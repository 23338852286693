import dayjs from 'dayjs';
import {
  AppDashboardProperty, useAnalytics, useCreateMortgage, useCreatePurchaseHistory,
  useDeleteMortgage, useUpdateMortgage, useUpdatePurchaseHistory,
} from 'lib';
import { formatDate } from 'ui';

import { PropertyFormValues } from './types';

export const usePurchaseHistoryMutation = () => {
  const entityName = 'Purchase History';
  const analytics = useAnalytics();
  const createPurchaseHistoryMutation = useCreatePurchaseHistory();
  const updatePurchaseHistoryMutation = useUpdatePurchaseHistory();

  return {
    isLoading: createPurchaseHistoryMutation.isLoading || updatePurchaseHistoryMutation.isLoading,
    mutate: (property: AppDashboardProperty, data: PropertyFormValues) => {
      const noDatesOrDatesMatch = property.purchaseHistory && (
        (!data.purchase.date && !property.purchaseHistory.date)
        || (data.purchase.date && formatDate(data.purchase.date!) === property.purchaseHistory.date)
      );
      const bothDoNotExist = !property.purchaseHistory && !data.purchase.amount;

      if (bothDoNotExist || (
        property.purchaseHistory
          && data.purchase.amount === (property.purchaseHistory.amount ?? '')
          && data.purchase.closingCost === (property.purchaseHistory.closingCost ?? '')
          && data.purchase.homeImprovementCost === (property.purchaseHistory.homeImprovementCost ?? '')
          && noDatesOrDatesMatch
      )) {
        return Promise.resolve();
      }

      if (property.purchaseHistory) {
        const payload = {
          homeImprovementCost: data.purchase.homeImprovementCost || null,
          closingCost: data.purchase.closingCost || null,
          amount: data.purchase.amount || 0,
          date: data.purchase.date ? formatDate(data.purchase.date) : null,
          purchaseHistoryId: property.purchaseHistory.id,
        };

        analytics.track('Entity Edited', {
          entityName,
          propertyAddress: property.address.fullAddress,
          ...payload,
        });

        return updatePurchaseHistoryMutation.mutateAsync(payload);
      }

      const payload = {
        propertyId: property.id,
        amount: data.purchase.amount || 0,
        date: data.purchase.date ? formatDate(data.purchase.date) : null,
        homeImprovementCost: data.purchase.homeImprovementCost || null,
        closingCost: data.purchase.closingCost || null,
      };

      analytics.track('Entity Created', {
        entityName,
        propertyAddress: property.address.fullAddress,
        ...payload,
      });

      return createPurchaseHistoryMutation.mutateAsync(payload);
    },
  };
};

export const useMortgageMutation = () => {
  const entityName = 'Mortgage';
  const analytics = useAnalytics();
  const createMortgageMutation = useCreateMortgage();
  const updateMortgageMutation = useUpdateMortgage();
  const deleteMortgageMutation = useDeleteMortgage();

  return {
    isLoading: createMortgageMutation.isLoading || updateMortgageMutation.isLoading || deleteMortgageMutation.isLoading,
    mutate: (property: AppDashboardProperty, data: PropertyFormValues) => {
      const loanTerm = data.mortgage.loanTerm === 'other' ? data.mortgage.otherLoanTerm : data.mortgage.loanTerm;
      let startDate = Number(data.mortgage.date) ?? 1;
      if (startDate === 0 || !startDate) {
        startDate = 1;
      }

      if (property.mortgage && !data.mortgage.mortgageType) {
        analytics.track('Entity Deleted', {
          entityName,
          propertyAddress: property.address.fullAddress,
          propertyId: property.id,
          mortgageId: property.mortgage.id,
        });

        return deleteMortgageMutation.mutateAsync(property.mortgage!.id);
      }

      // if the form data isn't valid
      if (
        !data.mortgage.mortgageType
        || !data.mortgage.amount
        || !loanTerm
        || !data.mortgage.interestRate) {
        return Promise.resolve();
      }

      // if the data didn't change
      if (
        data.mortgage.mortgageType === property.mortgage?.mortgageType
          && data.mortgage.amount === property.mortgage?.amount
          && property.mortgage?.startDate === formatDate(dayjs(startDate))
          && loanTerm === property.mortgage?.term
          && data.mortgage.interestRate === property.mortgage?.rate
          && data.mortgage.mortgageDueDay === property.mortgage?.mortgageDueDay
      ) {
        return Promise.resolve();
      }

      if (property.mortgage) {
        const payload = {
          rate: data.mortgage.interestRate,
          amount: data.mortgage.amount,
          startDate: formatDate(dayjs(startDate)),
          term: loanTerm,
          propertyId: property.id,
          mortgageId: property.mortgage.id,
          mortgageType: data.mortgage.mortgageType,
          mortgageDueDay: Number(data.mortgage.mortgageDueDay),
        };

        analytics.track('Entity Edited', {
          entityName,
          propertyAddress: property.address.fullAddress,
          ...payload,
        });

        return updateMortgageMutation.mutateAsync(payload);
      }

      const payload = {
        rate: data.mortgage.interestRate,
        amount: data.mortgage.amount,
        startDate: formatDate(dayjs(startDate)),
        term: loanTerm,
        propertyId: property.id,
        mortgageType: data.mortgage.mortgageType,
        mortgageDueDay: Number(data.mortgage.mortgageDueDay),
      };

      analytics.track('Entity Created', {
        entityName,
        propertyAddress: property.address.fullAddress,
        ...payload,
      });

      return createMortgageMutation.mutateAsync(payload);
    },
  };
};
