import { useEffect } from 'react';

import dayjs from 'dayjs';
import { BuyBoxLeadsWorkflowStatus, BuyBoxLeadWorkflowItem, useAnalytics } from 'lib';
import { FaDollarSign, FaHandHoldingUsd } from 'react-icons/fa';
import { MdHome, MdPhone, MdTimeline } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ColumnCard,
  Columns, FallbackSpinner, InvertedButton, KanbanBoard, LightTooltip, useLabels,
} from 'ui';
import { Stack } from '@mui/material';
import { signal } from '@preact/signals-react';

import { CardDetail, ColCard } from './ColCard';
import { useMutatePhoneCall } from '../../../api/calls';
import { useListBuyBoxLeads, useMutateBuyBoxLead } from '../../../api/workflows';
import { LeadCardHeader } from '../components/LeadCardHeader';
import { StatusChanger } from '../components/StatusChanger';
import { moveCardToColumn, updateCard } from '../utils';
import { useBuyBoxLeadStatuses } from '../WorkflowStatus';

const columnsSignal = signal<Columns<BuyBoxLeadCard>>({});

export const BuyBoxLeadsKanban = ({ orderedStatuses }: { orderedStatuses: BuyBoxLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { getStatus } = useBuyBoxLeadStatuses();
  const { data, isLoading, isError } = useListBuyBoxLeads();
  const { mutateAsync } = useMutateBuyBoxLead();

  useEffect(() => {
    if (data) {
      const cols: Columns<BuyBoxLeadCard> = {};

      orderedStatuses.forEach((columnID) => {
        const status = getStatus(columnID);

        cols[columnID] = {
          id: columnID,
          icon: status.icon,
          title: status.displayValue,
          color: status.color,
          tooltip: status.tooltip,
          cards: [],
        };
      });

      data.pages.forEach((page) => {
        page.leads.forEach((lead) => {
          const columnID = lead.status;

          cols[columnID].cards.push({
            ...lead,
            originalColumnID: columnID,
            disabled: false,
            disableStatusChange: true,
          });
        });
      });

      Object.keys(cols).forEach((columnID) => {
        cols[columnID].cards.sort((a, b) => dayjs(b.statusChangeTime).diff(dayjs(a.statusChangeTime)));
      });

      columnsSignal.value = cols;
    }

    if (isError) toast.error(l['error.unknownError']);
  }, [data, isLoading, isError, columnsSignal]);

  const handleColumnChange = async (lead: BuyBoxLeadCard, newStatus: string) => {
    analytics.track('Card Moved', {
      cardName: 'BuyBox Lead',
      from: lead.status,
      to: newStatus,
      id: lead.id,
    });

    const oldStatus = lead.status;

    columnsSignal.value = updateCard(columnsSignal.value, {
      ...lead,
      disabled: true,
    });

    try {
      await mutateAsync({
        id: lead.id,
        status: newStatus as BuyBoxLeadsWorkflowStatus,
      });
      columnsSignal.value = updateCard(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: newStatus as BuyBoxLeadsWorkflowStatus,
        originalColumnID: newStatus as BuyBoxLeadsWorkflowStatus,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);

      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...lead,
        disabled: false,
        status: oldStatus,
        originalColumnID: oldStatus,
      });
    }
  };

  useEffect(() => {
    document.querySelector('html')?.classList.add('full-height');

    return () => {
      document.querySelector('html')?.classList.remove('full-height');
    };
  }, []);

  if (isLoading) {
    return <FallbackSpinner />;
  }

  return (
    <Stack flexGrow={1} height="100%">
      <KanbanBoard
        colWidth={250}
        orderedColumnIDs={orderedStatuses}
        CardComponent={BuyBoxLeadColCard}
        columnsSignal={columnsSignal}
        onColumnChange={handleColumnChange}
      />
    </Stack>
  );
};

type BuyBoxLeadCard = BuyBoxLeadWorkflowItem & ColumnCard;

const BuyBoxLeadColCard = ({
  card,
  isOverlay = false,
}: {
  card: BuyBoxLeadCard,
  isOverlay?: boolean,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useBuyBoxLeadStatuses();
  const { mutateAsync: reveal, isLoading: isRevealing } = useMutateBuyBoxLead();
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateBuyBoxLead();
  const { mutateAsync: sendPhoneCallRequest, isLoading: isCalling } = useMutatePhoneCall();
  const disabled = isRevealing || isCalling || card.disabled;

  const revealLead = async () => {
    await reveal({
      id: card.id,
      revealed: true,
    });
    columnsSignal.value = updateCard(columnsSignal.value, {
      ...card,
      revealed: true,
    });
  };

  const call = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Call',
      called: 'BuyBox',
      id: card.id,
    });

    if (!card.leadContactDetails?.phoneNumber) {
      toast.error(l['error.missingContactPhone']);
      return;
    }

    try {
      await sendPhoneCallRequest(card.leadContactDetails?.id);
      toast.success(l['leads.call.success']);
    } catch (err) {
      console.error(err);
      toast.error(l['error.unknownError']);
    }
  };

  const handleStatusChange = async (status: BuyBoxLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: card.id,
        status,
      });
      columnsSignal.value = moveCardToColumn(columnsSignal.value, {
        ...card,
        disabled: false,
        status,
        originalColumnID: status,
      });
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  return (
    <ColCard
      card={card}
      revealCard={revealLead}
      revealed={!!card.revealed}
      isRevealing={isRevealing}
      createdTime={card.createdTime}
      updatedTime={card.statusChangeTime}
      isOverlay={isOverlay}
      footer={card.leadContactDetails?.phoneNumber && (
        <InvertedButton disabled={disabled} color="primary" variant="contained" onClick={call} fullWidth>
          <Stack direction="row" gap={1} alignItems="center">
            <MdPhone />
            {l.owner}
          </Stack>
        </InvertedButton>
      )}
      afterContent={(
        <StatusChanger
          id={card.id}
          status={card.status}
          statuses={statuses}
          changeStatus={handleStatusChange}
          isLoading={isUpdatingStatus}
        />
      )}
    >
      {card.leadContactDetails && (
        <LeadCardHeader
          firstName={card.leadContactDetails.firstName}
          lastName={card.leadContactDetails.lastName}
          email={card.leadContactDetails.email}
        />
      )}
      {card.timeline && (
        <CardDetail
          label={(
            <LightTooltip title={l.timeline} placement="top" arrow>
              <Stack>
                <MdTimeline />
              </Stack>
            </LightTooltip>
          )}
          content={card.timeline}
        />
      )}
      {card.amount && (
        <CardDetail
          label={<FaDollarSign />}
          content={card.amount}
        />
      )}
      {card.propertyType && (
        <CardDetail
          label={(
            <LightTooltip title={l.propertyType} placement="top" arrow>
              <Stack>
                <MdHome />
              </Stack>
            </LightTooltip>
          )}
          content={card.propertyType}
        />
      )}
      {card.financing && (
        <CardDetail
          label={(
            <LightTooltip title={l.financing} placement="top" arrow>
              <Stack>
                <FaHandHoldingUsd />
              </Stack>
            </LightTooltip>
          )}
          content={card.financing}
        />
      )}
    </ColCard>
  );
};
