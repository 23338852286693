import { Button } from '@mui/material';

import { useLabels } from '../../lib/translations';
import { Spinner } from '../../spinner';

type Props = {
  onClick: () => void;
  isLoading: boolean;
};

export const NoHoaButton = ({ onClick, isLoading }: Props) => {
  const l = useLabels();

  return (
    <Button size="small" disabled={isLoading} variant="outlined" color="primary" onClick={onClick}>
      {isLoading ? <Spinner size={30} /> : l.noHoa}
    </Button>
  );
};
