export enum Role {
  OWNER = 'owner',
  PROSPECT = 'prospect',
  MARKETPLACE = 'marketplace',
}

export type APIUser = {
  id: string;
  email: string;
  name: string;
  isActivated: boolean;
  isSelected: boolean;
  phoneNumber: string;
  companyRole: string;
  roles: Role[];
};

export type UsersResponse = {
  users: UserResponse[];
};

export type UserResponse = {
  name: string;
  email: string;
  users: string;
  firstName: string;
  lastName: string;
  isActivated: boolean;
  phoneNumber: string;
  companyRole: string;
  roles: string[];
};

export type UserImportRequest = {
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  propertyManager: string;
  companyRole: string;
  userTypes: string[];
};
