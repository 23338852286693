import { useMemo } from 'react';

import {
  AppDashboardProperty, calcYearlyExpenseByCategory, Category, getMissingCategories, PropertyWithAlerts,
} from 'lib';
import { MdClose } from 'react-icons/md';
import {
  Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useLabels } from '../../lib/translations';
import { TransactionAlert } from '../transactions';

export const MissingTransactionsModal = ({
  open,
  onClose,
  properties,
  onClick,
}: {
  open: boolean;
  onClose: () => void,
  properties: AppDashboardProperty[],
  onClick: (propertyID: string, category: Category) => void
}) => {
  const l = useLabels();
  const propsWithAlerts = useMemo<PropertyWithAlerts[]>((): PropertyWithAlerts[] => {
    const propertiesWithAlerts: PropertyWithAlerts[] = [];
    properties.forEach((property) => {
      const expenseByCategory = calcYearlyExpenseByCategory(property, '2024-01-01');
      const missingCategories = getMissingCategories(
        expenseByCategory,
        property.hoaMuted ? [] : [Category.PROPERTY_HOA],
      );
      if (missingCategories.length > 0) {
        propertiesWithAlerts.push({ ...property, missingExpensesCategories: missingCategories });
      }
    });
    return propertiesWithAlerts;
  }, [properties]);
  const theme = useTheme();
  const lowerThanMd = useMediaQuery(theme.breakpoints.down);
  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 1600,
        padding: 0,
      }}
    >
      <DialogContent sx={{
        padding: 0,
      }}
      >
        <Stack gap={3} width={lowerThanMd ? '200px' : '367px'}>
          <DialogTitle
            component={Stack}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ p: 3, paddingBottom: 0 }}
          >
            <Typography variant="h6">{l['transactions.alerts.title']}</Typography>
            <IconButton aria-label="Close" onClick={onClose} size="medium" color="secondary">
              <MdClose />
            </IconButton>
          </DialogTitle>
          <Divider />
          <Stack gap={3} px={4} pb={4}>
            {propsWithAlerts.map((propertyWithAlert) => (
              <TransactionAlert
                property={propertyWithAlert}
                onClick={(propertyID, category) => {
                  onClick(propertyID ?? '', category ?? Category.PROPERTY_HOA);
                }}
                key={propertyWithAlert.id}
                missingCategories={propertyWithAlert.missingExpensesCategories}
                textDecoration="unset"
              />
            ))}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
