import { Button, styled } from '@mui/material';

import { hexToRGBA } from '../lib/hexToRGBA';

const BaseButton = styled(Button)(({ theme }) => ({
  '&&&.active': {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  '&&&:not(.active)': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.dark,
  },
  '&&&, &&&:focus, &&&:active, &&&:hover': {
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    borderLeft: `1px solid ${theme.palette.background.paper}`,
    borderRight: `1px solid ${theme.palette.background.paper}`,
    fontWeight: theme.typography.fontWeightBold,
  },
}));

export const LeftButton = styled(BaseButton)(({ theme }) => ({
  '&&&': {
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
}));

export const RightButton = styled(BaseButton)(({ theme }) => ({
  '&&&': {
    borderRight: `1px solid ${theme.palette.grey[300]}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

export const InvertedButton = styled(BaseButton)(({ theme }) => ({
  '&&&:not(.active)': {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
    border: 'none',
  },
  '&&&:not(.active):hover': {
    background: theme.palette.primary.main,
  },
  '&&&.Mui-disabled': {
    background: hexToRGBA(theme.palette.primary.main, 0.12),
    color: hexToRGBA(theme.palette.primary.main, 0.26),
    boxShadow: 'none',
  },
}));

export const InvertedButtonDark = styled(BaseButton)(({ theme }) => ({
  '&&&:not(.active)': {
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    border: '1px solid gray',
  },
  '&&&:not(.active):hover': {
    background: theme.palette.primary.main,
  },
  '&&&.Mui-disabled': {
    background: hexToRGBA(theme.palette.primary.main, 0.12),
    color: hexToRGBA(theme.palette.primary.main, 0.26),
    boxShadow: 'none',
  },
}));

export const LightButton = styled(BaseButton)(({ theme }) => ({
  '&&&:not(.active)': {
    background: theme.palette.background.paper,
    color: theme.palette.primary.main,
    border: 'none',
  },
  '&&&:not(.active):hover': {
    background: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  '&&&.Mui-disabled': {
    background: theme.palette.grey[200],
    color: hexToRGBA(theme.palette.primary.main, 0.26),
    boxShadow: 'none',
  },
}));
