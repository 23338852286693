import { ReactNode } from 'react';

import {
  Box, Paper, Stack, useTheme,
} from '@mui/material';

import { InvertedButton } from '../button/styled';
import { useLabels } from '../lib/translations';
import { BoldTypography } from '../typography/BoldTypography';
import { SemiBoldTypography } from '../typography/SemiBoldTypography';

export type BlurLevel = 'low' | 'medium' | 'high';

export const BlurredComponent = ({
  title,
  subtitle,
  actionText = undefined,
  onClickAction = undefined,
  isBlurred,
  children,
  height = '100%',
  blurLevel = 'high',
  showMessageInCard = false,
}: {
  title: string,
  subtitle: string,
  onClickAction?: () => void,
  actionText?: string,
  isBlurred: boolean,
  children: ReactNode,
  height?: string,
  blurLevel?: BlurLevel,
  showMessageInCard?: boolean,
}) => {
  const l = useLabels();
  const theme = useTheme();

  if (!isBlurred) return children;

  const blurLevelToBlur = {
    low: 3,
    medium: 5,
    high: 8,
  };

  const msg = (
    <Stack gap={3} alignItems="center">
      <BoldTypography variant="h6" sx={{ textAlign: 'center' }}>{title}</BoldTypography>
      <SemiBoldTypography variant="body2" sx={{ textAlign: 'center' }}>{subtitle}</SemiBoldTypography>
      {onClickAction && (
        <Box>
          <InvertedButton onClick={onClickAction}>{actionText ?? l.unlock}</InvertedButton>
        </Box>
      )}
    </Stack>
  );

  return (
    <Stack position="relative" height={height}>
      <Stack
        height={height}
        width="100%"
        borderRadius="6px"
        p={7}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        top={0}
        left={0}
        zIndex={999}
      >
        {showMessageInCard ? (
          <Paper elevation={0} sx={{ borderRadius: '6px', border: `1px solid ${theme.palette.divider}`, p: { sx: 3, md: 6 } }}>
            {msg}
          </Paper>
        ) : msg}
      </Stack>
      <Stack sx={{
        filter: `blur(${blurLevelToBlur[blurLevel]}px)`,
        height,
        overflow: 'hidden',
      }}
      >
        {children}
      </Stack>
    </Stack>
  );
};
