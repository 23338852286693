import { ReactNode, useState } from 'react';

import { AppDashboardProperty, useAnalytics } from 'lib';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  Button,
  Collapse, Grid, Stack, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import { MortgageMetric } from './MortgageMetric';
import { ControlledFormattedNumberField } from '../../../form/ControlledFormattedNumberField';
import { PercentGreen, PercentRed } from '../../../icons';
import { formatDecimalToPercentage } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { TooltipMetric } from '../../../metric/Metric';
import { Spacer } from '../../../spacer/Spacer';
import { editModeSignal, useGetPropertyIdParam } from '../state';
import {
  Form, MetricRelatedExpense, metricRelatedExpenses, PropertyProformaData,
} from '../types';

const EditableMetric = ({
  form,
  label,
  icon,
  metricKey,
  tooltip,
}: {
  form: Form,
  label: string,
  icon: ReactNode,
  tooltip: string,
  metricKey: keyof (PropertyProformaData['metrics'])
}) => {
  const analytics = useAnalytics();
  const editMode = editModeSignal.value;
  const propertyID = useGetPropertyIdParam();
  const value = form.watch(`${propertyID}.metrics.${metricKey}`);
  const rent = form.watch(`${propertyID}.income.rent`);

  return (
    <TooltipMetric
      label={label}
      displayValue={editMode ? (
        <ControlledFormattedNumberField
          name={`${propertyID}.metrics.${metricKey}`}
          control={form.control}
          label={label}
          useExternalValue
          externalValue={value}
          prefix=""
          suffix="%"
          size="small"
          decimalScale={2}
          showZero
          onBlur={() => {
            if (metricRelatedExpenses.includes(metricKey as MetricRelatedExpense)) {
              form.setValue(`${propertyID}.expenses.${metricKey}`, (value / 100) * rent);
            }

            analytics.track('Input Changed', {
              inputName: `Proforma Metrics - ${metricKey}`,
              value,
            });
          }}
        />
      ) : formatDecimalToPercentage(value / 100, 2)}
      cardSx={{ height: '100%' }}
      icon={icon}
      tooltipName={metricKey}
      tooltipContent={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {tooltip}
        </Typography>
      )}
    />
  );
};

type MetricConfig = Record<keyof PropertyProformaData['metrics'], { label: string, icon: ReactNode, tooltip: string }>;

export const EditableMetrics = ({ form, properties }: { form: Form, properties: AppDashboardProperty[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const propertyID = useGetPropertyIdParam();
  const metrics = form.watch(`${propertyID}.metrics`);
  const metricKeys = Object.keys(metrics) as (keyof PropertyProformaData['metrics'])[];
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const iconSize = 40;

  const [metricsExpanded, setMetricsExpanded] = useState(false);

  const metricConfig: MetricConfig = {
    annualHomeAppreciation: {
      label: l.annualHomeAppreciation,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.annualHomeAppreciation.tooltip'],
    },
    annualRentGrowth: {
      label: l.annualRentGrowth,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.annualRentGrowth.tooltip'],
    },
    estimatedVacancy: {
      label: l.estimatedVacancy,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.estimatedVacancy.tooltip'],
    },
    inflationRate: {
      label: l.inflation,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.inflationRate.tooltip'],
    },
    managementFee: {
      label: l.managementFee,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.managementFee.tooltip'],
    },
    maintenance: {
      label: l.maintenanceReserve,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.maintenanceReserve.tooltip'],
    },
    capEx: {
      label: l.capEx,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.capEx.tooltip'],
    },
  };

  const renderedMetrics = (
    <Grid container spacing={3}>
      {metricKeys.map((key) => (
        <Grid item xs={12} sm={6} md={3} key={key}>
          <EditableMetric
            tooltip={metricConfig[key].tooltip}
            form={form}
            label={metricConfig[key].label}
            icon={metricConfig[key].icon}
            metricKey={key}
          />
        </Grid>
      ))}
      <Grid item xs={12} sm={6} md={3}>
        <MortgageMetric form={form} properties={properties} />
      </Grid>
    </Grid>
  );

  const handleClickExpandAssumptions = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Expand Assumptions',
      propertyId: propertyID,
      expanding: !metricsExpanded,
    });

    setMetricsExpanded((currExpanded) => !currExpanded);
  };

  if (isMobile) {
    return (
      <>
        <Stack direction="row" alignItems="center" gap={1}>
          <Button
            variant="text"
            onClick={handleClickExpandAssumptions}
            startIcon={(
              <MdKeyboardArrowRight
                style={{ transform: metricsExpanded ? 'rotate(90deg)' : undefined, transition: '0.3s' }}
              />
            )}
          >
            {l.assumptions}
          </Button>
        </Stack>
        <Collapse in={metricsExpanded}>
          <Spacer spacing={3} />
          {renderedMetrics}
        </Collapse>
      </>
    );
  }

  return renderedMetrics;
};
