import {
  AppDashboardProperty,
  AppUserActions, objectEntries, objectKeys, PMPreferences, PMSettingsResponse, sum, sumArrays,
} from 'lib';

import { calculateAnnualGrowth, calculateProjections } from './calc';
import { PORTFOLIO } from './state';
import {
  Loan, loanTerms, MetricRelatedExpense, metricRelatedExpenses, NonObjectField, nonObjectFields,
  ObjectField, objectFields, ProformaMetricsData, PropertyProformaData, PropertyProformaFormData,
} from './types';

export const loadProformaOverride = (raw: string) => {
  if (!raw) return null;

  return JSON.parse(raw) as PropertyProformaFormData;
};

const getRent = (properties: AppDashboardProperty[]): number => (
  sum(properties.map((property) => property.metrics?.annualMetrics?.estimatedIncome ?? 0))
);

const getValuation = (properties: AppDashboardProperty[]): number => (
  sum(properties.map((property) => property.metrics?.valuation ?? property.latestValuation?.priceMean ?? 0))
);

const getTax = (properties: AppDashboardProperty[]): number => (
  Math.abs(sum(properties.map((property) => property.metrics?.annualMetrics?.propertyTax ?? 0)))
);

const getInsurance = (properties: AppDashboardProperty[]): number => (
  Math.abs(sum(properties.map((property) => property.metrics?.annualMetrics?.propertyInsurance ?? 0)))
);

const getHoa = (properties: AppDashboardProperty[]): number => (
  Math.abs(sum(properties.map((property) => property.metrics?.annualMetrics?.propertyHoa ?? 0)))
);

export const getLoan = (property: AppDashboardProperty): Loan => {
  if (!property.mortgage) return null;

  const term = property.mortgage.term as typeof loanTerms[number];

  return {
    purchasePrice: property.purchaseHistory?.amount ?? 0,
    amount: property.mortgage.amount,
    interestRate: property.mortgage.rate,
    term: loanTerms.includes(term) ? term : 'other',
    termOther: loanTerms.includes(term) ? 0 : term as number,
  };
};

export const getAllLoans = (data: PropertyProformaFormData) => {
  const loans: Loan[] = [];

  Object.values(data).forEach((value) => {
    if (value.loan) {
      loans.push(value.loan);
    }
  });

  return loans;
};

export const proformaDefaultMetrics = {
  annualHomeAppreciation: 3,
  annualRentGrowth: 3,
  estimatedVacancy: 5,
  inflationRate: 3,
  managementFee: 8,
  maintenance: 6,
  capEx: 3,
};

const createPropertyFormData = (
  property: AppDashboardProperty,
  pmPreferences?: PMPreferences,
): PropertyProformaData => {
  const rent = getRent([property]);
  const loan = getLoan(property);

  const metrics = { ...proformaDefaultMetrics };

  const data = {
    askingPrice: getValuation([property]),
    metrics,
    loan,
    income: {
      rent,
    },
    expenses: {
      estimatedVacancy: rent * (metrics.estimatedVacancy / 100),
      tax: getTax([property]),
      insurance: getInsurance([property]),
      hoa: getHoa([property]),
      managementFee: rent * (metrics.managementFee / 100),
      maintenance: rent * (metrics.maintenance / 100),
      capEx: rent * (metrics.capEx / 100),
    },
  };

  if (pmPreferences?.managementFee) {
    data.expenses.managementFee = pmPreferences.isManagementFeeDollars
      ? pmPreferences.managementFee : rent * (pmPreferences.managementFee / 100);
    data.metrics.managementFee = pmPreferences.isManagementFeeDollars
      ? pmPreferences.managementFee / rent : pmPreferences.managementFee;
  }

  return data;
};

const overrideDataWithUserActions = (
  propertyID: string,
  data: PropertyProformaData,
  userActions?: AppUserActions,
): PropertyProformaData => {
  if (!userActions || !userActions.dashboardProformaOverride) return data;

  const propertyData = { ...data };

  const overrideData = loadProformaOverride(userActions.dashboardProformaOverride);

  if (!overrideData || !overrideData[propertyID]) return data;

  const overrideDataForProperty = overrideData[propertyID];

  objectKeys(overrideDataForProperty).forEach((key) => {
    const value = overrideDataForProperty[key];

    if (!value) return;

    if (objectFields.includes(key as any)) {
      propertyData[key] = {
        ...propertyData[key as ObjectField],
        ...value as any,
      };
    } else {
      propertyData[key as NonObjectField] = value as PropertyProformaData[NonObjectField];
    }
  });

  return propertyData;
};

const getMostPrevalentExpenseKey = (data: PropertyProformaData[]): keyof PropertyProformaData['expenses'] => {
  const expenseCounts = <Record<keyof PropertyProformaData['expenses'], number>>{};

  data.forEach((d) => {
    objectKeys(d.expenses).forEach((k) => {
      expenseCounts[k] = (expenseCounts[k] ?? 0) + 1;
    });
  });

  let max = 0;
  let key: keyof PropertyProformaData['expenses'] = 'managementFee';

  objectKeys(expenseCounts).forEach((k) => {
    if (expenseCounts[k] > max) {
      max = expenseCounts[k];
      key = k;
    }
  });

  return key;
};

export const createPortfolioFormData = (formData: PropertyProformaFormData): PropertyProformaData => {
  const years = [1, 2];
  const data: PropertyProformaData[] = [];

  Object.keys(formData).forEach((propertyID) => {
    if (propertyID === PORTFOLIO) return;

    data.push(formData[propertyID]);
  });

  const portfolioData: PropertyProformaData = JSON.parse(JSON.stringify(data[0]));

  const [rentYear1, rentYear2] = sumArrays(data.map((p) => calculateProjections({
    years,
    value: p.income.rent,
    multiplier: p.metrics.annualRentGrowth / 100,
  })));
  const [askingPriceYear1, askingPriceYear2] = sumArrays(data.map((p) => calculateProjections({
    years,
    value: p.askingPrice,
    multiplier: p.metrics.annualHomeAppreciation / 100,
  })));

  const expenseKey = getMostPrevalentExpenseKey(data);
  const [expenseYear1, expenseYear2] = sumArrays(data.map((p) => calculateProjections({
    years,
    value: p.expenses[expenseKey],
    multiplier: p.metrics.inflationRate / 100,
  })));

  const rent = sum(data.map((p) => p.income.rent));
  const capEx = sum(data.map((p) => p.expenses.capEx));
  const mgmtFee = sum(data.map((p) => p.expenses.managementFee));
  const maintenance = sum(data.map((p) => p.expenses.maintenance));
  const estimatedVacancy = sum(data.map((p) => p.expenses.estimatedVacancy));
  const tax = sum(data.map((p) => p.expenses.tax));
  const insurance = sum(data.map((p) => p.expenses.insurance));
  const hoa = sum(data.map((p) => p.expenses.hoa));

  portfolioData.income.rent = rent;

  portfolioData.metrics.capEx = rent ? 100 * (capEx / rent) : 0;
  portfolioData.metrics.managementFee = rent ? 100 * (mgmtFee / rent) : 0;
  portfolioData.metrics.maintenance = rent ? 100 * (maintenance / rent) : 0;
  portfolioData.metrics.estimatedVacancy = rent ? 100 * (estimatedVacancy / rent) : 0;
  portfolioData.metrics.annualRentGrowth = rentYear1 ? calculateAnnualGrowth(rentYear1, rentYear2) : 0;
  portfolioData.metrics.annualHomeAppreciation = askingPriceYear1 ? calculateAnnualGrowth(askingPriceYear1, askingPriceYear2) : 0;
  portfolioData.metrics.inflationRate = expenseYear1 ? calculateAnnualGrowth(expenseYear1, expenseYear2) : 0;

  portfolioData.expenses.tax = tax;
  portfolioData.expenses.insurance = insurance;
  portfolioData.expenses.hoa = hoa;
  portfolioData.expenses.maintenance = maintenance;
  portfolioData.expenses.managementFee = mgmtFee;
  portfolioData.expenses.capEx = capEx;
  portfolioData.expenses.estimatedVacancy = estimatedVacancy;

  return portfolioData;
};

export const createInitialFormData = (
  properties: AppDashboardProperty[],
  pmPreferences?: PMPreferences,
  userActions?: AppUserActions,
  pmSettings?: PMSettingsResponse,
): PropertyProformaFormData => {
  const initialData: PropertyProformaFormData = {};

  properties.forEach((property) => {
    initialData[property.id] = cleanupData(
      property.id,
      overrideWithPmSettings(
        property.id,
        overrideDataWithUserActions(
          property.id,
          createPropertyFormData(property, pmPreferences),
          userActions,
        ),
        pmSettings,
        userActions,
      ),
      userActions,
      pmSettings,
    );
  });

  initialData[PORTFOLIO] = createPortfolioFormData(initialData);

  return initialData;
};

const cleanupData = (
  propertyID: string,
  data: PropertyProformaData,
  userActions?: AppUserActions,
  pmSettings?: PMSettingsResponse,
): PropertyProformaData => {
  const cleanData = { ...data };
  const userOverride = loadProformaOverride(userActions?.dashboardProformaOverride || '{}') ?? {};
  const propertyDataOverride = userOverride[propertyID] ?? {};
  const metricsOverride = JSON.parse(pmSettings?.dashboardProformaMetricsOverride || '{}') as ProformaMetricsData;

  // Reset expenses for metrics that aren't overridden
  metricRelatedExpenses.forEach((metric) => {
    const isOverriddenByUser = propertyDataOverride.metrics?.[metric] !== undefined;
    const isOverriddenByPm = metricsOverride[metric] !== undefined;

    if (!isOverriddenByUser && !isOverriddenByPm) {
      cleanData.expenses[metric] = cleanData.income.rent * (cleanData.metrics[metric] / 100);
    }
  });

  return cleanData;
};

const overrideWithPmSettings = (
  propertyID: string,
  data: PropertyProformaData,
  pmSettings?: PMSettingsResponse,
  userActions?: AppUserActions,
): PropertyProformaData => {
  if (!pmSettings) return data;

  const newData = { ...data };
  const { rent } = data.income;
  const userOverride = loadProformaOverride(userActions?.dashboardProformaOverride || '{}') ?? {};
  const propertyDataOverride = userOverride[propertyID] ?? {};
  const metricsOverride = JSON.parse(pmSettings.dashboardProformaMetricsOverride || '{}') as ProformaMetricsData;

  objectEntries(metricsOverride).forEach(([metric, value]) => {
    // Only override if metric not in user override
    if (propertyDataOverride.metrics?.[metric] === undefined) {
      newData.metrics[metric] = value;

      if (metricRelatedExpenses.includes(metric as MetricRelatedExpense)) {
        newData.expenses[metric as MetricRelatedExpense] = rent * (value / 100);
      }
    }
  });

  return newData;
};

export const getFormDiff = (
  properties: AppDashboardProperty[],
  pmPreferences: PMPreferences,
  formData: PropertyProformaFormData,
  pmSettings?: PMSettingsResponse,
): string | null => {
  const diff: Record<string, Partial<PropertyProformaData>> = {};
  const original = createInitialFormData(properties, pmPreferences, undefined, pmSettings);

  Object.keys(original).forEach((propertyID) => {
    if (propertyID === PORTFOLIO) return;

    const originalProperty = original[propertyID];
    const newProperty = formData[propertyID];
    const diffProperty: Partial<PropertyProformaData> = {};

    Object.keys(newProperty).forEach((k) => {
      const topLevelKey = k as keyof PropertyProformaData;
      const originalValue = originalProperty[topLevelKey];
      const newValue = newProperty[topLevelKey];

      if (nonObjectFields.includes(topLevelKey as any) && originalValue !== newValue) {
        diffProperty[topLevelKey as NonObjectField] = newValue as PropertyProformaData[NonObjectField];
      } else if (objectFields.includes(topLevelKey as any)) {
        const typedNewValue = newValue as PropertyProformaData[ObjectField];
        const typedOriginalValue = originalValue as PropertyProformaData[ObjectField];

        if (!typedOriginalValue || !typedNewValue) return;

        Object.keys(typedNewValue).forEach((nestedK) => {
          const nestedKey = nestedK as keyof PropertyProformaData[ObjectField];
          const newFieldValue = typedNewValue[nestedKey];
          const originalFieldValue = typedOriginalValue[nestedKey];

          if (newFieldValue !== originalFieldValue) {
            if (!diffProperty[topLevelKey]) {
              diffProperty[topLevelKey] = {} as any;
            }

            diffProperty[topLevelKey]![nestedKey] = newFieldValue;
          }
        });
      }
    });

    if (Object.keys(diffProperty).length > 0) {
      diff[propertyID] = diffProperty;
    }
  });

  if (Object.keys(diff).length === 0) return null;

  return JSON.stringify(diff);
};
