import { useMemo } from 'react';

import { AppDashboardProperty } from '../api/dashboard-properties/types/property';
import { hasMissingCategoriesLastYear } from '../lib/calc/transactionFuncs';

const missingFields = [
  'cashInvested',
  'purchasePrice',
  'principalPaid',
  'principalPaydown',
  'appreciation',
  'capRate',
  'noi',
  'netCashFlow',
  'cashOnCash',
  'expenses',
] as const;

const missingAttributes = [
  'expenses',
  'purchasePrice',
  'mortgage',
] as const;

export type MissingDataFieldName = typeof missingFields[number];

type UserAttribute = typeof missingAttributes[number];

type MissingDataField = {
  isMissing: boolean;
  propertyIDs: string[];
};

type MissingData = {
  [key in MissingDataFieldName]: MissingDataField;
};

type MissingAttributes = {
  [key in UserAttribute]: boolean
};

const missingValuation = (property: AppDashboardProperty): boolean => !property.latestValuation;

const missingPurchasePrice = (property: AppDashboardProperty): boolean => !property.purchaseHistory;

const missingCashInvested = (property: AppDashboardProperty): boolean => (
  missingPurchasePrice(property) || (!!property.mortgage && !property.mortgage.amount)
);

const missingPrincipalPaid = (property: AppDashboardProperty): boolean => (
  missingPurchasePrice(property) || (!!property.mortgage && !property.mortgage.amount)
);

const missingExpenses = (property: AppDashboardProperty, fromDate?: string):
boolean => hasMissingCategoriesLastYear([property], [], fromDate);

// in this case the missingExpenses is equivalent to missing net cash flow
const missingCashOnCash = (property: AppDashboardProperty): boolean => missingExpenses(property) || missingCashInvested(property);

const missingCapRate = (property: AppDashboardProperty): boolean => missingExpenses(property) || missingValuation(property);

// eslint-disable-next-line no-spaced-func
const missingDataMap = new Map<MissingDataFieldName, (property: AppDashboardProperty, fromDate?: string) => boolean>([
  ['cashInvested', missingCashInvested],
  ['purchasePrice', missingPurchasePrice],
  ['principalPaid', missingPrincipalPaid],
  ['principalPaydown', missingPrincipalPaid],
  ['appreciation', missingPurchasePrice],
  ['capRate', missingCapRate],
  ['noi', missingExpenses],
  ['netCashFlow', missingExpenses],
  ['cashOnCash', missingCashOnCash],
  ['expenses', missingExpenses],
]);

const userAttributes: { [key in MissingDataFieldName]: UserAttribute } = {
  cashInvested: 'expenses',
  purchasePrice: 'purchasePrice',
  principalPaid: 'mortgage',
  principalPaydown: 'mortgage',
  appreciation: 'purchasePrice',
  capRate: 'expenses',
  noi: 'expenses',
  netCashFlow: 'expenses',
  cashOnCash: 'expenses',
  expenses: 'expenses',
};

export const useMissingData = (properties: AppDashboardProperty[], annualReport: boolean = false): {
  missingData: MissingData,
  isMissingData: boolean,
  missingAttributes: MissingAttributes
} => useMemo(() => {
  const tempMissingData: MissingData = missingFields.reduce((acc, field) => {
    acc[field] = {
      isMissing: false,
      propertyIDs: [],
    };
    return acc;
  }, {} as MissingData);

  const tempMissingAttributes: MissingAttributes = missingAttributes.reduce((acc, field) => {
    acc[field] = false;
    return acc;
  }, {} as MissingAttributes);

  let isMissingData = false;

  properties.forEach((property) => {
    let transactionsToUse = property.transactions;
    if (annualReport) {
      transactionsToUse = property.transactions.filter((
        transaction,
      ) => new Date(transaction.transactionDate).getFullYear() === 2024);
    }
    const propertyWithTransactionsOverride = { ...property, transactions: transactionsToUse };

    missingDataMap.forEach((missingDataFunc, fieldName) => {
      if (missingDataFunc(propertyWithTransactionsOverride, annualReport ? '2024-01-01' : undefined)) {
        isMissingData = true;
        tempMissingAttributes[userAttributes[fieldName]] = true;
        tempMissingData[fieldName].isMissing = true;
        tempMissingData[fieldName].propertyIDs.push(property.id);
      }
    });
  });

  return { missingData: tempMissingData, isMissingData, missingAttributes: tempMissingAttributes };
}, [properties]);
