import { useState } from 'react';

import {
  config, useFeatures, useGetRedirectURL,
} from 'lib';
import { MdOutlineCreditCard, MdSettings } from 'react-icons/md';
import { TbBriefcase2 } from 'react-icons/tb';
import { toast } from 'react-toastify';
import {
  PageTitle, Spacer, Tabs, useLabels,
} from 'ui';
import {
  Divider,
  Paper, Stack,
} from '@mui/material';

import { PmSettings } from './components/PmSettings';
import { Team } from './components/Team';

type TabName = 'team' | 'billing' | 'settings';

export const Settings = () => {
  const l = useLabels();
  const [tab, setTab] = useState<TabName>('team');
  const { mutateAsync: getRedirectURL, isLoading: isLoadingRedirectURL } = useGetRedirectURL();
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const handleClickBilling = async () => {
    let redirectURL;

    try {
      const url = `${config.apiBaseURL}/view-billing`;
      redirectURL = await getRedirectURL({
        url,
        params: {
          return_url: window.location.origin,
        },
      });
    } catch (e) {
      console.error(e);
    }

    if (redirectURL) {
      window.open(redirectURL, '_blank');
    } else {
      toast.error(l['error.unknownError']);
    }
  };

  return (
    <Stack height="100%" p={3}>
      <PageTitle title={l.settings} />
      <Spacer spacing={2} />
      <Paper elevation={4} sx={{ flexGrow: 1 }}>
        <Tabs
          tabs={[
            {
              label: l['menu-team'],
              value: 'team',
              icon: <TbBriefcase2 />,
            },
            features.isBillingEnabled ? {
              label: l['menu-billing'],
              value: 'billing',
              icon: <MdOutlineCreditCard size={16} />,
              onClick: () => {
                handleClickBilling();
              },
              isLoading: isLoadingRedirectURL,
            } : null,
            features.isAnnualReportPmEnabled ? {
              label: l.settings,
              value: 'settings',
              icon: <MdSettings size={16} />,
            } : null,
          ]}
          activeTab={tab}
          setTab={setTab}
          isLoading={isLoadingFeatures}
        />
        <Divider sx={{ mt: 0 }} />
        {tab === 'team' && (
          <Stack><Team /></Stack>
        )}
        {tab === 'settings' && (
          <PmSettings />
        )}
      </Paper>
    </Stack>
  );
};
