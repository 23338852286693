import { useState } from 'react';

import dayjs from 'dayjs';
import {
  convertExpenseCategoryToCategory, ITransactionForm, useAnalytics, useListOwnerProperties, useMissingData,
} from 'lib';
import { Button, Stack, Typography } from '@mui/material';

import { MissingTransactionsModal } from './MissingTransactionsModal';
import { useToggle } from '../../hooks/useToggle';
import { WarningTriangle } from '../../icons';
import { useLabels } from '../../lib/translations';
import { InfoTooltip, LightTooltip } from '../../tooltip/InfoTooltip';
import { OnClickAddTransaction } from '../transactions';
import { AddTransactionsModal } from '../transactions/AddTransaction';

export const MissingDataButton = ({ owner, inPreview = false }: {
  owner: string, inPreview?: boolean,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();

  const { data: ownerProperties, isLoading } = useListOwnerProperties(owner);
  const [addTransactionsModalInitialValues, setAddTransactionsModalInitialValues] = useState<ITransactionForm | null>(null);
  const [isAddTransactionsModalOpen, toggleAddTransactionsModal] = useToggle();
  const [missingDataPopupOpen, setMissingDataPopupOpen] = useState(false);
  const { missingData } = useMissingData(ownerProperties?.properties ?? [], true);

  const onClickAddTransaction: OnClickAddTransaction = (propertyID, category) => {
    if (propertyID !== undefined && category !== undefined) {
      setAddTransactionsModalInitialValues({
        category: convertExpenseCategoryToCategory(category),
        type: 'one_time',
        amount: undefined,
        propertyId: propertyID,
        dates: {
          date: dayjs('2024-01-01').format('MM/DD/YYYY'),
        },
      });
    } else {
      setAddTransactionsModalInitialValues(null);
    }
    toggleAddTransactionsModal();
    setMissingDataPopupOpen(false);
  };

  return !isLoading && missingData.expenses.isMissing && (
    <>
      <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
        <LightTooltip
          arrow
          enterTouchDelay={0}
          title={!inPreview ? undefined : (
            <Typography variant="body2" sx={{ p: 1 }}>
              {l['annualReport.missingDataDisable']}
            </Typography>
          )}
        >
          <span>
            <Button
              variant="outlined"
              size="small"
              color="error"
              disabled={inPreview}
              startIcon={<WarningTriangle />}
              onClick={() => {
                analytics.track('Button Clicked', {
                  buttonName: 'Annual Report Missing Data CTA',
                  widgetName: 'Annual Report',
                });

                setMissingDataPopupOpen(true);
              }}
            >
              {l['transactions.alerts.title']}
            </Button>
          </span>

        </LightTooltip>
        <InfoTooltip
          arrow
          isOutlined
          isLight
          title={l['annualReport.portfolioPerformance.missingDataTooltip']}
          track={(value) => {
            analytics.track('Tooltip Toggled', {
              value,
              tooltipName: 'Missing data on Annual report',
            });
          }}
        />
      </Stack>
      <AddTransactionsModal
        isOpen={isAddTransactionsModalOpen}
        toggle={toggleAddTransactionsModal}
        initialValues={addTransactionsModalInitialValues}
      />
      <MissingTransactionsModal
        open={missingDataPopupOpen}
        onClose={() => setMissingDataPopupOpen(false)}
        properties={ownerProperties?.properties ?? []}
        onClick={(propertyID, category) => {
          onClickAddTransaction(propertyID, category);
        }}
      />
    </>
  );
};
