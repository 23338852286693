export const getAppSettings = /* GraphQL */ `
    query GetAppSettings($id: ID!) {
        getAppSettings(id: $id) {
            id
            iamDomain
            iamClientId
            iamAudience
            title
            pmName
            tenant
            color
            logo
            favicon
            createdAt
            updatedAt
        }
    }
`;

export const listPropertyIDs = /* GraphQL */`
  query ListPropertyIDs {
    listMarketplaceProperties(limit: 5000) {
      items {
        property {
          id
        }
      }
    }
  }
`;

export const listSellableProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelDashboardPropertyFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $owner: String!
  ) {
    listDashboardPropertyByOwner(
      owner: $owner
      filter: $filter
      limit: 5000
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      nextToken
      items {
        id
        name
        address {
          street1
          street2
          city
          state
          zipCode
        }
        property {
          id
        }
        propertyValuation {
          items {
            priceMean
            priceUpper
            priceLower
          }
        }
      }
    }
  }
`;

export const getUserActions = /* GraphQL */ `
  query GetUserActions($owner: ID!) {
      getUserActions(owner: $owner) {
          owner
          viewedWelcomeToMarketplaceScreen
          viewedCashOutTooltip
          viewedAnnualReportBanner
          dashboardProformaOverride
      }
  }
`;

export const listStates = /* GraphQL */ `
  query ListStates {
    listStates {
      items {
        id
        name
        insuranceFactor
      }
    }
  }
`;

export const listOwnerLeads = /* GraphQL */ `
    query MyQuery($pm: String!) {
      listOwnerLeadWorkflowItemsByPm(pm: $pm) {
        items {
          updatedTime
          numOfProperties
          whatAreYouLookingFor
          status
          ownerLead {
            email
            firstName
            lastName
            phoneNumber
          }
        }
      }
    }
`;

export const listOwnerLeadReferralLinks = /* GraphQL */ `
    query MyQuery($ownerId: String!) {
      listOwnerLeadReferralLinks(filter: {ownerId: {eq: $ownerId}}) {
        items {
          id
        }
      }
    }
`;

export const getPMPreferencesQuery = /* GraphQL */ `
  query GetPMPreferences($pm: ID!) {
    getMarketplacePMPreferences(pm: $pm) {
      managementFee
      leadsThirdPartyIntegrationRequested
    }
  }
`;

export const getPMSettingsQuery = /* GraphQL */ `
  query MyQuery($pm: ID!) {
    getPMSettings(pm: $pm) {
      pm
      leadershipSummary2024
      dashboardProformaMetricsOverride
    }
  }
`;

export const listProperties = /* GraphQL */ `
  query ListProperties(
    $filter: ModelDashboardPropertyFilterInput
    $nextToken: String
    $sortDirection: ModelSortDirection
    $owner: String!
  ) {
    listDashboardPropertyByOwner(
      owner: $owner
      filter: $filter
      limit: 5000
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      nextToken
      items {
        id
        owner
        name
        hoaMuted
        deleted
        purchaseHistory {
        items {
          id
          amount
          closingCost
          homeImprovementCost
          date
          createdTime
          updatedTime
          dashboardPropertyPurchaseHistoryId
        }
      }
      property {
            extendedAddress {
            items {
               fips
             }
            }
      },
      propertyValuation(sortDirection: DESC, limit: 1) {
        items {
          id
          date
          priceUpper
          priceLower
          priceMean
          fsd
          provider
        }
       },
         metrics(sortDirection: DESC, limit: 1) {
            items {
              NOI
              capRate
              cashOnCash
              annualExpense
              annualIncome
              cashInvested
              valuation
              cashFlow
              totalEquity
              appreciation
              createdTime
              dashboardPropertyMetricsId
              id
              isDataPartial
              annualMetrics {
                income
                propertyTax
                propertyInsurance
                propertyHoa
              }
              trailingQ1 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
              trailingQ2 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
              trailingQ3 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
              trailingQ4 {
                income
                cashflow
                noi
                noiExpenses
                totalExpenses
              }
            }
          },
        transactions(limit: 5000) {
          items {
            id
            amount
            transactionDate
            owner
            type
            category
            label
            description
            isEditable
          }
          nextToken
        }
        leases(limit: 5000) {
          items {
            rent
            startDate
          }
        }
        userExpense {
          items {
            id
            amount
            startDate
            category
            owner
            createdTime
            updatedTime
            dashboardPropertyUserExpenseId
          }
          nextToken
        }
        amortizationTable(limit: 1, sortDirection: DESC) {
          items {
            id
            period
            transactionDate
            payment
            interest
            principal
            totalPrincipal
            mortgageId
          }
        }
        mortgage {
          items {
            id
            rate
            amount
            startDate
            mortgageDueDay
            mortgageType
            term
            owner
            grantor
            apn
            createdTime
            updatedTime
            dashboardPropertyMortgageId
          }
          nextToken
        }
        address {
          street1
          street2
          city
          state
          zipCode
        }
        property {
          id
          address {
            street1
            street2
            city
            state
            zipCode
          }
          units {
              items {
                  id
                  isVacant
              }
          }
          yearBuilt
          bedrooms
          bathrooms
          sqft
          isVacant
          owner
          createdTime
          updatedTime
        }
        createdTime
        updatedTime
        propertyDashboardPropertyId
      }
    }
  }
`;

export const listLegislationItems = /* GraphQL */ `
query MyQuery($state: String!) {
  listLegislationDataByState(state: $state) {
    nextToken
    items {
      billID
      billNumber
      createdTime
      executiveSummary
      id
      lastAction
      lastActionDate
      relevance
      state
      status
      summary
      title
      updatedTime
      url
    }
  }
}
`;

export const listMarketDataByFips = /* GraphQL */ `
query MyQuery($fips: String!){
 listMarketDataByFIPS(fips: $fips, filter: {and: {date: {gt: "2020-01-01", le: "2025-01-01"}}}) {
    items {
      areaName
      createdTime
      dataType
      date
      daysOnMarket
      fips
      homeValueAll
      homeValueSFR
      marketHeat
      marketSummary
      rentAll
      rentSFR
    }
  }
  }
`;
