export type OwnerFromID = {
  pm: string;
  email: string;
};

export const getOwnerFromID = (ownerID: string): OwnerFromID => {
  const [pm, email] = ownerID.split('::');

  return {
    pm,
    email,
  };
};
