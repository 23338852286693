import { useMutation, useQuery } from '@tanstack/react-query';

import {
  AnnualInvitationReportResponse,
  Invitation,
} from './types';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { axiosWithPayloadContext, createHeaders } from '../axios';

export const useGetAnnualInvitationReport = () => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.ANNUAL_REPORT_INVITATION, user?.pm], async (): Promise<Invitation[]> => {
    const token = await getAccessTokenSilently();

    const url = `${config.apiBaseURL}/annual-invitation-report`;

    const { data } = await axiosWithPayloadContext<AnnualInvitationReportResponse>({
      url,
      headers: createHeaders(token),
      method: 'GET',
    });

    return data.invitations;
  }, { enabled: true });
};

export const useInviteAnnualReportOwners = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (
    emails: string[],
  ) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext<{ token: string }>({
      url: `${config.apiBaseURL}/annual-invitation-report`,
      method: 'POST',
      headers: createHeaders(token),
      data: { emails },
    });
  });
};
