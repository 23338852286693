import { AppDashboardProperty } from '../../api/dashboard-properties/types/property';

const emptyDataProperty: AppDashboardProperty = {
  units: [
    {
      id: 'temp-empty-unit-id',
      isVacant: false,
    },
  ],
  displayName: '123 Main St',
  id: 'temp-empty-property-id',
  name: 'temp-empty-property-name',
  owner: 'temp-empty-property-owner',
  pmId: 'temp-empty-property-pm-id',
  bedrooms: 4,
  bathrooms: 2,
  sqft: 2000,
  amortizationTable: null,
  createdTime: '',
  updatedTime: '',
  mortgage: null,
  metrics: null,
  transactions: [],
  groupTransactions: [],
  valuations: [],
  leases: [],
  latestValuation: null,
  purchaseHistory: null,
  address: {
    street1: '123 Main St',
    street2: null,
    city: 'Anytown',
    state: 'CA',
    zipCode: '12345',
    fullAddress: '123 Main St, Anytown, CA 12345',
    shortAddress: '123 Main St, Anytown, CA',
    fips: '12345',
  },
};

const property: AppDashboardProperty = {
  ...emptyDataProperty,
};

const properties: AppDashboardProperty[] = [...Array(4)].map((_, index) => ({
  ...property,
  id: index.toString(),
}));

export {
  emptyDataProperty,
  properties,
  property,
};
