import { ReactNode, useEffect, useState } from 'react';

import {
  objectKeys, useAnalytics, useGetPMSettings, useUpdatePmSettings,
} from 'lib';
import { useForm } from 'react-hook-form';
import { MdEdit, MdSave } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  ControlledFormattedNumberField, formatDecimalToPercentage, PercentGreen, PercentRed,
  proformaDefaultMetrics,
  ProformaMetricsData, ProformaMetricsForm, ResetIcon, Spinner, SpinnerWithLogo, TooltipMetric, useLabels,
  YesNoDialog,
} from 'ui';
import {
  Button,
  Grid, IconButton, Stack, Typography,
} from '@mui/material';

export const PmSettings = () => {
  const l = useLabels();
  const { data: pmSettings, isLoading: isLoadingPmSettings } = useGetPMSettings();
  const { mutateAsync: updateProformaMetrics, isLoading: isUpdatingMetrics } = useUpdatePmSettings();
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);

  const form = useForm<ProformaMetricsData>({
    defaultValues: proformaDefaultMetrics,
  });

  const handleResetToDefault = async () => {
    try {
      await updateProformaMetrics({
        dashboardProformaMetricsOverride: '',
      });
      setConfirmResetOpen(false);
    } catch (error) {
      console.error(error);

      toast.error(l['error.unknownError']);
    }
  };

  useEffect(() => {
    if (!pmSettings) return;

    const data = JSON.parse(pmSettings.dashboardProformaMetricsOverride || '{}');
    form.reset({
      ...proformaDefaultMetrics,
      ...data,
    });
  }, [pmSettings]);

  if (isLoadingPmSettings) {
    return (
      <Stack height="100%" width="100%" alignItems="center" justifyContent="center">
        <SpinnerWithLogo />
      </Stack>
    );
  }

  return (
    <Stack px={5}>
      <Grid container spacing={3}>
        <EditableMetrics form={form} />
        <Grid item xs={12} sm={6} md={3}>
          <Stack justifyContent="center" alignItems="center" height="100%">
            <Button variant="outlined" startIcon={<ResetIcon />} onClick={() => setConfirmResetOpen(true)}>
              {l.resetToDefault}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <YesNoDialog
        open={confirmResetOpen}
        onClose={() => setConfirmResetOpen(false)}
        title={l['proforma.resetToDefault.title']}
        description={l['proforma.resetToDefault.description']}
        onSubmit={handleResetToDefault}
        isLoading={isUpdatingMetrics}
      />
    </Stack>
  );
};

const EditableMetric = ({
  form,
  label,
  icon,
  metricKey,
  tooltip,
}: {
  form: ProformaMetricsForm,
  label: string,
  icon: ReactNode,
  tooltip: string,
  metricKey: keyof ProformaMetricsData,
}) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [editMode, setEditMode] = useState(false);
  const value = form.watch(metricKey);
  const { mutateAsync: updateProformaMetrics, isLoading } = useUpdatePmSettings();

  const handleClickEditMode = async () => {
    const isEditMode = !editMode;

    if (isEditMode) {
      setEditMode(isEditMode);
    } else {
      try {
        const data = form.getValues();
        objectKeys(proformaDefaultMetrics).forEach((key) => {
          if (data[key] === proformaDefaultMetrics[key]) {
            delete data[key];
          }

          // if the key is management fee and the current value is the same as it
        });
        await updateProformaMetrics({
          dashboardProformaMetricsOverride: JSON.stringify(data),
        });
        setEditMode(isEditMode);
      } catch (error) {
        console.error(error);

        toast.error(l['error.unknownError']);
      }
    }
  };

  return (
    <TooltipMetric
      label={label}
      displayValue={(
        <Stack direction="row" alignItems="center" gap={2} justifyContent="space-between">
          {editMode ? (
            <ControlledFormattedNumberField
              name={metricKey}
              control={form.control}
              useExternalValue
              externalValue={value}
              prefix=""
              suffix="%"
              size="small"
              decimalScale={2}
              showZero
              inputProps={{
                sx: {
                  height: 16,
                },
              }}
              onBlur={() => {
                analytics.track('Input Changed', {
                  inputName: `Proforma Metrics - ${metricKey}`,
                  value,
                });
              }}
            />
          ) : (
            <Typography variant="body1">
              {formatDecimalToPercentage(value / 100, 2)}
            </Typography>
          )}
          <IconButton onClick={handleClickEditMode} size={isLoading ? 'medium' : 'small'} disabled={isLoading}>
            {isLoading && <Spinner />}
            {!isLoading && (editMode ? <MdSave /> : <MdEdit />)}
          </IconButton>
        </Stack>
      )}
      cardSx={{ height: '100%' }}
      icon={(
        <Stack>
          {icon}
        </Stack>
      )}
      tooltipName={metricKey}
      tooltipContent={(
        <Typography variant="body2" sx={{ p: 1 }}>
          {tooltip}
        </Typography>
      )}
    />
  );
};

type MetricConfig = Record<keyof ProformaMetricsData, { label: string, icon: ReactNode, tooltip: string }>;

export const EditableMetrics = ({
  form,
}: {
  form: ProformaMetricsForm,
}) => {
  const l = useLabels();

  const metrics = form.watch();
  const metricKeys = Object.keys(metrics) as (keyof ProformaMetricsData)[];
  const iconSize = 40;

  const metricConfig: MetricConfig = {
    annualHomeAppreciation: {
      label: l.annualHomeAppreciation,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.annualHomeAppreciation.tooltip'],
    },
    annualRentGrowth: {
      label: l.annualRentGrowth,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.annualRentGrowth.tooltip'],
    },
    estimatedVacancy: {
      label: l.estimatedVacancy,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.estimatedVacancy.tooltip'],
    },
    inflationRate: {
      label: l.inflation,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.inflationRate.tooltip'],
    },
    managementFee: {
      label: l.managementFee,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.managementFee.tooltip'],
    },
    maintenance: {
      label: l.maintenanceReserve,
      icon: <PercentGreen height={iconSize} width={iconSize} />,
      tooltip: l['proforma.maintenanceReserve.tooltip'],
    },
    capEx: {
      label: l.capEx,
      icon: <PercentRed height={iconSize} width={iconSize} />,
      tooltip: l['proforma.capEx.tooltip'],
    },
  };

  return (
    <>
      {metricKeys.map((key) => (
        <Grid item xs={12} sm={6} md={3} key={key}>
          <EditableMetric
            tooltip={metricConfig[key].tooltip}
            form={form}
            label={metricConfig[key].label}
            icon={metricConfig[key].icon}
            metricKey={key}
          />
        </Grid>
      ))}
    </>
  );
};
