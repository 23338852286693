import {
  MouseEvent,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import {
  AppDashboardProperty,
  AppUserActions, history,
  PMPreferences, PMSettingsResponse, useAnalytics, useMutateUserActions,
} from 'lib';
import { IoIosLink } from 'react-icons/io';
import { IoArrowBackOutline } from 'react-icons/io5';
import {
  MdCheck, MdEdit, MdOutlineSave,
} from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Box,
  Button,
  IconButton,
  MenuItem, Select, Stack, Typography,
  useTheme,
} from '@mui/material';

import { InvertedButton } from '../../../button/styled';
import { YesNoDialog } from '../../../dialogs/YesNoDialog';
import { ResetIcon } from '../../../icons';
import { useLabels } from '../../../lib/translations';
import { Spinner } from '../../../spinner';
import { LightTooltip } from '../../../tooltip/InfoTooltip';
import { BoldTypography } from '../../../typography/BoldTypography';
import { createInitialFormData, getFormDiff } from '../form';
import { editModeSignal, PORTFOLIO, useGetPropertyIdParam } from '../state';
import { Form } from '../types';

export const ProformaHeader = ({
  properties,
  pmPreferences,
  userActions = undefined,
  pmSettings = undefined,
  form,
}: {
  properties: AppDashboardProperty[],
  pmPreferences: PMPreferences,
  userActions?: AppUserActions,
  pmSettings?: PMSettingsResponse,
  form: Form
}) => {
  const l = useLabels();
  const theme = useTheme();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const propertyID = useGetPropertyIdParam();
  const [confirmResetOpen, setConfirmResetOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const editMode = editModeSignal.value;
  const { mutateAsync: updateUserActions, isLoading: isUpdatingUserActions } = useMutateUserActions();

  const handleSelectProperty = (selected: string) => {
    analytics.track('Selector Changed', {
      selectorName: 'Property Proforma',
      value: selected || 'portfolio',
    });

    if (selected === PORTFOLIO) {
      navigate('/proforma');
    } else {
      navigate(selected ? `/proforma/${selected}` : '/proforma');
    }
  };

  const handleClickEditMode = async (event: MouseEvent) => {
    const newMode = !editModeSignal.value;

    event.preventDefault();
    event.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Edit Mode',
      editMode: newMode,
    });

    if (editDisabled) return;

    if (!newMode) {
      const diff = getFormDiff(
        properties,
        pmPreferences,
        form.getValues(),
        pmSettings,
      );

      if (!diff) {
        editModeSignal.value = newMode;

        return;
      }

      try {
        await updateUserActions({ dashboardProformaOverride: diff });
      } catch (e) {
        console.error(e);

        toast.error(l['error.unknownError']);

        return;
      }
    }

    editModeSignal.value = newMode;
  };

  const resetOverride = async () => {
    if (userActions?.dashboardProformaOverride) {
      try {
        await updateUserActions({ dashboardProformaOverride: '' });
      } catch (e) {
        console.error(e);

        toast.error(l['error.unknownError']);

        return;
      }
    }

    form.reset(createInitialFormData(properties, pmPreferences, undefined, pmSettings));
    setConfirmResetOpen(false);
  };

  const handleClickReset = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Proforma - Reset to Default',
    });

    setConfirmResetOpen(true);
  };

  const handleClickCopyLink = async () => {
    analytics.track('Button Clicked', {
      buttonName: 'Proforma - Share',
    });

    await navigator.clipboard.writeText(window.location.href);

    toast.success(l['proforma.shareConfirm']);

    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  const handleClickBack = () => {
    if (history.value.length > 1) {
      window.history.back();
    } else {
      navigate('/');
    }
  };

  const isPortfolioMode = propertyID === PORTFOLIO;
  const foundPropertyByID = isPortfolioMode || !!properties?.find((p) => p.id === propertyID);
  const editDisabled = isUpdatingUserActions || !foundPropertyByID || isPortfolioMode;

  const canResetToDefault = !isUpdatingUserActions && (!!userActions?.dashboardProformaOverride || form.formState.isDirty);

  const spinner = (
    <>
      &nbsp;
      <Spinner size={18} />
    </>
  );

  const editButton = (
    <InvertedButton
      variant="outlined"
      onClick={handleClickEditMode}
      disabled={editDisabled}
      startIcon={!isUpdatingUserActions && (editMode ? <MdOutlineSave /> : <MdEdit />)}
    >
      {isUpdatingUserActions && spinner}
      {!isUpdatingUserActions && (editMode ? l.save : l.edit)}
    </InvertedButton>
  );

  return (
    <>
      <Stack gap={3}>
        <Stack direction="row" alignItems="center" gap={2}>
          <IconButton onClick={handleClickBack} color="primary" edge="end">
            <IoArrowBackOutline size={22} />
          </IconButton>
          <BoldTypography variant="h6">
            {l['proforma.title']}
          </BoldTypography>
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" gap={3} flexWrap="wrap">
          <Select
            size="small"
            value={propertyID ?? ''}
            onChange={(e) => handleSelectProperty(e.target.value)}
            displayEmpty
          >
            {properties.length > 1 && (
              <MenuItem value={PORTFOLIO} disabled={editMode}>
                {l.portfolio}
              </MenuItem>
            )}
            {properties.map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.displayName}
              </MenuItem>
            ))}
          </Select>
          <Stack gap={3} direction="row" alignItems="center">
            <Button
              variant="outlined"
              onClick={handleClickReset}
              disabled={!canResetToDefault}
              startIcon={!isUpdatingUserActions && <ResetIcon height={16} width={16} />}
            >
              {isUpdatingUserActions ? spinner : l.resetToDefault}
            </Button>
            <LightTooltip
              arrow
              enterTouchDelay={0}
              title={isPortfolioMode ? (
                <Typography variant="body2" sx={{ p: 1 }}>
                  {l['proforma.editDisabled.tooltip']}
                </Typography>
              ) : null}
            >
              <Box component="span" onClick={handleClickEditMode}>
                {editButton}
              </Box>
            </LightTooltip>
            <LightTooltip
              arrow
              enterTouchDelay={0}
              title={(
                <Typography variant="body2" sx={{ p: 1 }}>
                  {l['tooltip.proforma.share']}
                </Typography>
              )}
            >
              <IconButton
                onClick={handleClickCopyLink}
              >
                {copied ? (
                  <MdCheck fill={theme.palette.primary.dark} />
                ) : (
                  <IoIosLink fill={theme.palette.primary.dark} />
                )}
              </IconButton>
            </LightTooltip>
          </Stack>
        </Stack>
      </Stack>
      <YesNoDialog
        open={confirmResetOpen}
        onClose={() => setConfirmResetOpen(false)}
        title={l['proforma.resetToDefault.title']}
        description={l['proforma.resetToDefault.description']}
        onSubmit={resetOverride}
        isLoading={isUpdatingUserActions}
      />
    </>
  );
};
