import { useAnalytics } from 'lib';
import { toast } from 'react-toastify';
import {
  Banner1, InvertedButton, ShareLinkButton, useLabels,
} from 'ui';

export const InviteProspectBanner = () => {
  const l = useLabels();
  const analytics = useAnalytics();

  return (
    <Banner1
      title={l['listings.marketplace.inviteProspectsBanner.title']}
      buttonComponent={(
        <ShareLinkButton
          ButtonComponent={InvertedButton}
          buttonText={l['listings.marketplace.inviteProspectsBanner.buttonText']}
          copyURL={`${window.location.origin}/?register=true`}
          onCopy={() => {
            analytics.track('Button Clicked', {
              buttonName: 'Invite Prospect',
            });

            toast.success(l['pm-dashboard.prospect.copyLink']);
          }}
        />
      )}
    />
  );
};
