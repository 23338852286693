import { useState } from 'react';

import {
  GrowthPolicy, SuggestedPartnerStatus, useAnalytics, useDebounce, useListStates, useTrackPageBottom,
} from 'lib';
import {
  BlurredComponent, PartnersIcon, UpgradeProductDialog, useLabels,
} from 'ui';
import { Grid, Stack, Typography } from '@mui/material';

import { SortOption, sortOptionToFunction } from './Header';
import { partnerStatusState } from './state';
import { SuggestedPartnerCard } from './SuggestedPartnerCard';
import { SuggestedPartner } from '../../api/suggested-partners/types';

export const SuggestedPartners = ({
  suggestedPartners,
  growthPolicy,
  initialSearch,
  selectedStates = [],
  sortOption,
  enabled = false,
  upSellIfDisabled = false,
  disabledTitle,
  disabledSubtitle,
  disabledActionText = undefined,
}: {
  suggestedPartners: SuggestedPartner[],
  growthPolicy: GrowthPolicy | null,
  initialSearch: string,
  selectedStates?: string[],
  sortOption: SortOption,
  disabledTitle: string,
  disabledSubtitle: string,
  disabledActionText?: string,
  enabled?: boolean
  upSellIfDisabled?: boolean
}) => {
  const partnersPerPage = 24;
  const l = useLabels();
  const analytics = useAnalytics();
  const { data: states, isLoading: isLoadingStates } = useListStates();

  const [isUpgradeProductDialogOpen, setIsUpgradeProductDialogOpen] = useState(false);
  const [,search] = useDebounce(initialSearch, 500);

  const [maxPartnersToShow, setMaxPartnersToShow] = useState<Record<SuggestedPartnerStatus, number>>({
    [SuggestedPartnerStatus.NEW]: partnersPerPage,
    [SuggestedPartnerStatus.IN_PROGRESS]: 0,
    [SuggestedPartnerStatus.WON]: 0,
    [SuggestedPartnerStatus.LOST]: 0,
  });

  const statusToPartners: Record<SuggestedPartnerStatus, SuggestedPartner[]> = {
    [SuggestedPartnerStatus.NEW]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.NEW),
    [SuggestedPartnerStatus.IN_PROGRESS]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.IN_PROGRESS),
    [SuggestedPartnerStatus.WON]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.WON),
    [SuggestedPartnerStatus.LOST]: suggestedPartners.filter((sp) => sp.status === SuggestedPartnerStatus.LOST),
  };

  const partnerSearchFilter = (partner: SuggestedPartner) => {
    const matchBySearch = (
      partner.name.toLowerCase().includes(search.toLowerCase())
      || partner.email.toLowerCase().includes(search.toLowerCase())
    );

    const partnerStateAbbreviations = partner.workingCounties.map((county) => county.state);
    const stateMap = selectedStates.map((state) => states?.statesByName.get(state)?.abbreviation);
    const matchByState = stateMap.length === 0 || stateMap.some((state) => partnerStateAbbreviations.includes(state ?? ''));

    return matchBySearch && matchByState;
  };

  const partners = statusToPartners[partnerStatusState.value]
    .sort(sortOptionToFunction[sortOption]).filter(partnerSearchFilter);

  const noData = partners.length === 0;

  useTrackPageBottom(() => {
    analytics.track('Page Bottom Reached', {
      pageName: 'Suggested Partners',
      partnerStatus: partnerStatusState.value,
      maxPartnersToShow: maxPartnersToShow[partnerStatusState.value],
    });

    setMaxPartnersToShow((prevMaxPartnersToShow) => ({
      ...prevMaxPartnersToShow,
      [partnerStatusState.value]: prevMaxPartnersToShow[partnerStatusState.value] + partnersPerPage,
    }));
  });

  const onClickAction = () => {
    analytics.track('Button Clicked', {
      buttonName: 'Unlock Suggested Partners',
    });

    setIsUpgradeProductDialogOpen(true);
  };

  return !isLoadingStates && (
    <>
      <BlurredComponent
        showMessageInCard
        title={disabledTitle}
        subtitle={disabledSubtitle}
        actionText={upSellIfDisabled && !enabled ? disabledActionText : undefined}
        onClickAction={upSellIfDisabled && !enabled ? onClickAction : undefined}
        isBlurred={!enabled}
        height={noData ? '100%' : 'calc(100vh - 300px)'}
        blurLevel="low"
      >
        <Stack gap={3}>
          {noData ? (
            <Stack flexGrow={1} justifyContent="center" alignItems="center" p={10}>
              <PartnersIcon height={40} width={40} />
              <Typography variant="h6">
                {l['partners.noPartnersFound']}
              </Typography>
            </Stack>
          ) : (
            <Grid container spacing={3}>
              {partners.slice(0, maxPartnersToShow[partnerStatusState.value] || partners.length).map((partner) => (
                <Grid item xs={12} sm={6} md={4} key={partner.email}>
                  <SuggestedPartnerCard partner={partner} growthPolicy={growthPolicy} />
                </Grid>
              ))}
            </Grid>
          )}
          {!noData && maxPartnersToShow[partnerStatusState.value] >= partners.length && (
            <Typography variant="body2" color="secondary" sx={{ textAlign: 'center' }}>
              {l['partners.noMorePartners']}
            </Typography>
          )}
        </Stack>
      </BlurredComponent>
      <UpgradeProductDialog
        open={isUpgradeProductDialogOpen}
        onClose={() => setIsUpgradeProductDialogOpen(false)}
      />
    </>
  );
};
