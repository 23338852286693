import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useQuery } from '@tanstack/react-query';

import { convertLegislationData, convertMarketData } from './converters';
import {
  CreateDashboardMetricsRequest, LegislationItem, MarketAnalysisData,
} from './types';
import { createMutationHook } from '../../hooks/createMutationHook';
import { useAuth } from '../../hooks/useAuth';
import { filterNulls } from '../../lib/filter';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import {
  LegislationData,
  ListLegislationDataByStateQuery,
  ListMarketDataByFIPSQuery,
  MarketData,
} from '../API';
import { listLegislationItems, listMarketDataByFips } from '../graphql/queries';

export const useListLegislationData = (state: string) => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.LEGISLATION_ITEMS, user?.pm, state], async (): Promise<LegislationItem[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listLegislationItems,
      authToken: token,
      variables: { owner: user?.id ?? '', state },
    }) as GraphQLResult<ListLegislationDataByStateQuery>;

    if (!res.data?.listLegislationDataByState) {
      return [];
    }

    const legislationItems = filterNulls<LegislationData>(
      res.data.listLegislationDataByState.items,
    ).map(convertLegislationData);

    return (legislationItems);
  }, { enabled: !!user?.id });
};

export const useListMarketAnalysis = (fips: string) => {
  const { user, getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.MARKET_ANALYSIS, fips], async (): Promise<MarketAnalysisData[]> => {
    const token = await getAccessTokenSilently();

    const res = await queryGraphQL({
      query: listMarketDataByFips,
      authToken: token,
      variables: { owner: user?.id ?? '', fips },
    }) as GraphQLResult<ListMarketDataByFIPSQuery>;

    if (!res.data?.listMarketDataByFIPS) {
      return [];
    }

    const marketDatas: MarketAnalysisData[] = filterNulls<MarketData>(res.data.listMarketDataByFIPS.items).map(convertMarketData);

    return (marketDatas);
  }, { enabled: !!user?.id && !!fips });
};

export const useCreateMetrics = createMutationHook<CreateDashboardMetricsRequest>('/property-metrics', 'POST');

export const useFetchMetrics = (payload: CreateDashboardMetricsRequest, isEnabled: boolean) => {
  const { user } = useAuth();

  const createMetrics = useCreateMetrics();
  return useQuery({
    queryKey: [QueryKey.FETCH_METRICS, user?.id],
    queryFn: async () => {
      const result = await createMetrics.mutateAsync(payload);
      return result.data;
    },
    enabled: isEnabled,
  });
};
