import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { convertPMSettings } from './converters';
import { PMSettingsResponse } from './types';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import { GetPMSettingsQuery } from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import { getPMSettingsQuery } from '../graphql/queries';

export const useGetPMSettings = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.PM_SETTINGS, pm], async (): Promise<PMSettingsResponse | null> => {
    if (!pm) return null;

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getPMSettingsQuery,
      variables: { pm },
      authToken: token,
    }) as GraphQLResult<GetPMSettingsQuery>;

    if (!res.data?.getPMSettings) {
      return null;
    }

    return convertPMSettings(res.data?.getPMSettings);
  });
};

export const useUpdatePmSettings = () => {
  const queryClient = useQueryClient();
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useMutation(async (data: {
    leadershipSummary2024?: string,
    dashboardProformaMetricsOverride?: string,
  }) => {
    const token = await getAccessTokenSilently();

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/pm-settings/${pm}`,
      method: 'PUT',
      headers: createHeaders(token),
      data,
    });

    queryClient.invalidateQueries([QueryKey.PM_SETTINGS, pm]);
  });
};
