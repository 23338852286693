import { useState } from 'react';

import {
  AppDashboardProperty, BillStatus, LegislationItem as LegislationItemData, useListLegislationData,
} from 'lib';
import { Box, Button, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { LegislationStatusChip } from './LegislationStatusChip';
import { annualReportAreaDataPropertyID } from './state';
import { JudgeHammerCircle, PinkDotLightIcon } from '../../icons';
import { useLabels } from '../../lib/translations';
import { FallbackSpinner } from '../../spinner';
import { BoldTypography } from '../../typography/BoldTypography';

export const LegislationItem = ({ legislationData }: { legislationData: LegislationItemData }) => (
  <Stack direction="row" key={legislationData.id} alignItems="flex-start" gap={2}>
    <Box>
      <PinkDotLightIcon className="pink-dot-icon" />
    </Box>
    <Stack gap={1}>
      <BoldTypography
        variant="body2"
        sx={{
          textAlign: 'justify',
          cursor: 'pointer',
        }}
        onClick={() => window.open(legislationData.url, '_blank')}
      >
        {`${legislationData.billNumber} ${legislationData.title}`}
      </BoldTypography>
      <Typography
        component="span"
        variant="body2"
        sx={{
          textAlign: 'justify',
        }}
      >
        {legislationData.executiveSummary}
        {' '}
        <LegislationStatusChip status={legislationData.status || BillStatus.CANCELLED} />
      </Typography>
    </Stack>
  </Stack>
);

export const LegislationOverview = ({ properties }: { properties: AppDashboardProperty[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const currentProperty = properties?.find((p) => p.id === annualReportAreaDataPropertyID.value);
  const stateToUse = currentProperty ? currentProperty.address.state : '';
  const [numberOfLegislationItemsToShow, setNumberOfLegislationItemsToShow] = useState(8);
  const { data: legislationData, isLoading } = useListLegislationData(stateToUse);

  const legislationDataParsed = legislationData ?? [];
  const legislationDataSorted = legislationDataParsed.sort((a, b) => b.relevance - a.relevance);
  const legislationDataFiltered = legislationDataSorted.slice(0, numberOfLegislationItemsToShow);
  const isAllDataLoaded = legislationDataParsed.length === legislationDataFiltered.length;

  const handleClickShowMoreLegislation = () => {
    setNumberOfLegislationItemsToShow(numberOfLegislationItemsToShow + 2);
  };

  if (isLoading || !legislationDataFiltered || stateToUse === '') {
    return <FallbackSpinner />;
  }

  return (
    <Stack>
      <Stack direction="row" alignItems="center" gap={2} pb={4}>
        <JudgeHammerCircle />
        <BoldTypography variant="body1">{l['annualReport.legislationOverview.title']}</BoldTypography>
      </Stack>
      <Grid container spacing={5}>
        {
          legislationDataFiltered.map((item) => (
            <Grid item xs={12} md={6} key={item.id}>
              <LegislationItem legislationData={item} />
            </Grid>
          ))
        }
        {!isAllDataLoaded && (
          <Grid item xs={12} md={12}>
            <Stack width="100%" justifyContent="center" alignItems="center" py={2}>
              <Button
                variant="text"
                onClick={handleClickShowMoreLegislation}
                sx={{ textTransform: 'none', color: theme.palette.primary.dark }}
              >
                {l.showMore}
              </Button>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Stack>
  );
};
