import Lottie from 'lottie-react';
import { Box } from '@mui/material';

import animationData from './assets/portfolio.json';

type Props = {
  height?: number | string;
  width?: number | string;
};

export const PortfolioLottie = ({ height = 32, width = 32 }: Props) => (
  <Box height={height} width={width}>
    <Lottie animationData={animationData} loop />
  </Box>
);
