import { GraphQLResult } from '@aws-amplify/api-graphql';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { convertUserActions } from './converters';
import { config } from '../../config/config';
import { useAuth } from '../../hooks/useAuth';
import { AppUserActions } from '../../types/appUserActions';
import { QueryKey } from '../../types/enums';
import { queryGraphQL } from '../amplify';
import { GetUserActionsQuery } from '../API';
import { axiosWithPayloadContext, createHeaders } from '../axios';
import { getUserActions } from '../graphql/queries';

export const useGetUserActions = (owner:string) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.USER_ACTIONS], async (): Promise<AppUserActions> => {
    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getUserActions,
      authToken: token,
      variables: { owner },
    }) as GraphQLResult<GetUserActionsQuery>;

    return convertUserActions(res.data?.getUserActions);
  }, { enabled: !!owner });
};

export const useMutateUserActions = () => {
  const { user, getAccessTokenSilently } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(async (data: {
    viewedWelcomeToMarketplaceScreen?: boolean | undefined,
    viewedCashOutTooltip?: boolean | undefined,
    dashboardProformaOverride?: string | undefined,
    viewedAnnualReportBanner?: boolean | undefined,
  }) => {
    const token = await getAccessTokenSilently();

    const reqData = { ...data };

    if (user?.actAs) {
      delete reqData.viewedCashOutTooltip;
      delete reqData.viewedWelcomeToMarketplaceScreen;
      delete reqData.viewedAnnualReportBanner;
    }

    if (Object.keys(reqData).length === 0) return;

    await axiosWithPayloadContext({
      url: `${config.apiBaseURL}/user-actions`,
      method: 'PATCH',
      headers: createHeaders(token),
      data: reqData,
    });

    await queryClient.invalidateQueries([QueryKey.USER_ACTIONS]);
  });
};
