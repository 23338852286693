export enum QueryKey {
  SELLABLE_PROPERTIES = 'marketplace_sellableProperties',
  PROPERTY_IDS = 'marketplace_propertyIds',
  USER_ACTIONS = 'user_actions',
  USERS = 'users',
  OWNER_LEADS = 'owner_leads',
  OWNER_LEADS_REFERRAL_LINKS = 'owner_leads_referral_links',
  STATES = 'states',
  PM_PREFERENCES = 'pm_preferences',
  PM_SETTINGS = 'pm_settings',
  ANNUAL_REPORT_INVITATION = 'annual_report_invitation',
  PROPERTIES = 'dashboard_properties',
  LEGISLATION_ITEMS = 'dashboard_legislation_items',
  MARKET_ANALYSIS = 'dashboard_market_analysis',
  FETCH_METRICS = 'dashboard_fetch_metrics',
  RECURRING_EXPENSE = 'dashboard_recurringExpense',
}
