import { ReactNode } from 'react';

import { Label, Transaction } from 'lib';

export type TableItem = {
  id: string;
  propertyName: string;
  amount: number;
  date: string;
  originalCategory: string;
  category: string;
  transactions: Transaction[]
  label?: ReactNode,
  rawLabel?: Label | null,
  isEditable: boolean,
  propertyId: string;
  fullAddress?: string
};

export enum Order {
  ASC = 'asc',
  DESC = 'desc',
}

export type OrderByType = 'date' | 'amount';
