import { SyntheticEvent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAnalytics, useMissingData } from 'lib';
import { MdClear, MdHomeWork } from 'react-icons/md';
import { Avatar, useLabels } from 'ui';
import {
  Box,
  IconButton,
  Snackbar,
  styled,
  Typography,
  useTheme,
} from '@mui/material';

import { RequiresPropertiesProps } from './types';

const closedModalKey = 'closedMissingPropertyDataModal';

const SnackbarCustom = styled(Snackbar)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '4px',
  bottom: '60px !important',
  padding: theme.spacing(3),
  boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.24)',
  maxWidth: '400px',
  [theme.breakpoints.up('lg')]: {
    bottom: '10px !important',
    left: 16,
  },
}));

export const MissingPropertyDataModal = ({ properties }: RequiresPropertiesProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [missingDataPropertyID, setMissingDataPropertyID] = useState('');
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const { missingData } = useMissingData(properties);

  useEffect(() => {
    if (properties?.length && !sessionStorage.getItem(closedModalKey)) {
      const foundMissingData = false;
      (['cashInvested', 'purchasePrice', 'principalPaid', 'principalPaydown', 'appreciation'] as const).forEach((key) => {
        if (foundMissingData) return;
        if (missingData[key].isMissing) {
          setOpen(true);
          setMissingDataPropertyID(missingData[key].propertyIDs[0]);
        }
      });
    }
  }, [properties?.length]);

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    sessionStorage.setItem(closedModalKey, 'true');
    setOpen(false);

    analytics.track('Button Clicked', {
      buttonName: 'Close',
      widgetName: 'Missing Data',
    });
  };

  return (
    <SnackbarCustom
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      onClose={handleClose}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Avatar skin="light" variant="rounded" color="primary" sx={{ mr: 3 }}>
          <MdHomeWork color={theme.palette.primary.contrastText} size={24} />
        </Avatar>
        <Box>
          <Typography
            color="primary.contrastText"
            variant="h6"
          >
            {l.missingPropertyData}
          </Typography>
          <Typography variant="body2" color="primary.contrastText" sx={{ opacity: 0.8 }}>
            {l['missingData.provideInfo1']}
            <Link
              style={{ color: theme.palette.primary.contrastText }}
              to={`/onboarding/${missingDataPropertyID}`}
            >
              {l['missingData.updateProperties']}
            </Link>
            {l['missingData.provideInfo2']}
          </Typography>
        </Box>
        <IconButton onClick={handleClose}>
          <MdClear color={theme.palette.primary.contrastText} />
        </IconButton>
      </Box>
    </SnackbarCustom>
  );
};
