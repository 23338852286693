import {
  useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ReportType, useAnalytics } from 'lib';
import { MdAutoGraph } from 'react-icons/md';
import {
  Avatar,
  BoldTypography,
  EmptyFullPageContainer,
  FallbackSpinner,
  useLabels,
  useLayoutStyles,
} from 'ui';
import {
  Box,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack, styled, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import {
  CashFlow, LoanPayments, NetOperatingIncome,
  OperatingExpense, OperatingIncome,
} from './widget/detailed';
import {
  MonthlyAppreciationWidget, OccupancyWidget, PortfolioValueWidget, TotalEquityWidget,
} from './widget/small';
import { Widget } from './widget';
import { useGetReport, useListOwnerReports } from '../../api/reports';

const DarkBackgroundSkeleton = styled(Skeleton)(({ theme }) => ({
  '&.MuiSkeleton-root': {
    background: theme.palette.grey[200],
  },
  '&.MuiSkeleton-root::after': {
    background: 'linear-gradient(90deg, transparent, rgba(39, 38, 41, 0.15), transparent)',
  },
}));

const ReportSkeleton = ({ isPortfolio = false }: { isPortfolio?: boolean }) => {
  const l = useLabels();
  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));
  const headerSkeletonHeight = 40;
  const headerSkeletonWidth = 80;

  return (
    <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 16 }}>
      <Grid item xs={4}>
        <Widget
          isDark
          title={isPortfolio ? l['reports.portfolio.estValue'] : l['reports.estValue']}
          tooltip={isPortfolio ? l['reports.portfolio.estValue.tooltip'] : l['reports.estValue.tooltip']}
        >
          <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <Typography variant="h4">
              <DarkBackgroundSkeleton variant="text" width={headerSkeletonWidth} height={headerSkeletonHeight} animation="wave" />
            </Typography>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={4}>
        <Widget
          title={l['reports.monthlyAppreciation']}
          tooltip={isPortfolio ? l['reports.portfolio.monthlyAppreciation.tooltip'] : l['reports.portfolio.tooltip']}
        >
          <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <Typography variant="h4">
              <DarkBackgroundSkeleton variant="text" width={headerSkeletonWidth} height={headerSkeletonHeight} animation="wave" />
            </Typography>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={4}>
        <Widget
          title={l['reports.totalEquity']}
          tooltip={isPortfolio ? l['reports.portfolio.totalEquity.tooltip'] : l['reports.totalEquity.tooltip']}
        >
          <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <Typography variant="h4">
              <DarkBackgroundSkeleton variant="text" width={headerSkeletonWidth} height={headerSkeletonHeight} animation="wave" />
            </Typography>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={4}>
        <Widget
          title={l['reports.occupancy']}
          tooltip={l['reports.portfolio.occupancy.tooltip']}
        >
          <Box sx={{ flexDirection: 'row', alignItems: 'center', display: 'flex' }}>
            <Typography variant="h4">
              <DarkBackgroundSkeleton variant="text" width={headerSkeletonWidth} height={headerSkeletonHeight} animation="wave" />
            </Typography>
          </Box>
        </Widget>
      </Grid>
      {isDesktop ? (
        <>
          <Grid item xs={8} md={8}>
            <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
              <OperatingIncome value={0} totalOtherIncome={0} showSkeleton />
              <OperatingExpense expenses={null} hideBorder showSkeleton />
            </Card>
          </Grid>
          <Grid item xs={8} md={8}>
            <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
              <NetOperatingIncome value={0} capRate={0} showSkeleton />
              <LoanPayments value={0} showSkeleton />
              <CashFlow netCashFlow={0} cashOnCash={0} showSkeleton />
            </Card>
          </Grid>
        </>
      ) : (
        <Grid item xs={4} md={8} mb={6}>
          <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
            <OperatingIncome value={0} totalOtherIncome={0} showSkeleton />
            <OperatingExpense expenses={null} showSkeleton />
            <NetOperatingIncome value={0} capRate={0} showSkeleton />
            <LoanPayments value={0} showSkeleton />
            <CashFlow netCashFlow={0} cashOnCash={0} showSkeleton />
          </Card>
        </Grid>
      )}
    </Grid>
  );
};

export const Reports = () => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const navigate = useNavigate();
  const { setPadding: setPaddingDisabled } = useLayoutStyles();
  const { data: ownerReports, isLoading: ownerReportsLoading } = useListOwnerReports();
  const { reportId } = useParams();
  const {
    data: reportData, isLoading: reportLoading,
  } = useGetReport(reportId, !!reportId);

  const isPortfolio = reportData?.reportType === 'portfolio';
  const [selectedOwnerReportID, setSelectedOwnerReportID] = useState<string>('');
  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));

  const handleSelectReport = (id: string) => {
    navigate(`/reports/${id}`);
  };

  const optionalReports = useMemo(() => {
    if (selectedOwnerReportID && ownerReports) {
      const ownerReport = ownerReports.find((owr) => owr.id === selectedOwnerReportID);
      if (ownerReport) {
        return ownerReport!.reports!.items;
      }
    }
    return [];
  }, [selectedOwnerReportID, ownerReports]);

  useEffect(() => {
    if (ownerReports && ownerReports.length > 0) {
      if (reportId) {
        const chosenOwnerReport = ownerReports.find((owr) => owr.reports?.items.find((r) => r.id === reportId));
        setSelectedOwnerReportID(chosenOwnerReport?.id || '');
      } else {
        const portfolioReport = ownerReports[0].reports?.items.find((r) => r.reportType === ReportType.portfolio);

        handleSelectReport(portfolioReport ? portfolioReport.id : ownerReports[0].reports?.items[0].id || '');
      }
    }
  }, [ownerReports, reportId]);

  useEffect(() => {
    setPaddingDisabled(true);

    return () => {
      setPaddingDisabled(false);
    };
  }, []);

  if (ownerReportsLoading) {
    return (
      <FallbackSpinner />
    );
  }

  if (ownerReports?.length === 0 || (!ownerReportsLoading && !reportLoading && !reportData)) {
    return (
      <EmptyFullPageContainer>
        <Avatar skin="light" variant="circular" color="info" sx={{ mb: 3, height: 72, width: 72 }}>
          <MdAutoGraph color={theme.palette.primary.main} size={40} />
        </Avatar>
        <BoldTypography>There are no available reports yet.</BoldTypography>
      </EmptyFullPageContainer>
    );
  }

  return (
    <>
      <Stack
        direction="column"
        mx={0}
        sx={{
          background: theme.palette.background.paper,
          zIndex: 'appBar',
          position: 'sticky',
          top: 0,
          boxShadow: 4,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          p={3}
          m={0}
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack gap={2} direction="row" justifyContent="space-between" sx={{ alignItems: 'center', width: '400px' }}>
            <FormControl>
              <InputLabel>{l['reports.filters.date']}</InputLabel>
              <Select
                label={l['reports.filters.date']}
                value={selectedOwnerReportID}
                onChange={(event) => {
                  setSelectedOwnerReportID(event.target.value);
                  const ownerReport = ownerReports?.find((owr) => owr.id === event.target.value);
                  if (ownerReport) {
                    handleSelectReport(ownerReport!.reports!.items[0].id);
                  }
                  analytics.track('Selector Changed', {
                    value: event.target.value,
                    selectorName: 'Owner Report Date',
                  });
                }}
                inputProps={{ sx: { mr: 5 } }}
              >
                {ownerReports?.map((p) => {
                  const v = `${p.month}/${p.year}`;
                  return (
                    <MenuItem value={p.id} key={v}>
                      {v}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel>{l['reports.filters.type']}</InputLabel>
              <Select
                label={l['reports.filters.type']}
                value={selectedOwnerReportID ? reportId : ''}
                onChange={(event) => {
                  handleSelectReport(event.target.value);
                  analytics.track('Selector Changed', {
                    value: event.target.value,
                    selectorName: 'Report Type',
                  });
                }}
              >
                {optionalReports?.map((report) => (
                  <MenuItem value={report.id} key={report.id}>
                    {report.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        py={6}
        px={isDesktop ? 6 : 2}
        gap={2}
      >
        {reportLoading ? (
          <ReportSkeleton isPortfolio={isPortfolio} />
        ) : (
          <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 16 }}>
            <PortfolioValueWidget value={(reportData as any)!.homeValue} isPortfolio={isPortfolio} />
            <MonthlyAppreciationWidget
              isPortfolio={isPortfolio}
              amount={reportData!.appreciation}
            />
            <TotalEquityWidget isPortfolio={isPortfolio} totalEquity={reportData!.totalEquity} />
            <OccupancyWidget
              totalOccupiedUnits={reportData!.totalOccupiedUnits}
              totalUnits={reportData!.totalUnits}
              isPortfolio={isPortfolio}
            />
            {isDesktop ? (
              <>
                <Grid item xs={8} md={8}>
                  <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
                    <OperatingIncome value={reportData!.totalRent} totalOtherIncome={reportData?.totalOtherIncome} />
                    <OperatingExpense expenses={reportData!.expenses} hideBorder />
                  </Card>
                </Grid>
                <Grid item xs={8} md={8}>
                  <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
                    <NetOperatingIncome value={reportData!.noi} capRate={reportData!.yearlyMeasures.capRate} />
                    <LoanPayments value={reportData!.expenses.mortgage} />
                    <CashFlow netCashFlow={reportData!.netCashFlow} cashOnCash={reportData!.yearlyMeasures.cashOnCash} />
                  </Card>
                </Grid>
              </>
            ) : (
              <Grid item xs={4} md={8} mb={6}>
                <Card sx={{ height: '100%', boxShadow: '0px 4px 10px rgba(23, 55, 113, 0.06)' }}>
                  <OperatingIncome value={reportData!.totalRent} totalOtherIncome={reportData?.totalOtherIncome} />
                  <OperatingExpense expenses={reportData!.expenses} />
                  <NetOperatingIncome value={reportData!.noi} capRate={reportData!.yearlyMeasures.capRate} />
                  <LoanPayments value={reportData!.expenses.mortgage} />
                  <CashFlow netCashFlow={reportData!.netCashFlow} cashOnCash={reportData!.yearlyMeasures.cashOnCash} />
                </Card>
              </Grid>
            )}
          </Grid>
        )}
      </Stack>
    </>
  );
};
