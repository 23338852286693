import { Expense } from './Expense';
import { DetailRow } from './Row';
import { formatNumberToCurrency } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { BoldTypography } from '../../../typography/BoldTypography';
import { SemiBoldTypography } from '../../../typography/SemiBoldTypography';
import { useGetPropertyIdParam } from '../state';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const EstimatedVacancy = ({ form }: RowProps) => {
  const l = useLabels();

  const propertyID = useGetPropertyIdParam();
  const estimatedVacancy = form.watch(`${propertyID}.expenses.estimatedVacancy`);
  const projections = useProjections(form.watch(), propertyID);
  return (
    <DetailRow
      rowID="estimated-vacancy"
      columns={[
        {
          id: 'estimated-vacancy',
          content: (
            <SemiBoldTypography variant="body1" sx={{ pl: 8 }}>
              {l.estimatedVacancy}
            </SemiBoldTypography>
          ),
        },
        ...projections.years.map((year, i) => ({
          id: `estimated-vacancy-year-${year}`,
          content: year === 1 ? (
            <Expense
              form={form}
              showAsNegative
              expenseName="estimatedVacancy"
              value={estimatedVacancy}
              label={l.estimatedVacancy}
            />
          ) : (
            <BoldTypography variant="body1">
              {estimatedVacancy !== 0 && '-'}
              {formatNumberToCurrency(projections.estimatedVacancy[i] || 0, 0)}
            </BoldTypography>
          ),
        })),
      ]}
    />
  );
};
