import {
  createContext, ReactElement, ReactNode, useState,
} from 'react';

import { TrackFunction, TrackingProperties } from '../analytics/types';
import { Features } from '../hooks/useFeature';

export interface Analytics {
  track: TrackFunction;
  register: (properties: TrackingProperties) => void;
  identify: (userId: string, properties: TrackingProperties, features: Omit<Features, 'isLoading'>,) => void;
  page: () => Promise<void>;
}

export const AnalyticsContext = createContext<Analytics>({
  track: () => { throw new Error('AnalyticsContext not initialized'); },
  register: () => {},
  identify: () => {},
  page: async () => {},
});

export const AnalyticsProvider = (
  { children, analyticsObj }: { children: ReactNode, analyticsObj: Analytics },
): ReactElement => {
  const [analytics] = useState<Analytics>(analyticsObj);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const AnalyticsConsumer = AnalyticsContext.Consumer;
