import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { recommendationBackgroundURL } from '../../assets';
import { BoldTypography } from '../../typography/BoldTypography';

export const PinkDotBanner = () => {
  const theme = useTheme();

  return (
    <Stack
      alignItems="center"
      px={6}
      py={7}
      height="80px"
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '6px',
        backgroundImage: `url(${recommendationBackgroundURL})`,
        backgroundSize: 'cover',
      }}
      justifyContent="center"
    >
      <BoldTypography variant="body1">lorum ipsum dolor sit amet</BoldTypography>
      <Typography variant="body2">consectetur adipiscing elit. Duis elementum, quam et tincidunt gravida</Typography>
    </Stack>
  );
};
