export const listOwnerReports = /* GraphQL */ `
query ListOwnerReports($owner: String!) {
  listOwnerReportsByOwner(owner: $owner) {
         items {
        id
        year
        month
        owner
        reports {
          items {
            id
            name
            createdAt
            reportType
          }
        }
      }
  }
}
`;

export const listOwnerReportsIds = /* GraphQL */ `
query ListReportIdsQuery($owner: String!) {
  listOwnerReportsByOwner(owner: $owner) {
    items {
      id
    }
  }
}
`;

export const getReport = /* GraphQL */ `
  query GetReport($id: ID!){
    getReport(id: $id){
      id
      appreciation
      name
      homeValue
      netCashFlow
      noi
      totalOccupiedUnits
      ownerReportReportsId
      reportType
      totalEquity
      totalUnits
      totalRent
      totalOtherIncome
      updatedAt
      version
      yearlyMeasures {
        capRate
        cashOnCash
      }
      previousMonthlyMeasures {
        appreciation
        homeValue
        totalEquity
      }
      expenses {
        insurance
        hoa
        managementFee
        mortgage
        other
        tax
        totalExpense
        utilities
        legalAndProfessionalFees
        rentalRegistration
        autoAndTravel
        supplies
        maintenance
        leasingAndMarketing
      }
    }
  }
`;

export const searchActiveListingPropertyIDs = /* GraphQL */ `
  query searchMarketplaceProperties($nextToken: String) {
    searchMarketplacePropertyListingItems(filter: {status: {eq: "active"}}, nextToken: $nextToken, limit: 1000) {
      nextToken
      items {
        id
        marketplacePropertyListingItemsId
        property {
          id
        }
      }
    }
  }
`;
