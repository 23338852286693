import { RiArrowRightLine } from 'react-icons/ri';
import Carousel from 'react-multi-carousel';
import { BoldTypography, useLabels } from 'ui';
import {
  Box, Button, Collapse, Grid, Stack, useMediaQuery, useTheme,
} from '@mui/material';

import { carouselResponsiveConfig } from './carousel';
import { ListingCard } from './ListingCard';
import { ListingSkeletons } from './ListingSkeletons';
import { ListingProperty } from '../../types/property';

export type CategoryListingsProps = {
  categoryLabel: string,
  properties: ListingProperty[],
  isLoading: boolean,
  forceLoading: boolean,
  changeExpandedCategory: () => void,
  showExpandButton: boolean,
  filtersCleared: boolean,
  useClassName: boolean,
  forceHide?: boolean,
};

export const CategoryListings = ({
  categoryLabel,
  properties,
  isLoading,
  forceLoading,
  changeExpandedCategory,
  showExpandButton,
  filtersCleared,
  useClassName,
  forceHide = false,
}: CategoryListingsProps) => {
  const l = useLabels();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Collapse in={filtersCleared && !forceHide} timeout={300} collapsedSize={0} sx={{ width: '100%' }}>
      <Collapse
        in={false}
        timeout={300}
        collapsedSize={450}
        className={useClassName ? 'listings-container' : ''}
      >
        <Grid container item xs={12} rowGap={4} sx={{ transition: 'all 0.3s ease-in-out !important', flexGrow: 1 }}>
          <Grid item xs={12} flexGrow={1}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <BoldTypography variant="body1">
                {categoryLabel}
              </BoldTypography>
              {showExpandButton && (
                <Button
                  variant="text"
                  onClick={changeExpandedCategory}
                  endIcon={<RiArrowRightLine size={18} />}
                >
                  {l.seeAll}
                </Button>
              )}
            </Stack>
          </Grid>
          <Grid container item xs={12} rowGap={20} spacing={3} pb={3}>
            {!isLoading && isMobile && (
              <Grid item xs={12}>
                <Carousel
                  containerClass="properties-carousel-container"
                  partialVisible
                  arrows={false}
                  responsive={carouselResponsiveConfig}
                >
                  {properties.slice(0, 3).map((listing, index) => (
                    <Box key={listing.id}>
                      <ListingCard listingIndex={index} listingItem={listing} />
                    </Box>
                  ))}
                </Carousel>
              </Grid>
            )}
            {!isLoading && !isMobile && properties.map((listing, index) => (
              <Grid item xs={12} md={4} key={listing.id}>
                <ListingCard listingIndex={index} listingItem={listing} />
              </Grid>
            ))}
            {(forceLoading || isLoading) && (
              <ListingSkeletons />
            )}
          </Grid>
        </Grid>
      </Collapse>
    </Collapse>
  );
};
