import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { useLabels } from '../../lib/translations';

export const LegalDisclaimer = () => {
  const l = useLabels();
  const text = l['annualReport.disclaimerText'];

  return (
    <Stack
      gap={3}
      alignItems="flex-start"
      direction="row"
      sx={{
        backgroundColor: 'rgba(247, 247, 250, 1)',
        padding: 4,
        paddingTop: 0,
        gap: 4,
        borderRadius: '6px',
        boxShadow: 'none',
        paddingBottom: 2,
      }}
    >
      <Typography
        variant="body1"
        sx={{
          opacity: 0.8,
          letterSpacing: '0.15px',
          fontSize: '0.75rem',
          textAlign: 'justify',
          padding: 1,
        }}
      >
        {text}
      </Typography>
    </Stack>
  );
};
