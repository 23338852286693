import {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { useConfigCatClient } from 'configcat-react';

import { useAuth } from './useAuth';

export enum FeatureFlag {
  IS_PM_DASHBOARD_PERFORMANCE_REVIEW_ENABLED = 'isPmDashboardPerformanceReviewEnabled',
  LISTING_CARD_GROSS_YIELD_AS_DOLLAR = 'listingCardGrossYieldAsDollar',
  IS_RETAIN_PRODUCT_ENABLED = 'isRetainProductEnabled',
  IS_GROW_PRODUCT_ENABLED = 'isGrowProductEnabled',
  IS_OLD_NAVIGATION_ENABLED = 'isOldNavigationEnabled',
  IS_REFERRAL_LEADS_TAB_ENABLED = 'isReferralLeadsTabEnabled',
  IS_B2C_REFERRAL_PROGRAM_ENABLED = 'isB2CReferralProgramEnabled',
  IS_OWNER_LEADS_ZAPIER_INTEGRATION_ENABLED = 'isOwnerLeadsZapierIntegrationEnabled',
  IS_DASHBOARD_PROPERTY_PROFORMA_ENABLED = 'isDashboardPropertyProformaEnabled',
  IS_OBP_VENDOR_ENABLED = 'isObpVendorEnabled',
  IS_LEGACY_PM = 'isLegacyProductPM',
  IS_PM_PROPERTIES_DASHBOARD_ENABLED = 'isPMDashboardEnabled',
  IS_GROWTH_PM_PARTNERS_ENABLED = 'isGrowthPmPartnersEnabled',
  IS_FREEMIUM_VERSION_ENABLED = 'isFreemiumVersionProductEnabled',
  IS_CRANE_ALTERNATIVE_TEXT_ENABLED = 'isCraneAlternativeTextEnabled',
  IS_CRANE_COMMUNITY_NAV_ITEM_ENABLED = 'isCraneCommunityNavItemEnabled',
  IS_INVITE_PROSPECTS_ENABLED = 'isInviteProspectsEnabled',
  IS_USER_GUIDING_ENABLED = 'isUserGuidingEnabled',
  IS_ANNUAL_REPORT_ENABLED = 'isAnnualReportEnabled',
}

type FeatureFlagUser = {
  identifier: string;
  email: string;
  custom: {
    pm: string;
  };
};

// This function is a modified version of the useFeatureFlag hook from the ConfigCat React SDK
// https://github.com/configcat/react-sdk/blob/main/src/ConfigCatHooks.tsx
const useFeatureFlag = (
  key: string,
  defaultValue: any,
  userRequired: boolean,
  createCustomAttributes?: (pm: string) => Record<string, string>,
) => {
  const client = useConfigCatClient();
  const { user: authUser } = useAuth();

  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue);
  const [loading, setLoading] = useState(true);
  const ffUser = useMemo<FeatureFlagUser | undefined>(() => {
    if (!authUser) return undefined;

    return {
      identifier: authUser.id,
      email: authUser.email,
      custom: {
        pm: authUser.pm,
        ...(createCustomAttributes?.(authUser.pm) ?? {}),
      },
    };
  }, [authUser]);

  const onReceiveValue = useCallback((v: any) => {
    setFeatureFlagValue(v);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (userRequired && (!authUser || !ffUser)) return;
    client.getValue(key, defaultValue, onReceiveValue, ffUser);
  }, [authUser, client, key, defaultValue, ffUser, userRequired]);

  return { value: featureFlagValue, loading };
};

export const useIsB2CReferralProgramEnabledFF = () => useFeatureFlag(FeatureFlag.IS_B2C_REFERRAL_PROGRAM_ENABLED, false, true);

export const useListingCardGrossYieldAsDollar = () => useFeatureFlag(FeatureFlag.LISTING_CARD_GROSS_YIELD_AS_DOLLAR, false, true);

export const useIsRetainProductEnabledFF = () => useFeatureFlag(FeatureFlag.IS_RETAIN_PRODUCT_ENABLED, false, true);

export const useIsGrowProductEnabledFF = () => useFeatureFlag(FeatureFlag.IS_GROW_PRODUCT_ENABLED, false, true);

export const useIsOwnerLeadsZapierIntegrationEnabled = () => useFeatureFlag(
  FeatureFlag.IS_OWNER_LEADS_ZAPIER_INTEGRATION_ENABLED, false, true,
);

export const useIsDashboardPropertyProformaEnabled = () => useFeatureFlag(
  FeatureFlag.IS_DASHBOARD_PROPERTY_PROFORMA_ENABLED, false, true,
);

const createVendorCustomAttributes = (vendor: string, pm: string): Record<string, string> => ({
  pmToVendor: `${pm}::${vendor}`,
});

export const useIsObpVendorEnabled = (vendor: string) => (
  useFeatureFlag(FeatureFlag.IS_OBP_VENDOR_ENABLED, false, true, (pm) => createVendorCustomAttributes(vendor, pm))
);

export const useIsLegacyPM = () => useFeatureFlag(FeatureFlag.IS_LEGACY_PM, false, true);

export const useIsPmPropertiesDashboardEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_PM_PROPERTIES_DASHBOARD_ENABLED, false, true,
);

export const useIsGrowthPmPartnersEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_GROWTH_PM_PARTNERS_ENABLED, false, true,
);

export const useIsFreemiumVersionEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_FREEMIUM_VERSION_ENABLED, false, true,
);

export const useIsCraneAlternativeTextEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_CRANE_ALTERNATIVE_TEXT_ENABLED, false, true,
);

export const useIsCraneCommunityNavItemEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_CRANE_COMMUNITY_NAV_ITEM_ENABLED, false, true,
);

export const useIsInviteProspectsEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_INVITE_PROSPECTS_ENABLED, false, true,
);

export const useIsUserGuidingEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_USER_GUIDING_ENABLED, false, true,
);

export const useIsAnnualReportEnabledFF = () => useFeatureFlag(
  FeatureFlag.IS_ANNUAL_REPORT_ENABLED, false, true,
);
