import { useEffect, useState } from 'react';

import {
  AppDashboardProperty,
  AppUserActions,
  useAnalytics, useFeatures, useFetchMetrics, useGetPMPreferences,
  useListOwnerProperties,
} from 'lib';
import { MdTrendingUp } from 'react-icons/md';
import { Options as ReactToPdfOptions, Resolution, usePDF } from 'react-to-pdf';
import {
  Card, CardContent, Container, Divider, Typography,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AnnualReportHeaderBanner } from './AnnualReportHeaderBanner';
import { LeadershipSummary } from './LeadershipSummary';
import { LegalDisclaimer } from './LegalDisclaimer';
import { LegislationOverview } from './LegislationOverview';
import { MarketAnalysis } from './MarketAnalysis';
import { AnnualReportPdf } from './pdf';
import { PinkDotBanner } from './PinkDotBanner';
import { PortfolioPerformance, PropertyWithMetrics } from './PortfolioPerformance';
import { PortfolioProjections } from './PortfolioProjections';
import { annualReportAreaDataPropertyID } from './state';
import { LightButton } from '../../button/styled';
import { Pdf } from '../../icons';
import { useLabels } from '../../lib/translations';
import { FallbackSpinner, Spinner } from '../../spinner';
import { BlurredComponent } from '../BlurredComponent';

const getPropertyWithMostCommonFips = (properties: AppDashboardProperty[]) => {
  const fipsMap: Record<string, number> = {};

  properties.forEach((property) => {
    const { fips } = property.address;
    if (fipsMap[fips]) {
      fipsMap[fips] += 1;
    } else {
      fipsMap[fips] = 1;
    }
  });

  const fips = Object.keys(fipsMap).reduce((a, b) => (fipsMap[a] > fipsMap[b] ? a : b));

  return properties.find((p) => p.address.fips === fips);
};

const ExportPdfButton = ({ toPDF }: { toPDF: (options: ReactToPdfOptions) => void }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();
  const [exportingPdf, setExportingPdf] = useState(false);

  return (
    <LightButton
      disabled={exportingPdf}
      variant="outlined"
      size="small"
      startIcon={exportingPdf ? undefined : <Pdf />}
      sx={{
        border: `1px solid ${theme.palette.text.disabled} !important`,
        zIndex: 100,
      }}
      onClick={() => {
        setExportingPdf(true);

        const exportPdf = async () => {
          toPDF({ filename: 'page.pdf' });

          setTimeout(() => {
            setExportingPdf(false);
          }, 5000);
        };

        exportPdf().then(() => { }).catch(() => { });

        analytics.track('Button Clicked', {
          buttonName: 'Export Annual Report',
        });
      }}
    >
      {exportingPdf ? <Spinner size={20} /> : l['annualReport.export']}
    </LightButton>
  );
};

export const AnnualReport = ({
  owner,
  showHeader,
  userActions,
  inPreview = false,
}: {
  owner: string,
  showHeader: boolean,
  userActions: AppUserActions | undefined,
  inPreview?: boolean,
}) => {
  const l = useLabels();
  const { toPDF, targetRef } = usePDF({
    filename: 'Blanket Annual Report.pdf',
    resolution: Resolution.HIGH,
    page: {
      format: 'letter',
    },
  });

  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  const { data: ownerProperties, isLoading } = useListOwnerProperties(owner);
  const { data: pmPreferences, isLoading: pmPreferencesLoading } = useGetPMPreferences();
  const properties = ownerProperties?.properties ?? [];
  const { data: metricsResult } = useFetchMetrics({
    properties: properties.map(({ id }) => id),
    pointInTime: new Date('01/01/2025').getTime() / 1000,
  }, !!properties.length);

  let totalValue = 0;
  let totalEquity = 0;
  let totalAppreciation = 0;
  let totalCashflow = 0;
  const propertiesWithMetrics: PropertyWithMetrics[] = [];

  properties.forEach((property) => {
    if (!metricsResult) return;

    const responseMetrics = metricsResult.metrics[property.id];
    totalValue += responseMetrics?.valuation || 0;
    totalEquity += responseMetrics?.totalEquity || 0;
    totalAppreciation += responseMetrics?.appreciation || 0;
    totalCashflow += responseMetrics?.cashFlow || 0;

    const isMetricsAvailable = !!responseMetrics
        && property.transactions.filter((t) => t.label !== 'estimated'
            && new Date(t.transactionDate).getTime() > new Date('01/01/2024').getTime()
            && new Date(t.transactionDate).getTime() < new Date('01/01/2025').getTime()).length > 0;

    propertiesWithMetrics.push({
      ...property,
      isMetricsAvailable,
      metrics: responseMetrics.metrics,
      propertyId: property.id,
      fullAddress: property.address.fullAddress,
    });
  });

  useEffect(() => {
    if (!annualReportAreaDataPropertyID.value && properties?.length > 0) {
      annualReportAreaDataPropertyID.value = getPropertyWithMostCommonFips(properties)?.id || '';
    }
  }, [properties, annualReportAreaDataPropertyID.value]);

  if ((owner && isLoading) || pmPreferencesLoading || isLoadingFeatures) {
    return <FallbackSpinner />;
  }

  if (!(features.isAnnualReportPmEnabled || features.isAnnualReportEnabled)) {
    return null;
  }

  if (properties?.length === 0) {
    return (
      <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
        <Stack gap={3} p={3} alignItems="center">
          <MdTrendingUp size={36} />
          <Typography variant="h6">
            {l.propertiesNotFound}
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack height="100%" width="100%" gap={4} p={4}>

      <Container>
        <Stack gap={3} paddingBottom={5}>
          {showHeader && (
            <Stack direction="row" justifyContent="flex-end">
              <ExportPdfButton toPDF={toPDF} />
            </Stack>
          )}
          <AnnualReportHeaderBanner />
          <Card
            elevation={1}
            component={Stack}
            sx={{
              justifyContent: 'space-between',
              p: 0,
              gap: 4,
            }}
          >
            <CardContent>
              <Stack gap={4} divider={<Divider />}>
                <Stack gap={4}>
                  <Divider />
                  <MarketAnalysis properties={properties} />
                </Stack>
                <PinkDotBanner />
                <LegislationOverview properties={properties} />
                <BlurredComponent
                  title=""
                  subtitle={l['annualReport.noDataForThisSection']}
                  isBlurred={!(propertiesWithMetrics.filter((p) => p.isMetricsAvailable).length > 0)}
                >
                  <PortfolioPerformance
                    owner={owner}
                    properties={propertiesWithMetrics}
                    inPreview={inPreview}
                    totalValue={totalValue}
                    totalEquity={totalEquity}
                    totalAppreciation={totalAppreciation}
                    totalCashflow={totalCashflow}
                  />
                  <PortfolioProjections
                    userActions={userActions}
                    pmPreferences={pmPreferences || undefined}
                    ownerProperties={ownerProperties?.properties ?? []}
                  />
                </BlurredComponent>
              </Stack>
            </CardContent>
            <Stack>
              <LeadershipSummary />
              <LegalDisclaimer />
            </Stack>
          </Card>
        </Stack>
      </Container>
      {!inPreview && (
        <AnnualReportPdf
          targetRef={targetRef}
          owner={owner}
        />
      )}
    </Stack>
  );
};
