import dayjs from 'dayjs';
import { MdTrendingDown, MdTrendingUp } from 'react-icons/md';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AreaChart, getYAxisMaxAmount } from '../../charts';
import { formatDecimalToPercentage } from '../../lib/formatFuncs';
import { useLabels } from '../../lib/translations';
import { LightTooltip } from '../../tooltip/InfoTooltip';
import { BoldTypography } from '../../typography/BoldTypography';

const xLabel = Array.from({ length: 60 }, (_, i) => i + 1);

export const getTrend = (series: number[], title: string) => {
  const theme = useTheme();
  const l = useLabels();

  if (series.length < 12) {
    return null;
  }

  const { length } = series;
  const latestValue = series[length - 1] ?? 0;
  const lastYearValue = series[length - 13] ?? 0;

  if (latestValue === 0 || lastYearValue === 0) {
    return null;
  }

  const tooltipText = title.includes('Rent') ? l['annualReport.marketAnalysis.rentTooltip']
    : l['annualReport.marketAnalysis.valueTooltip'];

  const TooltipComponent = LightTooltip;

  const lastYearPercentage = ((latestValue - lastYearValue) / lastYearValue);
  if (lastYearPercentage > 0) {
    return (
      <TooltipComponent
        arrow={false}
        placement="top"
        title={tooltipText}
        PopperProps={{ sx: { zIndex: 2000 } }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <BoldTypography>
            YoY: &nbsp;
            {formatDecimalToPercentage(lastYearPercentage, 2)}
          </BoldTypography>
          <MdTrendingUp color={theme.palette.success.main} className="graph-trend-icon" />
        </Stack>
      </TooltipComponent>
    );
  }
  return (
    <TooltipComponent
      arrow={false}
      placement="top"
      title={tooltipText}
      PopperProps={{ sx: { zIndex: 2000 } }}
    >
      <Stack direction="row" alignItems="center" gap={1}>
        <BoldTypography>
          YoY: &nbsp;
          {formatDecimalToPercentage(lastYearPercentage, 2)}
        </BoldTypography>
        <MdTrendingDown color={theme.palette.error.main} className="graph-trend-icon" />
      </Stack>
    </TooltipComponent>
  );
};

export const MarketAnalysisGraph = ({
  color, series, title, scale = 100_000, getHalfMin = false, height = 211,
}: { color: string, series: number[], title: string, scale?: number, getHalfMin?: boolean, height?: number }) => {
  const theme = useTheme();
  let chosenColor: string;
  switch (color) {
    case 'purple':
      chosenColor = 'rgba(138, 91, 174, 1)';
      break;
    case 'green':
      chosenColor = 'rgba(16, 170, 134, 1)';
      break;
    case 'blue':
      chosenColor = 'rgba(38, 120, 245, 1)';
      break;
    default:
      chosenColor = 'rgba(138, 91, 174, 1)';
      break;
  }

  const data = [{
    name: title,
    color: chosenColor,
    data: series,
  }];

  const yAxisMax = getYAxisMaxAmount([...series], scale);
  const yAxisMin = Math.floor(yAxisMax / 2);
  const trend = getTrend(series, title);

  const startYear = 2024 - Math.floor(series.length / 12);

  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: '6px',
      }}
    >
      <Stack direction="row" justifyContent="space-between" mx={4} my={4}>
        <BoldTypography variant="body1">
          {title}
        </BoldTypography>
        {trend}
      </Stack>
      <AreaChart
        series={data}
        xLabels={xLabel}
        max={yAxisMax}
        min={getHalfMin ? yAxisMin : 0}
        height={height}
        width="100%"
        formatXLabel={(monthNumber, opts) => {
          if (opts?.inTooltip) {
            if (Number(monthNumber) === 60) return 'Dec, 2024';
            const year = Math.floor(Number(monthNumber) / 12) + startYear;
            return dayjs(`${year}-${((Number(monthNumber)) % 12) + 1}-01`).format('MMM, YYYY');
          }
          if (Number(monthNumber) % 12 === 6) {
            return (startYear + Math.floor(Number(monthNumber) / 12)).toString();
          }
          return '';
        }}
      />
    </Stack>
  );
};
