import { calculateGrossYield } from 'lib';

import { CalculatedRow } from './Row';
import { formatDecimalToPercentage } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { useGetPropertyIdParam } from '../state';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const GrossYield = ({ form }: RowProps) => {
  const l = useLabels();

  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form.watch(), propertyID);

  const getGrossYield = (i: number) => calculateGrossYield(
    projections.expectedRent[i] / 12,
    projections.askingPrices[i],
  );

  return (
    <CalculatedRow
      label={l.grossYield}
      values={projections.years.map((_, i) => formatDecimalToPercentage(getGrossYield(i) || 0, 2))}
      isInnerRow
    />
  );
};
