import {
  ListingItem, queryGraphQL, SearchListingItemsQuery, useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as Sentry from '@sentry/react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { QueryKey } from '../../types/enums';
import { searchActiveListingPropertyIDs } from '../graphql/queries';

class MissingListingItemPropertiesError extends Error {
  name = 'MissingListingItemPropertiesError';
}

export const useCountActiveListings = (enabled: boolean) => {
  const { getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    enabled,
    queryKey: [QueryKey.ACTIVE_LISTING_IDS],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      propertyIDs: Set<string>;
      nextToken: string | null | undefined;
    }> => {
      const token = await getAccessTokenSilently();

      let res;

      try {
        res = await queryGraphQL({
          query: searchActiveListingPropertyIDs,
          variables: { nextToken },
          authToken: token,
        }) as GraphQLResult<SearchListingItemsQuery>;
      } catch (e: any) {
        res = e; // in this case, the error type is GraphQLResult object and we want the process to continue.
      }

      if (!res.data?.searchMarketplacePropertyListingItems?.items?.length) {
        return { propertyIDs: new Set(), nextToken: null };
      }

      const propertyIDs = new Set<string>();

      const listingItemsWithMissingPropertyIDs = new Set<string>();

      res.data.searchMarketplacePropertyListingItems.items.forEach((item: ListingItem) => {
        if (!item?.marketplacePropertyListingItemsId) return;

        if (!item.property) {
          listingItemsWithMissingPropertyIDs.add(item.id);
          return;
        }

        propertyIDs.add(item.marketplacePropertyListingItemsId);
      });

      if (listingItemsWithMissingPropertyIDs.size > 0) {
        Sentry.withScope((scope) => {
          scope.setContext('errorData', {
            listingItemIDs: Array.from(listingItemsWithMissingPropertyIDs),
          });

          Sentry.captureException(new MissingListingItemPropertiesError('Missing properties in listing items'));
        });
      }

      return {
        propertyIDs,
        nextToken: res.data.searchMarketplacePropertyListingItems.nextToken,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
    onSuccess: (data) => {
      if (data.pages[data.pages.length - 1]?.nextToken) {
        query.fetchNextPage();
      }
    },
  });

  return query;
};
