import { useState } from 'react';

import {
  getActualNOI,
  getCapRate,
  getCashOnCash, useAnalytics,
  useMissingData,
} from 'lib';
import {
  formatDecimalToPercentage, formatNumberToCurrency, InfoTooltip,
  MissingData, MissingDataIcon, PercentGreen, PercentRed, SemiBoldTypography, useLabels,
} from 'ui';
import {
  Box,
  Grid, Stack, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';

import { MissingExpenses } from './MissingExpenses';
import { StyledGrid } from './styled';
import { TooltipContent } from './TooltipContent';
import { RequiresPropertiesProps } from './types';
import { Data, StatisticsBox } from '../../components/StatisticsBox';
import { UpdateProperties } from '../../components/update-properties';

export const NOISummary = ({ properties }: RequiresPropertiesProps) => {
  const theme = useTheme();
  const l = useLabels();
  const analytics = useAnalytics();
  const { missingData } = useMissingData(properties);
  const [missingDataPopupOpen, setMissingDataPopupOpen] = useState(false);

  const tooltipContent = [
    {
      bold: l['tooltip.noi.title'],
      label: l['tooltip.noi.desc'],
    },
    {
      bold: l['tooltip.caprate.title'],
      label: l['tooltip.caprate.desc'],
    },
    {
      bold: l['tooltip.cash.title'],
      label: l['tooltip.cash.desc'],
    },
  ];

  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));
  const metrics = properties.map((property) => property.metrics);

  const noi = getActualNOI(metrics);
  const capRate = getCapRate(metrics);
  const cashOnCash = getCashOnCash(metrics);

  const isDataPartial = properties.some((property) => property.metrics?.isDataPartial);
  const isCapRateMissing = capRate === 0 || Math.abs(capRate) === Infinity;
  const isCashOnCashMissing = cashOnCash === 0 || Math.abs(cashOnCash) === Infinity;
  const isNOIMissing = noi === 0;

  const dataCapRate: Data = {
    color: 'primary',
    stats: (
      isCapRateMissing
        ? <MissingDataIcon />
        : formatDecimalToPercentage(capRate, 2)
    ),
    icon: <PercentRed />,
    // eslint-disable-next-line no-nested-ternary
    title: isCapRateMissing ? l.capRate : isDataPartial ? l.calculatedCapRate : l.capRate,
  };
  const dataCashOnCash: Data = {
    color: 'success',
    stats: isCashOnCashMissing ? <MissingDataIcon /> : formatDecimalToPercentage(cashOnCash, 2),
    icon: <PercentGreen />,
    // eslint-disable-next-line no-nested-ternary
    title: isCashOnCashMissing ? l.cashOnCash : isDataPartial ? l.calculatedCashOnCash : l.cashOnCash,
  };
  return (
    <Card
      component={Stack}
      sx={{
        height: '100%', justifyContent: 'space-between',
      }}
    >
      <Box>
        <CardHeader
          sx={{ p: 4, pb: 2 }}
          title={l.netOperatingIncome}
          titleTypographyProps={{ variant: 'h6' }}
          action={(
            <InfoTooltip
              arrow
              isLight
              title={<TooltipContent bullets={tooltipContent} />}
              isOutlined
              track={(value) => {
                analytics.track('Tooltip Toggled', {
                  value,
                  tooltipName: 'Net Operating Income Tooltip',
                });
              }}
            />
          )}
        />
        <CardContent>
          <Stack spacing={3}>
            <Stack
              spacing={1}
              sx={{
                borderBottom: `1px solid ${theme.palette.divider}`,
                paddingBottom: 3,
              }}
            >
              <SemiBoldTypography
                variant="h5"
                color={isNOIMissing ? 'secondary' : undefined}
                sx={{ mr: 1.5 }}
                data-testid="net-operating-income"
              >
                {formatNumberToCurrency(noi, 0)}
                {isNOIMissing && <MissingDataIcon boxProps={{ sx: { ml: 2 } }} />}
              </SemiBoldTypography>
              <Typography variant="body2">
                {l['last-12-months']}
              </Typography>
            </Stack>
            <Grid
              container
              sx={{
                flexDirection: isDesktop ? 'row' : 'column',
                gap: isDesktop ? 0 : 2,
              }}
            >
              <Grid
                item
                xs={6}
                pr={isDesktop ? 3 : 0}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'row',
                  borderRight: isDesktop ? `1px solid ${theme.palette.divider}` : 'none',
                  width: isDesktop ? '50%' : '100% !important',
                  maxWidth: isDesktop ? '50%' : '100% !important',
                }}
              >
                <StatisticsBox data={dataCapRate} />
              </Grid>
              <StyledGrid
                item
                xs={6}
                px={isDesktop ? 3 : 0}
              >
                <StatisticsBox data={dataCashOnCash} />
              </StyledGrid>
            </Grid>
          </Stack>
        </CardContent>
      </Box>
      {/* Data in this widget are based on cashOnCash or capRate missing */}
      {/* Since both are also based on expenses, they can't be part of the following condition */}
      {/* cashInvested is also part of the logic and unrelated to expenses so we have to use it here */}
      {missingData.cashInvested.isMissing && (
        <>
          <MissingData onAdd={() => {
            analytics.track('Button Clicked', {
              buttonName: 'Missing Data CTA',
              widgetName: 'NOI Summary',
            });

            setMissingDataPopupOpen(true);
          }}
          />
          <UpdateProperties
            open={missingDataPopupOpen}
            onClose={() => setMissingDataPopupOpen(false)}
            properties={properties}
            propertyID={missingData.cashInvested.propertyIDs[0]}
            showPurchase
            showMortgage
          />
        </>
      )}
      <MissingExpenses
        properties={properties}
        containerSx={{ mt: 0 }}
        onAdd={() => {
          analytics.track('Button Clicked', {
            buttonName: 'Missing Expenses CTA',
            widgetName: 'NOI Summary',
          });
        }}
      />
    </Card>
  );
};
