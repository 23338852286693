import { sum, useAnalytics } from 'lib';
import {
  BlurredComponent, BoldTypography, DonutChart, formatNumberToCurrency, InfoTooltip, useLabels,
} from 'ui';
import {
  Card, CardContent, Stack, Typography, useTheme,
} from '@mui/material';

import { DonutChartLegends } from './DonutChartLegends';
import { PMMetrics } from '../../../api/pm-metrics/types';

export const RevenueOpportunities = ({ metrics }: { metrics: PMMetrics }) => {
  const l = useLabels();
  const theme = useTheme();
  const analytics = useAnalytics();

  const configs = [
    {
      value: metrics.leadMetrics?.totalSellerLeadsValue ?? 0,
      count: metrics.leadMetrics?.totalSellerLeads ?? 0,
      label: l['blanketOverview.revenueOpportunities.sellers'],
      color: theme.palette.warning.main,
    },
    {
      value: metrics.leadMetrics?.totalBuyerLeadsValue ?? 0,
      count: metrics.leadMetrics?.totalBuyerLeads ?? 0,
      label: l['blanketOverview.revenueOpportunities.buyers'],
      color: theme.palette.error.main,
    },
    {
      value: metrics.leadMetrics?.totalBuyBoxLeadsValue ?? 0,
      count: metrics.leadMetrics?.totalBuyBoxLeads ?? 0,
      label: l['blanketOverview.revenueOpportunities.buybox'],
      color: theme.palette.info.main,
    },
    {
      value: metrics.leadMetrics?.totalReferralsValue ?? 0,
      count: metrics.leadMetrics?.totalReferrals ?? 0,
      label: l['blanketOverview.revenueOpportunities.referrals'],
      color: theme.palette.success.main,
    },
    {
      value: metrics.leadMetrics?.totalProspectsValue ?? 0,
      count: metrics.leadMetrics?.totalProspects ?? 0,
      label: l['blanketOverview.revenueOpportunities.prospects'],
      color: theme.palette.secondary.main,
    },
  ];

  return (
    <BlurredComponent
      isBlurred={sum(configs.map((c) => c.value)) === 0 || sum(configs.map((c) => c.count)) === 0}
      title={l['blanketOverview.chart.noData.title']}
      subtitle={l['blanketOverview.chart.noData.subtitle']}
    >
      <Card sx={{ height: '100%' }}>
        <CardContent>
          <Stack gap={3}>
            <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
              <BoldTypography variant="h6">
                {l['blanketOverview.revenueOpportunities']}
              </BoldTypography>
              <InfoTooltip
                title={(
                  <Stack p={1}>
                    <Typography variant="body2">
                      {l['blanketOverview.revenueOpportunities.tooltip.title']}
                    </Typography>
                    <ul style={{ padding: 0, paddingLeft: theme.spacing(4) }}>
                      <li><Typography variant="body3">{l['blanketOverview.revenueOpportunities.tooltip.item1']}</Typography></li>
                      <li><Typography variant="body3">{l['blanketOverview.revenueOpportunities.tooltip.item2']}</Typography></li>
                      <li><Typography variant="body3">{l['blanketOverview.revenueOpportunities.tooltip.item3']}</Typography></li>
                    </ul>
                  </Stack>
                )}
                arrow
                isLight
                isOutlined
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Revenue Opportunities Tooltip',
                  });
                }}
              />
            </Stack>
            <DonutChart
              tooltipAsDollars
              series={configs.map((c) => c.value)}
              labels={configs.map((c) => c.label)}
              colors={configs.map((c) => c.color)}
              scale={1}
              formatter={() => formatNumberToCurrency(sum(configs.map((c) => c.value)), 2, {
                notation: 'compact',
                compactDisplay: 'short',
              })}
            >
              <Stack flexGrow={1}>
                <DonutChartLegends legends={configs} />
              </Stack>
            </DonutChart>
          </Stack>
        </CardContent>
      </Card>
    </BlurredComponent>
  );
};
