import { Method } from 'axios';
import { useMutation } from '@tanstack/react-query';

import { useAuth } from './useAuth';
import { axiosWithPayloadContext, createHeaders } from '../api/axios';
import { config } from '../config/config';

export const createMutationHook = <Payload>(url: string, method: Method) => () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (payload: Payload) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}${url}`,
      method,
      headers: createHeaders(token),
      data: payload,
    });
  });
};
