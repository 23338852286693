import {
  AppDashboardProperty,
  AppUserActions, PMPreferences, sum,
} from 'lib';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';

import { AreaChart, BarChart, getYAxisMaxAmount } from '../../../charts';
import { TVIcon } from '../../../icons';
import { useLabels } from '../../../lib/translations';
import { BoldTypography } from '../../../typography/BoldTypography';
import { createInitialFormData } from '../../property-proforma/form';
import { useGetPropertyIdParam } from '../../property-proforma/state';
import { useProjections } from '../../property-proforma/useProjections';
import { useGraphYearLabel } from '../Graphs';

const graphYears = Array.from({ length: 25 }, (_, i) => i + 1);

export const PortfolioProjectionsPdf = ({
  userActions,
  ownerProperties,
  pmPreferences = undefined,
}: {
  userActions: AppUserActions | undefined,
  ownerProperties: AppDashboardProperty[],
  pmPreferences?: PMPreferences,
}) => {
  const l = useLabels();
  const theme = useTheme();

  const { getYearLabel, formatXLabel } = useGraphYearLabel();

  const propertyID = useGetPropertyIdParam();

  const data = createInitialFormData(ownerProperties ?? [], pmPreferences, userActions);
  const projections = useProjections(data, propertyID, graphYears);

  const loanBalance = {
    name: l.loanBalance,
    color: theme.palette.error.main,
    data: projections.years.map((_, i) => projections.loanBalance[i]),
  };
  const equity = {
    name: l.equity,
    color: theme.palette.success.main,
    data: projections.years.map((_, i) => projections.equity[i]),
  };
  const propertyValue = {
    name: l.propertyValue,
    color: theme.palette.info.main,
    data: projections.years.map((_, i) => projections.askingPrices[i]),
  };
  const areaSeries = [loanBalance, equity, propertyValue];

  const netCashFlow = {
    name: l.cumNetCashFlow,
    color: theme.palette.warning.main,
    data: projections.years.map((_, i) => projections.calculations.getCumulativeNetCashflow(i)),
  };
  const appreciation = {
    name: l.cumAppreciation,
    color: theme.palette.info.main,
    data: projections.years.map((_, i) => projections.calculations.getCumulativeAppreciation(i)),
  };
  const investmentValue = {
    name: l.estInvestmentValue,
    color: theme.palette.primary.dark,
    data: projections.years.map((_, i) => sum([
      projections.calculations.getCumulativeNetCashflow(i),
      projections.calculations.getCumulativeAppreciation(i),
      projections.equity[i],
    ])),
    type: 'line',
  };
  const series = [netCashFlow, appreciation, equity, investmentValue];

  const yAxisMaxGrowthOverTime = getYAxisMaxAmount(projections.years.map((_, i) => sum([
    Math.abs(netCashFlow.data[i]),
    Math.abs(appreciation.data[i]),
    Math.abs(equity.data[i]),
  ])));

  const yAxisMaxEquity = getYAxisMaxAmount([...loanBalance.data, ...equity.data, ...propertyValue.data]);

  return (
    <Stack pt="1rem">
      <Stack direction="row" alignItems="center" gap={2} pb={4}>
        <TVIcon />
        <BoldTypography variant="body1">{l['annualReport.portfolioProjections.title']}</BoldTypography>
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Stack sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '6px',
          }}
          >
            <BoldTypography variant="body2" p="1rem" pb={0}>
              {l['annualReport.portfolioProjections.estInvestmentOverTime']}
            </BoldTypography>
            <BarChart
              series={series}
              labels={projections.years.map(getYearLabel)}
              height={200}
              max={yAxisMaxGrowthOverTime}
              formatXLabel={formatXLabel}
              stroke={{
                width: [0, 0, 0, 2],
                curve: 'smooth',
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Stack sx={{
            border: `1px solid ${theme.palette.divider}`,
            borderRadius: '6px',
            pb: 2,
          }}
          >
            <BoldTypography variant="body2" p="1rem" pb={0}>
              {l['annualReport.portfolioProjections.equityBuildUp']}
            </BoldTypography>
            <AreaChart
              series={areaSeries}
              xLabels={projections.years}
              height={200}
              max={yAxisMaxEquity}
              formatXLabel={formatXLabel}
            />
          </Stack>
        </Grid>
      </Grid>
    </Stack>

  );
};
