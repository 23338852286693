import { useAppSettings, useAuth } from 'lib';
import {
  Divider, Stack, Typography, useTheme,
} from '@mui/material';

import { portfolioDataImageURL, recommendationBackgroundURL } from '../../../assets';
import { LazyImage } from '../../../image';
import { BoldTypography } from '../../../typography/BoldTypography';
import { DataImage } from '../DataImage';

export const AnnualReportHeaderBannerPdf = () => {
  const theme = useTheme();
  const { data: appSettings } = useAppSettings();
  const { user } = useAuth();

  return (

    <Stack height="100%">
      <Stack
        height="50%"
        sx={{ backgroundColor: '#EBEDF5' }}
        pt={10}
      >
        <DataImage
          src={appSettings?.logo ?? ''}
          style={{ height: '15.91511936%', width: 'fit-content', marginLeft: theme.spacing(10) }}
          alt="logo"
        />
        <LazyImage
          img={portfolioDataImageURL}
          style={{ flexGrow: 1, scale: 0.8 }}
        />
      </Stack>
      <Stack sx={{
        backgroundImage: `url(${recommendationBackgroundURL})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '50%',
        px: 10,
        py: 10,
        justifyContent: 'space-evenly',
      }}
      >
        <Stack>
          <BoldTypography variant="h3">Annual Investor</BoldTypography>
          <BoldTypography variant="h3">Report</BoldTypography>
        </Stack>
        <Divider sx={{ borderColor: '#EBEDF5', borderWidth: 0.5, opacity: 0.5 }} />
        <Typography variant="h1">2024</Typography>
        {user?.givenName && <Typography variant="h5">{`${user.givenName} ${user.familyName ?? ''}`}</Typography>}
      </Stack>
    </Stack>
  );
};
