import { Label, Transaction as APITransaction } from '../api/API';

export enum TransactionType {
  EXPENSE = 'expense',
  INCOME = 'income',
}

export enum Category { // HACK! TODO : How to exclude one type and merge with the others?
  OTHER_EXPENSES = 'other_expenses',
  OTHER_INCOME = 'other_income',
  RENT = 'rent',
  MANAGEMENT_FEE = 'management_fee',
  MORTGAGE = 'mortgage',
  PROPERTY_TAX = 'property_tax',
  PROPERTY_INSURANCE = 'property_insurance',
  PROPERTY_HOA = 'property_hoa',
  UTILITIES = 'utilities',
  LEGAL_AND_PROFESSIONAL_FEES = 'legal_and_professional_fees',
  LEASING_AND_MARKETING = 'leasing_and_marketing',
  RENTAL_REGISTRATION = 'rental_registration',
  AUTO_AND_TRAVEL = 'auto_and_travel',
  SUPPLIES = 'supplies',
  MAINTENANCE = 'maintenance',
  CAPITAL_EXPENDITURE = 'capital_expenditure',
}

export type Transaction = Pick<APITransaction, 'amount' | 'id' | 'transactionDate' | 'label' | 'description' | 'isEditable'> & {
  type: TransactionType;
  category: Category;
};

export type GroupedTransaction = {
  id: string,
  amount: number,
  transactionMonth: string,
  category: Category,
  label?: Label | null,
  transactions: Transaction[]
  dashboardPropertyTransactionsId?: string | null,
  description: string,
  isEditable: boolean,
};
