import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  AppDashboardProperty,
  Category,
  convertExpenseCategoryToCategory,
  getCategoryDisplayName,
  hasMissingCategoriesLastYear,
  ITransactionForm,
  Label, TFilterDate, transactionCategoryToDisplay, useAnalytics, useAuth, useListOwnerProperties,
} from 'lib';
import { TbReportMoney } from 'react-icons/tb';
import {
  AddTransactionsModal,
  Avatar,
  BoldTypography,
  Chip,
  EmptyFullPageContainer,
  FallbackSpinner,
  InfoTooltip,
  OnClickAddTransaction,
  TransactionAlerts,
  useLabels,
  useLayoutStyles,
  useToggle,
} from 'ui';
import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ConfirmTransactionsModal } from './ConfirmTransaction';
import { FilterModal } from './FilterModal';
import { SumLabel } from './SumLabel';
import { TransactionCard } from './TransactionCard';
import { Order, TableItem } from './types';
import { getComparator } from './utils';

const Categories = Array.from(transactionCategoryToDisplay.values());

const createTableData = (properties: AppDashboardProperty[]) => {
  const l = useLabels();

  const cardLabels = {
    [Category.MORTGAGE]: <Chip label={l.calculated} color="info" skin="light" size="small" />,
    [Label.estimated]: <Chip label={l.estimated} color="info" skin="light" size="small" />,
    [Label.pm]: <Chip label={l['label.pm']} color="secondary" skin="light" size="small" />,
    [Label.manual]: <Chip label={l['label.manual']} color="success" skin="light" size="small" />,
    [Label.manual_recurring]: <Chip label={l['label.manual']} color="success" skin="light" size="small" />,
  };
  const transactions: TableItem[] = [];

  properties.forEach((property) => {
    property.groupTransactions.forEach((transaction) => {
      transactions.push({
        id: transaction.id,
        propertyName: property.displayName,
        propertyId: property.id,
        amount: transaction.amount,
        date: transaction.transactionMonth,
        originalCategory: transaction.description,
        category: getCategoryDisplayName(transaction.category),
        label: transaction.category === Category.MORTGAGE
          ? cardLabels[Category.MORTGAGE]
          : transaction.label && cardLabels[transaction.label],
        rawLabel: transaction.label,
        fullAddress: property.address.fullAddress,
        transactions: transaction.transactions,
        isEditable: transaction.isEditable,
      });
    });
  });

  return transactions;
};

const TransactionTooltip = () => {
  const l = useLabels();
  return (
    <Stack p={3} gap={3}>
      <Stack direction="row" gap={3} alignItems="baseline" justifyContent="space-between">
        <span>
          <Chip label={l.mortgage} color="info" skin="light" size="small" />
        </span>
        <Typography variant="body2" sx={{ width: '220px' }}>
          {l['tooltip.estimated']}
        </Typography>
      </Stack>
      <Stack direction="row" gap={3} alignItems="baseline" justifyContent="space-between">
        <span>
          <Chip label={l['label.pm']} color="secondary" skin="light" size="small" />
        </span>
        <Typography variant="body2" sx={{ width: '220px' }}>
          {l['tooltip.pm']}
        </Typography>
      </Stack>
      <Stack direction="row" gap={3} alignItems="baseline" justifyContent="space-between">
        <span>
          <Chip label={l['label.manual']} color="success" skin="light" size="small" />
        </span>
        <Typography variant="body2" sx={{ width: '220px' }}>
          {l['tooltip.manual']}
        </Typography>
      </Stack>
    </Stack>
  );
};

export const Transactions = () => {
  const { user } = useAuth();

  const {
    data: ownerProperties,
    isLoading,
    isError,
  } = useListOwnerProperties(user?.id ?? '');
  const properties = ownerProperties?.properties ?? [];

  const { setPadding: setPaddingDisabled } = useLayoutStyles();
  const l = useLabels();
  const location = useLocation();
  const missingDataInitialExpanded = !!location.state?.missingExpenses;
  const analytics = useAnalytics();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [selectedProperties, setSelectedProperties] = useState<string[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
  const [fromDate, setFromDate] = useState<TFilterDate>(null);
  const [toDate, setToDate] = useState<TFilterDate>(null);
  const theme = useTheme();
  const tableRows = useMemo(() => createTableData(properties ?? []), [properties]);
  const [isAddTransactionsModalOpen, toggleAddTransactionsModal] = useToggle();
  const [addTransactionsModalInitialValues, setAddTransactionsModalInitialValues] = useState<ITransactionForm | null>(null);
  const [
    confirmTransactionsModalInitialValues,
    setConfirmTransactionsModalInitialValues,
  ] = useState<ITransactionForm | null>(null);
  const onClickAddTransaction: OnClickAddTransaction = (propertyID, category) => {
    if (propertyID !== undefined && category !== undefined) {
      setAddTransactionsModalInitialValues({
        category: convertExpenseCategoryToCategory(category),
        type: 'one_time',
        amount: undefined,
        propertyId: propertyID,
        dates: {
          date: dayjs().format('MM/DD/YYYY'),
        },
      });
    } else {
      setAddTransactionsModalInitialValues(null);
    }
    toggleAddTransactionsModal();
  };

  useEffect(() => {
    setPaddingDisabled(true);

    return () => {
      setPaddingDisabled(false);
    };
  }, []);

  const getSelectedCategoriesDisplayNames = (
    categories: string,
    transactionCategoryToDisplayMapping: Map<Category, string>,
  ): string[] => (
    categories
      .split(',')
      .map((category) => transactionCategoryToDisplayMapping.get(category as Category))
      .filter((displayName): displayName is string => displayName !== undefined)
  );

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchFromDate = searchParams.get('fromDate');
    const searchToDate = searchParams.get('toDate');
    const searchCategory = searchParams.get('category');

    if (searchFromDate) {
      setFromDate(dayjs(searchFromDate).toDate());
    }
    if (searchToDate) {
      setToDate(dayjs(searchToDate).toDate());
    }
    if (searchCategory) {
      setSelectedCategories(getSelectedCategoriesDisplayNames(searchCategory, transactionCategoryToDisplay));
    }
  }, [location.search]);

  const handleApply = ({
    newProperties, newCategories, startDate, endDate,
  }: { newProperties: string[], newCategories: string[], startDate: TFilterDate, endDate: TFilterDate }) => {
    setSelectedCategories(newCategories);
    setSelectedProperties(newProperties);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const filteredRows = tableRows.filter(
    (row) => (selectedProperties.includes(row.propertyName)
      || selectedProperties.length === 0) && (selectedCategories.includes(row.category) || selectedCategories.length === 0)
      && (row.date >= dayjs(fromDate).format('YYYY-MM') || !fromDate)
      && (row.date <= dayjs(toDate).format('YYYY-MM') || !toDate),
  ).sort(getComparator(Order.DESC, 'date'));

  const sum = filteredRows.reduce((acc, obj) => acc + obj.amount, 0);
  const [firstDate, lastDate] = useMemo(() => [filteredRows[filteredRows.length - 1]?.date, filteredRows[0]?.date], [tableRows]);
  const [hadMissingTransactions, setHadMissingTransactions] = useState<boolean>(false);

  const isMissingTransactions = useMemo<boolean>(() => {
    if (properties && properties.length > 0) {
      return hasMissingCategoriesLastYear(properties, [Category.PROPERTY_HOA]);
    }
    return false;
  }, [properties]);

  useEffect(() => {
    if (isMissingTransactions) {
      setHadMissingTransactions(true);
    }
    if (hadMissingTransactions && !isMissingTransactions) {
      analytics.track('Milestone Reached', {
        milestone: 'Completed Missing Transactions',
      });
    }
  }, [isMissingTransactions]);

  const isDesktop = useMediaQuery((_theme: Theme) => _theme.breakpoints.up('sm'));
  const isViewportTooNarrow = useMediaQuery((_theme: Theme) => _theme.breakpoints.down(370));

  const [isConfirmTransactionsModalOpen, toggleConfirmTransactionsModalOpen] = useToggle();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchTransactionId = searchParams.get('transactionId');
    const action = searchParams.get('action');

    if (action === 'confirm' && searchTransactionId) {
      let propertyId: string | undefined;
      let category: Category | undefined;
      let amount: number | undefined;

      properties.forEach((property) => {
        property.transactions.forEach((transaction) => {
          if (transaction.id === searchTransactionId) {
            propertyId = property.id;
            category = transaction.category;
            amount = transaction.amount;
          }
        });
      });

      if (propertyId && category) {
        setConfirmTransactionsModalInitialValues({
          category: convertExpenseCategoryToCategory(category),
          type: 'one_time',
          amount,
          propertyId,
          dates: {
            date: dayjs()
              .format('MM/DD/YYYY'),
          },
        });
        toggleConfirmTransactionsModalOpen();
      }
    }
  }, [location.search, properties]);

  if (isLoading || !properties || isError) return <FallbackSpinner />;

  if (properties.length === 0) {
    return (
      <EmptyFullPageContainer>
        <Avatar skin="light" variant="circular" color="info" sx={{ mb: 3, height: 72, width: 72 }}>
          <TbReportMoney color={theme.palette.primary.main} size={40} />
        </Avatar>
        <BoldTypography>{l['transactions.no-results']}</BoldTypography>
      </EmptyFullPageContainer>
    );
  }

  return (
    <>
      <Stack
        direction="column"
        mx={0}
        sx={{
          background: theme.palette.background.paper,
          zIndex: 'appBar',
          position: 'sticky',
          top: 0,
          boxShadow: 4,
        }}
      >
        <Stack
          alignItems="center"
          justifyContent="space-between"
          direction="row"
          p={3}
          sx={{
            borderBottom: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Stack gap={2} direction="row" justifyContent="space-between" sx={{ alignItems: 'center' }}>
            <Typography variant={isViewportTooNarrow ? 'h6' : 'h5'} sx={{ fontWeight: 'bold' }}>
              {l['transactions.title']}
            </Typography>
            <Stack>
              <InfoTooltip
                title={<TransactionTooltip />}
                isOutlined
                arrow
                isLight
                track={(value) => {
                  analytics.track('Tooltip Toggled', {
                    value,
                    tooltipName: 'Transactions Tooltip',
                  });
                }}
              />
            </Stack>
            {!isMobile && (
              <Stack ml={3} gap={2} direction="row">
                <FilterModal
                  properties={properties.map((p) => p.displayName)}
                  categories={Categories}
                  firstDate={fromDate?.toString() ?? firstDate}
                  lastDate={toDate?.toString() ?? lastDate}
                  onApply={handleApply}
                  selectedCategories={selectedCategories}
                />
                <Button
                  variant="contained"
                  onClick={() => onClickAddTransaction()}
                >
                  {l.add}
                </Button>
              </Stack>
            )}
          </Stack>
          {!isMobile && tableRows.length !== filteredRows.length && (
            <Stack gap={4} p={0} direction="row" justifyContent="space-between">
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{l.total}</Typography>
              <SumLabel value={sum} />
            </Stack>
          )}
          {isMobile && (
            <Stack direction="row" gap={2}>
              <FilterModal
                properties={properties.map((p) => p.displayName)}
                categories={Categories}
                firstDate={firstDate}
                lastDate={lastDate}
                onApply={handleApply}
                selectedCategories={selectedCategories}
              />
              <Button
                variant="contained"
                onClick={() => onClickAddTransaction()}
              >
                {' '}
                {l.add}
              </Button>
            </Stack>
          )}
        </Stack>
        {isMobile && tableRows.length !== filteredRows.length && (
          <Stack gap={2} p={3} direction="row" justifyContent="space-between">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{l.total}</Typography>
            <SumLabel value={sum} />
          </Stack>
        )}
      </Stack>
      <Stack
        py={4}
        px={isDesktop ? 6 : 2}
        gap={2}
      >
        {isMissingTransactions && (
          <TransactionAlerts
            properties={properties}
            onClick={(propertyID, category) => onClickAddTransaction(propertyID, category)}
            initialExpanded={missingDataInitialExpanded}
          />
        )}
        <Stack gap={2}>
          {filteredRows.length === 0 && (
            <Stack direction="column" alignItems="center" justifyContent="space-between" mt={10}>
              <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
                {l['transactions.no-results']}
                <br />
                {l['transactions.no-results.try-again']}
              </Typography>
            </Stack>
          )}
          {filteredRows.filter((row) => row.amount !== 0)
            .map((row) => <TransactionCard state={row} key={row.id} />)}
        </Stack>
      </Stack>
      <AddTransactionsModal
        isOpen={isAddTransactionsModalOpen}
        toggle={toggleAddTransactionsModal}
        initialValues={addTransactionsModalInitialValues}
      />
      <ConfirmTransactionsModal
        isOpen={isConfirmTransactionsModalOpen}
        toggle={toggleConfirmTransactionsModalOpen}
        initialValues={confirmTransactionsModalInitialValues}
      />
    </>
  );
};
