import { sum } from './math';
import { AppDashboardProperty } from '../../api/dashboard-properties/types/property';

type CalculateMortgageFields = {
  loanAmount: number;
  interestRate: number;
  loanTerm: number;
};
/**
 * [Logic for Mortgage Calculation](https://www.mymove.com/mortgage/mortgage-calculation)
 */
export const calculateMortgage = ({
  loanAmount,
  interestRate,
  loanTerm,
}: CalculateMortgageFields): number => {
  if (![loanAmount, interestRate, loanTerm].every(Boolean)) {
    return 0;
  }

  const monthlyInterestRate = (interestRate || 0) / 100 / 12;

  return loanAmount * (
    (monthlyInterestRate * (1 + monthlyInterestRate) ** loanTerm) / ((1 + monthlyInterestRate) ** loanTerm - 1)
  );
};

export const calculateCashInvested = (purchasePrice: number | undefined, mortgageAmount: number | undefined): number => {
  if (!purchasePrice) {
    return 0;
  }

  return purchasePrice - (mortgageAmount ?? 0);
};

type MortgageField = 'mortgage';
export const calculateTotalMortgageAmount = (properties: Pick<AppDashboardProperty, MortgageField>[]) => sum(
  properties.map(({ mortgage }) => mortgage?.amount ?? 0),
);
type AmortizationTableField = 'amortizationTable';
type Mortgage = 'mortgage';

export const calculateTotalMortgagePaydown = (properties: Pick<AppDashboardProperty, AmortizationTableField | Mortgage>[]) => sum(
  properties.map(({ amortizationTable, mortgage }) => ((mortgage?.amount ?? 0) - (amortizationTable?.totalPrincipal ?? 0))),
);

export const calculateTotalPrincipal = (properties: Pick<AppDashboardProperty, AmortizationTableField>[]) => sum(
  properties.map(({ amortizationTable }) => (amortizationTable?.totalPrincipal ?? 0)),
);
