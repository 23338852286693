import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  ListingItemTag, useAnalytics, useAuth, useFeatures, useListingCardGrossYieldAsDollar,
} from 'lib';
import { GiHouse } from 'react-icons/gi';
import {
  BoldTypography,
  Chip,
  formatDecimalToPercentage, formatNumber, formatNumberToCurrency, useLabels,
} from 'ui';
import {
  CardContent, CardMedia,
  Skeleton,
  Stack, Typography,
  useMediaQuery, useTheme,
} from '@mui/material';

import { FavoriteButton } from './FavoriteButton';
import { ShareButton } from './ShareButton';
import { ListingCard as StyledListingCard } from './styled';
import { ListingProperty } from '../../types/property';

export type ListingCardProps = {
  listingItem: ListingProperty,
  listingIndex: number,
  isRecommendedListing?: boolean,
};

const Tag = ({ label }: { label: string }) => {
  const theme = useTheme();

  return (
    <Chip
      label={label}
      sx={{
        '&.MuiChip-root': {
          background: theme.palette.primary.dark,
        },
      }}
    />
  );
};

export const ListingTag = ({ tags }: { tags: ListingItemTag[] }) => {
  const l = useLabels();

  const tag = useMemo(() => {
    if (tags.includes(ListingItemTag.handpicked_by_blanket)) {
      return l.handpicked;
    }

    if (tags.includes(ListingItemTag.good_crime_rate)) {
      return l.lowCrime;
    }

    if (tags.includes(ListingItemTag.one_percent_rule)) {
      return l.onePercentRule;
    }

    return null;
  }, [tags]);

  return tag && <Tag label={tag} />;
};

const CardHeader = ({
  listingItem,
  tags,
  setListingItem,
}: {
  listingItem: ListingProperty,
  tags: ListingItemTag[],
  setListingItem: (li: ListingProperty) => void,
}) => {
  const theme = useTheme();
  const mediaHeight = 200;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap={2}
        justifyContent="space-between"
        zIndex={1}
        position="absolute"
        top={0}
        right={0}
        width="100%"
        p={3}
      >
        <Stack>
          <ListingTag tags={tags} />
        </Stack>
        <Stack direction="row" gap={3}>
          <ShareButton listingID={listingItem.id} />
          <FavoriteButton
            onChangeFavorite={(id: string | null) => {
              setListingItem({
                ...listingItem,
                favoriteId: id,
              });
            }}
            favoriteId={listingItem.favoriteId}
            marketplacePropertyId={listingItem.id}
          />
        </Stack>
      </Stack>
      {listingItem.primaryPicture ? (
        <CardMedia
          component="img"
          height={mediaHeight}
          width="370"
          image={listingItem.primaryPicture}
        />
      ) : (
        <Stack alignItems="center">
          <GiHouse size={mediaHeight} color={theme.palette.primary.main} />
        </Stack>
      )}
    </>
  );
};

export const ListingCard = ({ listingItem: li, listingIndex, isRecommendedListing = false }: ListingCardProps) => {
  const theme = useTheme();
  const l = useLabels();
  const { user } = useAuth();
  const analytics = useAnalytics();
  const features = useFeatures();
  const { value: showGrossYieldAsDollar, loading: loadingGrossYieldAsDollarFF } = useListingCardGrossYieldAsDollar();

  const [listingItem, setListingItem] = useState(li);

  const aboveMd = useMediaQuery(theme.breakpoints.up('md'));

  const displayPropertyMeasures = listingItem.measures.grossYield !== 0 && listingItem.measures.capRate !== 0;

  const grossYield = showGrossYieldAsDollar ? (
    formatNumberToCurrency(listingItem.measures.grossYield * li.askingPrice, 1, {
      style: 'currency',
      notation: 'compact',
      compactDisplay: 'short',
    })
  ) : formatDecimalToPercentage(listingItem.measures.grossYield, 2);

  useEffect(() => {
    analytics.identify(user!.realID, {
      showGrossYieldAsDollar,
    }, features);
  }, [showGrossYieldAsDollar, features]);

  return (
    <Link
      to={`/marketplace/${listingItem.id}`}
      style={{
        textDecoration: 'none',
        color: 'inherit',
        display: 'block',
        pointerEvents: 'auto',
        height: '100%',
      }}
    >
      <StyledListingCard
        data-testid={listingItem.id}
        onClick={() => {
          analytics.track('Card Clicked', {
            cardName: isRecommendedListing ? 'Recommended Listing' : 'Listing',
            position: listingIndex,
            propertyAddress: listingItem.address.fullAddress,
            propertyId: listingItem.propertyID,
            listingId: listingItem.id,
          });
        }}
        sx={{ height: aboveMd ? '100%' : 'auto' }}
      >
        <CardHeader listingItem={listingItem} setListingItem={setListingItem} tags={li.listingItem?.tags ?? []} />
        <CardContent component={Stack} sx={{ p: 0, pb: `${theme.spacing(3)} !important`, height: 'calc(100% - 200px)' }}>
          <Stack p={3} minHeight={120} justifyContent="space-between">
            <Stack>
              <Typography variant="h6">
                {formatNumberToCurrency(listingItem.askingPrice ?? 0, 0)}
              </Typography>
            </Stack>
            <Stack>
              <Stack>
                <Stack direction="row" spacing={3} justifyContent="flex-start" alignItems="center" mt={1}>
                  {listingItem.property.bedrooms ? (
                    <Typography variant="body2">
                      {listingItem.property.bedrooms}
                      {' '}
                      {l.bedsShort}
                    </Typography>
                  ) : null}
                  {listingItem.property.bathrooms ? (
                    <Typography variant="body2">
                      {listingItem.property.bathrooms}
                      {' '}
                      {l.bathsShort}
                    </Typography>
                  ) : null}
                  {listingItem.property.sqft ? (
                    <Typography variant="body2">
                      {formatNumber(listingItem.property.sqft, 0)}
                      {' '}
                      {l.sqft}
                    </Typography>
                  ) : null}
                  {listingItem.property.yearBuilt ? (
                    <Typography variant="body2">
                      {l.builtIn}
                      {' '}
                      {listingItem.property.yearBuilt}
                    </Typography>
                  ) : null}
                </Stack>
              </Stack>
              <Typography variant="body2">
                {listingItem.address.fullAddress}
              </Typography>
            </Stack>
          </Stack>
          {displayPropertyMeasures ? (
            <Stack height={35} borderTop={`1px solid ${theme.palette.divider}`} p={3} mt="auto">
              <Stack
                direction="row"
                gap={3}
                alignItems="center"
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  gap={1}
                  sx={{ display: listingItem.measures.grossYield !== 0 ? 'flex' : 'none' }}
                >
                  {loadingGrossYieldAsDollarFF ? (
                    <Skeleton variant="text" width="80px" />
                  ) : (
                    <BoldTypography variant="body1">
                      {grossYield}
                    </BoldTypography>
                  )}
                  <Typography variant="body2">
                    {l.grossYield}
                  </Typography>
                </Stack>
                <Stack
                  alignItems="center"
                  direction="row"
                  gap={1}
                  sx={{ display: listingItem.measures.capRate !== 0 ? 'flex' : 'none' }}
                >
                  <BoldTypography variant="body1">
                    {formatDecimalToPercentage(listingItem.measures.capRate, 2)}
                  </BoldTypography>
                  <Typography variant="body2">
                    {l.capRate}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
        </CardContent>
      </StyledListingCard>
    </Link>
  );
};
