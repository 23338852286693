import { LegislationItem, MarketAnalysisData } from './types';
import { BillStatus, LegislationData, MarketData } from '../API';

export const convertMarketData = (marketData: MarketData | null): MarketAnalysisData => ({
  id: marketData?.id ?? '',
  date: marketData?.date ?? '',
  areaName: marketData?.areaName ?? '',
  homeValueSFR: marketData?.homeValueSFR ?? 0,
  homeValueAll: marketData?.homeValueAll ?? 0,
  rentSFR: marketData?.rentSFR ?? 0,
  rentAll: marketData?.rentAll ?? 0,
  marketSummary: marketData?.marketSummary ?? '',
});

export const convertLegislationData = (legislationData: LegislationData | null): LegislationItem => ({
  id: legislationData?.id ?? '',
  billNumber: legislationData?.billNumber ?? '',
  title: legislationData?.title ?? '',
  url: legislationData?.url ?? '',
  relevance: legislationData?.relevance ?? 0,
  status: legislationData?.status ?? BillStatus.CANCELLED,
  summary: legislationData?.summary ?? '',
  executiveSummary: legislationData?.executiveSummary ?? '',
});
