import { useState } from 'react';

import {
  DeleteRecurringTransactionModalProps, QueryKey, useAnalytics,
  useDeleteRecurringExpense, useDeleteSingleRecurringExpense, useGetRecurringExpenseByTransactionId,
} from 'lib';
import { MdClose } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  SpinnerWithLogo,
  useLabels, useToggle, YesNoDialog,
} from 'ui';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack, Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { sleep } from '../../lib/sleep';

export const DeleteRecurringTransactionsModal = ({
  isOpen, toggle, id,
}: DeleteRecurringTransactionModalProps) => {
  const l = useLabels();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();

  const [isDeleteOpen, toggleDeleteOpen] = useToggle();
  const [allDeleteSelected, setAllDeleteSelected] = useState(false);
  const [forceLoading, setForceLoading] = useState(false);
  const {
    mutateAsync: deleteSingleRecurringMutate,
  } = useDeleteSingleRecurringExpense();
  const {
    mutateAsync: deleteRecurringMutate,
  } = useDeleteRecurringExpense();
  const {
    isLoading: isLoadingRecurring,
    data: recurringExpense,
  } = useGetRecurringExpenseByTransactionId(id, isOpen);

  const onSubmit = async () => {
    analytics.track('Form Submitted', {
      formName: 'Delete Recurring Transaction',
      transactionId: recurringExpense?.data.id,
    });

    if (!recurringExpense) return;

    try {
      setForceLoading(true);
      if (allDeleteSelected) {
        await deleteRecurringMutate(recurringExpense.data.id);
      } else {
        await deleteSingleRecurringMutate({ recurringId: recurringExpense.data.id, transactionId: id });
      }

      await sleep(4000);
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toggle();
      toggleDeleteOpen();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
    setForceLoading(false);
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={toggle}
        sx={{
          zIndex: 1600,
        }}
      >
        {isLoadingRecurring ? (
          <Stack p={30}>
            <SpinnerWithLogo />
          </Stack>
        ) : (
          <>
            <DialogTitle>
              <Stack direction="row" alignItems="center" justifyContent="space-between" gap={3}>
                <Typography variant="h5" sx={{ textAlign: 'center' }}>
                  {l.deleteRecurringTitle}
                </Typography>
                <IconButton onClick={toggle}>
                  <MdClose />
                </IconButton>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body2" sx={{ textAlign: 'center' }}>
                {l.deleteRecurringDescription}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                onClick={() => { setAllDeleteSelected(true); toggleDeleteOpen(); }}
                style={{ flexGrow: 1 }}
                disabled={isLoadingRecurring}
              >
                {isLoadingRecurring ? l.pleaseWait : 'all'}
              </Button>
              <Button
                variant="contained"
                onClick={() => { toggleDeleteOpen(); }}
                style={{ flexGrow: 1 }}
                disabled={isLoadingRecurring}
              >
                {isLoadingRecurring ? l.pleaseWait : 'Only this'}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <YesNoDialog
        open={isDeleteOpen}
        onClose={toggleDeleteOpen}
        isLoading={forceLoading}
        onSubmit={onSubmit}
        title={l['delete-modal-title']}
        description=""
        actionID="delete"
      />
    </>
  );
};
