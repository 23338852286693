import { AppDashboardProperty, useListMarketAnalysis } from 'lib';
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { DollarCircleBlack } from '../../../icons';
import { useLabels } from '../../../lib/translations';
import { FallbackSpinner } from '../../../spinner';
import { BoldTypography } from '../../../typography/BoldTypography';
import { BlurredComponent } from '../../BlurredComponent';
import { calculateMinLength } from '../MarketAnalysis';
import { MarketAnalysisGraph } from '../MarketAnalysisGraph';
import { annualReportAreaDataPropertyID } from '../state';

export const MarketAnalysisPdf = ({ properties }: { properties: AppDashboardProperty[] }) => {
  const currentProperty = properties?.find((p) => p.id === annualReportAreaDataPropertyID.value);
  const fipsToUse = currentProperty ? currentProperty.address.fips : '';
  const { data: marketAnalysisData, isLoading } = useListMarketAnalysis(fipsToUse);
  const l = useLabels();

  const rentAllSeries: number[] = [];
  const rentSFRSeries: number[] = [];
  const valueAllSeries: number[] = [];
  const valueSFRSeries: number[] = [];

  const sortedMarketData = (marketAnalysisData ?? []).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  sortedMarketData.forEach((marketData) => {
    rentAllSeries.push(marketData.rentAll ?? 0);
    rentSFRSeries.push(marketData.rentSFR ?? 0);
    valueSFRSeries.push(marketData.homeValueSFR ?? 0);
    valueAllSeries.push(marketData.homeValueAll ?? 0);
  });

  const graphHeight = 250;

  if (isLoading || !marketAnalysisData) {
    return <FallbackSpinner />;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" gap="1rem" pt="0.5rem">
        <DollarCircleBlack height="2rem" width="2rem" />
        <BoldTypography variant="h5">
          {l['annualReport.marketAnalysis.title']}
          {' '}
          (
          {marketAnalysisData && marketAnalysisData[0].areaName}
          )
        </BoldTypography>
      </Stack>
      <Typography
        variant="body1"
        sx={{
          '&& .good': {
            color: '#0c7b60',
          },
          '&& .bad': {
            color: '#b7474f',
          },
        }}
        dangerouslySetInnerHTML={{ __html: marketAnalysisData[0].marketSummary ?? '' }}
      />
      <MarketAnalysisGraphsPdf
        graphHeight={graphHeight}
        rentSFRSeries={rentSFRSeries}
        rentAllSeries={rentAllSeries}
        valueSFRSeries={valueSFRSeries}
        valueAllSeries={valueAllSeries}
      />
    </>
  );
};

export const MarketAnalysisGraphsPdf = ({
  graphHeight, rentSFRSeries, valueSFRSeries, rentAllSeries, valueAllSeries,
}:
{ graphHeight: number, rentSFRSeries: number[], valueSFRSeries: number[],
  rentAllSeries: number[], valueAllSeries: number[] }) => {
  const l = useLabels();

  const rentSFRParsed = rentSFRSeries.filter((value) => value !== 0);
  const rentAllParsed = rentAllSeries.filter((value) => value !== 0);
  const valueSFRParsed = valueSFRSeries.filter((value) => value !== 0);
  const valueAllParsed = valueAllSeries.filter((value) => value !== 0);
  const rentAllEmpty = rentAllParsed.length === 0;
  const rentSFREmpty = rentSFRParsed.length === 0;
  const valueSFREmpty = valueSFRParsed.length === 0;
  const valueAllEmpty = valueAllParsed.length === 0;

  const minLength = calculateMinLength([rentSFRParsed.length,
    rentAllParsed.length, valueSFRParsed.length, valueAllParsed.length]);

  const rentSFRSliced = rentSFRParsed.slice(-minLength);
  const rentAllSliced = rentAllParsed.slice(-minLength);
  const valueSFRSliced = valueSFRParsed.slice(-minLength);
  const valueAllSliced = valueAllParsed.slice(-minLength);

  return (
    <Stack gap={6}>
      <Stack direction="row" justifyContent="space-between" gap={6}>
        <Grid container spacing={3}>
          <Grid container item xs={12} md={6} spacing={3}>
            <Grid item xs={12}>
              <BoldTypography variant="subtitle1">
                {l['annualReport.marketAnalysis.singleFamily']}
              </BoldTypography>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={rentSFREmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.rent']}
                  series={rentSFRSliced}
                  color="purple"
                  scale={1_000}
                  height={graphHeight}
                />
              </BlurredComponent>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={valueSFREmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.homeValue']}
                  series={valueSFRSliced}
                  color="blue"
                  getHalfMin
                  height={graphHeight}
                />
              </BlurredComponent>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={3}>
            <Grid item xs={12}>
              <BoldTypography variant="subtitle1">
                {l['annualReport.marketAnalysis.allProperties']}
              </BoldTypography>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={rentAllEmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.rent']}
                  series={rentAllSliced}
                  color="purple"
                  scale={1_000}
                  height={graphHeight}
                />
              </BlurredComponent>
            </Grid>
            <Grid item xs={12}>
              <BlurredComponent title="" subtitle={l['annualReport.noDataAvailable']} isBlurred={valueAllEmpty}>
                <MarketAnalysisGraph
                  title={l['annualReport.marketAnalysis.homeValue']}
                  series={valueAllSliced}
                  color="blue"
                  getHalfMin
                  height={graphHeight}
                />
              </BlurredComponent>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};
