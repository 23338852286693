import { MouseEvent, useState } from 'react';

import { useAnalytics } from 'lib';
import { MdKeyboardArrowRight } from 'react-icons/md';
import {
  Collapse, IconButton, Stack, Typography,
} from '@mui/material';

import { ExpenseRow } from './Expense';
import { DetailRow } from './Row';
import { formatNumberToCurrency } from '../../../lib/formatFuncs';
import { useLabels } from '../../../lib/translations';
import { InfoTooltip } from '../../../tooltip/InfoTooltip';
import { BoldTypography } from '../../../typography/BoldTypography';
import { SemiBoldTypography } from '../../../typography/SemiBoldTypography';
import { useGetPropertyIdParam } from '../state';
import { RowProps } from '../types';
import { useProjections } from '../useProjections';

export const Expenses = ({ form }: RowProps) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const [totalExpensesExpanded, setTotalExpensesExpanded] = useState(false);
  const propertyID = useGetPropertyIdParam();
  const { years, totalExpenses } = useProjections(form.watch(), propertyID);

  const handleClickToggleTotalExpenses = (e: MouseEvent) => {
    e.stopPropagation();

    analytics.track('Button Clicked', {
      buttonName: 'Toggle Total Expenses',
      value: !totalExpensesExpanded,
    });

    setTotalExpensesExpanded((expanded) => !expanded);
  };

  return (
    <>
      <DetailRow
        rowID="expenses"
        bottomBorder={false}
        columns={[
          {
            id: 'expenses',
            content: (
              <Stack direction="row" gap={3}>
                <BoldTypography variant="body1">
                  {l.expenses}
                </BoldTypography>
                <InfoTooltip
                  title={(
                    <Typography variant="body2">
                      {l['tooltip.proforma.expenses']}
                    </Typography>
                  )}
                  isLight
                  isOutlined
                />
              </Stack>
            ),
          },
          ...years.map(() => ({})),
        ]}
      />
      <DetailRow
        rowID="total-expenses"
        totalRow
        bottomBorder={false}
        onClickRow={handleClickToggleTotalExpenses}
        columns={[
          {
            id: 'total-expenses',
            content: (
              <Stack direction="row" alignItems="center" gap={1}>
                <IconButton onClick={handleClickToggleTotalExpenses} size="small" color="primary">
                  <MdKeyboardArrowRight
                    style={{
                      transform: totalExpensesExpanded ? 'rotate(90deg)' : 'none',
                      transition: 'transform 0.3s',
                    }}
                  />
                </IconButton>
                <SemiBoldTypography variant="body1">
                  {l.total}
                </SemiBoldTypography>
              </Stack>
            ),
          },
          ...years.map((year, i) => ({
            id: `total-expenses-year-${year}`,
            content: (
              <BoldTypography variant="body1">
                {formatNumberToCurrency(totalExpenses[i], 0)}
              </BoldTypography>
            ),
          })),
        ]}
      />
      <Collapse in={totalExpensesExpanded}>
        <ExpenseRow
          form={form}
          label={l.propertyTax}
          expenseKey="tax"
        />
        <ExpenseRow
          form={form}
          label={l.insurance}
          expenseKey="insurance"
        />
        <ExpenseRow
          form={form}
          label={l.hoa}
          expenseKey="hoa"
        />
        <ExpenseRow
          form={form}
          label={l.managementFees}
          expenseKey="managementFee"
        />
        <ExpenseRow
          form={form}
          label={l.maintenance}
          expenseKey="maintenance"
        />
        <ExpenseRow
          form={form}
          label={l.capEx}
          expenseKey="capEx"
        />
      </Collapse>
    </>
  );
};
