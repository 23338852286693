import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { recommendationBackgroundURL } from '../../assets';
import { useLabels } from '../../lib/translations';

export const PinkDotBanner = () => {
  const theme = useTheme();
  const l = useLabels();

  return (
    <Stack
      alignItems="center"
      px={6}
      py={7}
      height="80px"
      sx={{
        backgroundColor: theme.palette.background.default,
        borderRadius: '6px',
        backgroundImage: `url(${recommendationBackgroundURL})`,
        backgroundSize: 'cover',
      }}
      justifyContent="center"
    >
      <Typography
        sx={{
          textAlign: 'center',
        }}
        variant="body2"
      >
        {l['annualReport.didYouKnow']}
      </Typography>
    </Stack>
  );
};
