/* eslint-disable max-len */
import {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  APIUser, Role,
} from 'lib';
import { MdClose } from 'react-icons/md';
import {
  AnnualReport, annualReportLeadershipSummaryState, BoldTypography, InvertedButton, RichEditor, useLabels,
} from 'ui';
import {
  Autocomplete,
  Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton,
  Stack, TextField, useMediaQuery, useTheme,
} from '@mui/material';

type AutocompleteOption = {
  label: string,
  value: string,
};

const Editor = () => {
  const l = useLabels();

  return (
    <RichEditor
      placeholder={l['annualReport.owner.SummaryPlaceholder']}
      initialContent={annualReportLeadershipSummaryState.value}
      onContentChange={(html) => {
        annualReportLeadershipSummaryState.value = html;
      }}
    />
  );
};
const Actions = ({
  onClose,
  onSave,
  isLoading,
}: {
  onClose: () => void,
  onSave: (summary: string) => void,
  isLoading: boolean,
}) => {
  const l = useLabels();

  const summaryHtml = annualReportLeadershipSummaryState.value;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      width="100%"
      pt={3}
      gap={2}
    >
      <Button
        size="small"
        variant="text"
        onClick={onClose}
      >
        {l.cancel}
      </Button>
      <InvertedButton
        disabled={isLoading || !summaryHtml || summaryHtml === '<p></p>'}
        size="small"
        onClick={() => onSave(summaryHtml)}
      >
        {l.save}
      </InvertedButton>
    </Stack>
  );
};

export const LeadershipSummaryDialog = ({
  open,
  onClose,
  onSave,
  isLoading = false,
  users,
}: {
  open: boolean,
  users: APIUser[]
  onClose: () => void,
  onSave: (summary: string) => void,
  isLoading?: boolean
}) => {
  const l = useLabels();
  const theme = useTheme();
  const [selectedOwner, setSelectedOwner] = useState<AutocompleteOption | null>(null);
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  const closeDialog = () => {
    onClose();
  };

  useEffect(() => {
    if (users && users.length > 0 && !selectedOwner) {
      setSelectedOwner({
        label: users[0].email,
        value: users[0].id,
      });
    }
  }, [users, selectedOwner]);

  const [reportElement, setReportElement] = useState<HTMLDivElement | null>(null);

  const annualReportRef = useCallback((node: HTMLDivElement | null) => {
    setReportElement(node);
  }, []);

  useEffect(() => {
    if (!reportElement) return () => { };

    const observer = new MutationObserver(() => {
      reportElement.scrollTo({
        top: reportElement.scrollHeight,
        behavior: 'smooth',
      });
    });

    observer.observe(reportElement, { childList: true });
    return () => observer.disconnect();
  }, [reportElement]);

  useEffect(() => {
    if (!reportElement) return;

    reportElement.scrollTo({
      top: reportElement.scrollHeight,
      behavior: 'smooth',
    });
  }, [selectedOwner, reportElement]);

  const options = users.filter((ow) => ow.roles.includes(Role.OWNER)).map((user) => ({
    label: user.email,
    value: user.id,
  })).sort((a, b) => a.label.localeCompare(b.label));

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      fullScreen
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown
    >
      <DialogTitle sx={{ borderBottom: `1px solid ${theme.palette.divider}`, py: 2 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <BoldTypography variant="h6">
            {l['owner.summaryTitle']}
          </BoldTypography>
          <IconButton onClick={closeDialog}>
            <MdClose />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{
        p: `${theme.spacing(4)} !important`,
        overflowY: 'hidden',
        height: '100%',
        display: 'flex',
      }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            height: '100%',
            maxHeight: '100%',
          }}
        >
          <Grid item md={12} lg={5}>
            <Editor />
          </Grid>
          {!isLowerThanLg && (
            <Grid item md={12} lg={7} sx={{ height: '100%' }}>
              <Stack flexGrow={1} height="100%">
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap={5}
                  sx={{
                    padding: '12px',
                    border: '1px solid rgba(39, 38, 41, 0.12)',
                    borderTopRightRadius: '6px',
                    borderTopLeftRadius: '6px',
                  }}
                >
                  <BoldTypography variant="h6">
                    {l['annualReport.previewReport']}
                  </BoldTypography>
                  <Stack flexGrow={1} alignItems="flex-end" maxWidth="50%">
                    <Autocomplete
                      fullWidth
                      size="small"
                      value={selectedOwner}
                      options={options}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onChange={(_, value) => setSelectedOwner(value)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            sx: {
                              borderRadius: '8px',
                            },
                            ...params.InputProps,
                          }}
                          placeholder="Select owner for preview"
                          error={false}
                        />
                      )}
                    />
                  </Stack>
                </Stack>
                <Stack
                  flexGrow={1}
                  sx={{
                    overflowY: 'auto',
                    border: '1px solid rgba(39, 38, 41, 0.12)',
                    boxShadow: 'inset 0px 0px 6px rgba(0, 0, 0, 0.1)',
                    borderBottomRightRadius: '6px',
                    borderBottomLeftRadius: '6px',
                    minHeight: 0, // This allows scrolling to work properly
                  }}
                  ref={annualReportRef}
                >
                  <AnnualReport
                    inPreview
                    userActions={undefined}
                    showHeader={false}
                    owner={selectedOwner?.value ?? ''}
                  />
                </Stack>
              </Stack>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}`, py: 3 }}>
        <Actions
          onClose={closeDialog}
          onSave={onSave}
          isLoading={isLoading}
        />
      </DialogActions>
    </Dialog>
  );
};
