import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import dayjs from 'dayjs';
import {
  BuyerLeadsWorkflowStatus, ContactDetails, useAnalytics, useAuth,
} from 'lib';
import { MdPhone } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  FallbackSpinner, formatNumberToCurrency, formatPotentialTodayDate,
  LightTypography, ListBoard, RowGroup, RowGroupConfig, Spinner, useLabels,
} from 'ui';
import {
  Button, Stack, Typography, useTheme,
} from '@mui/material';
import { signal } from '@preact/signals-react';
import { useQueryClient } from '@tanstack/react-query';
import { ColumnDef } from '@tanstack/react-table';

import { useMutatePhoneCall } from '../../../api/calls';
import { useListBuyerLeads, useMutateBuyerLead } from '../../../api/workflows';
import { BuyerLead } from '../../../api/workflows/converters';
import { QueryKey } from '../../../types/enums';
import { Contact } from '../components/Contact';
import { LeadReveal, LeadType } from '../components/LeadReveal';
import { StatusChanger } from '../components/StatusChanger';
import { markListItemAsRevealed, sortContacts } from '../utils';
import { useBuyerLeadStatuses } from '../WorkflowStatus';

const rowGroupsSignal = signal<RowGroup<BuyerLead>[]>([]);

export const BuyerLeadsList = ({ orderedStatuses }: { orderedStatuses: BuyerLeadsWorkflowStatus[] }) => {
  const l = useLabels();
  const theme = useTheme();
  const { getStatus } = useBuyerLeadStatuses();
  const { data, isLoading, isFetchingNextPage } = useListBuyerLeads();

  useEffect(() => {
    if (!data) return;

    const columns: ColumnDef<BuyerLead, string>[] = [
      {
        header: l.status,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <BuyerLeadStatusChanger item={info.row.original} />
        ),
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.name,
        id: l.name,
        accessorKey: 'buyerContact.email',
        sortingFn: (a, b) => sortContacts(a.original.buyerContact, b.original.buyerContact),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <Contact contact={info.row.original.buyerContact} />,
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.property,
        id: l.property,
        accessorKey: 'name',
        sortingFn: (a, b) => a.original.name.localeCompare(b.original.name),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => {
          const lead = info.row.original;
          const firstCommaIndex = lead.name.indexOf(',');
          const name = firstCommaIndex > -1 ? lead.name.slice(0, firstCommaIndex) : lead.name;
          const location = firstCommaIndex > -1 ? lead.name.slice(firstCommaIndex + 1) : '';

          return (
            <Stack gap={1}>
              {lead.propertyId && lead.isPropertyVisible ? (
                <Link
                  to={`/marketplace/${lead.propertyId}`}
                  target="_blank"
                  style={{ color: theme.palette.info.main, textDecoration: 'none' }}
                >
                  <LightTypography variant="body1" sx={{ textWrap: 'nowrap' }}>
                    {name}
                  </LightTypography>
                </Link>
              ) : name}
              <LightTypography variant="body2" color="secondary" sx={{ textWrap: 'nowrap' }}>
                {location}
              </LightTypography>
            </Stack>
          );
        },
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.seller,
        id: l.seller,
        accessorKey: 'sellerAgentContact.email',
        sortingFn: (a, b) => sortContacts(a.original.sellerAgentContact, b.original.sellerAgentContact),
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <Contact contact={info.row.original.sellerAgentContact} />,
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        header: l.askingPrice,
        id: l.askingPrice,
        accessorKey: 'askingPrice',
        enableSorting: true,
        minSize: 125,
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => (
          <Typography variant="body1">
            {info.row.original.askingPrice ? formatNumberToCurrency(info.row.original.askingPrice, 0) : l.toDiscuss}
          </Typography>
        ),
        meta: { blurred: (row) => !row.original.revealed },
      },
      {
        id: l.updated,
        header: l.updated,
        accessorKey: 'updatedTime',
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => formatPotentialTodayDate(info.row.original.statusChangeTime),
        meta: { shownAsText: true, blurred: (row) => !row.original.revealed },
        sortingFn: (a, b) => dayjs(a.original.statusChangeTime).diff(dayjs(b.original.statusChangeTime)),
      },
      {
        header: '‎ ', // necessary to show separation between sticky and non-sticky columns
        // eslint-disable-next-line react/no-unstable-nested-components
        cell: (info) => <BuyerLeadActions lead={info.row.original} />,
        meta: { sticky: true },
      },
    ];

    const leads = data?.pages.flatMap((page) => page.leads) ?? [];

    const groups: RowGroupConfig<BuyerLead>[] = [];

    orderedStatuses.forEach((status) => {
      const statusConfig = getStatus(status);

      groups.push({
        rows: leads?.filter((lead) => lead.status === status) ?? [],
        columnDefs: columns,
        id: status,
        color: statusConfig.color,
        icon: statusConfig.smallIcon,
        title: statusConfig.displayValue,
        tooltip: statusConfig.tooltip,
      });
    });

    rowGroupsSignal.value = groups;
  }, [data]);

  if (isLoading || isFetchingNextPage) return <FallbackSpinner />;

  return (
    <ListBoard rowGroups={rowGroupsSignal.value} columnSort={[{ id: l.updated, desc: true }]} />
  );
};

export const BuyerLeadStatusChanger = ({ item }: { item: BuyerLead }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { statuses } = useBuyerLeadStatuses();
  const queryClient = useQueryClient();

  const [invalidatingQueries, setInvalidatingQueries] = useState(false);
  const { mutateAsync: updateStatus, isLoading: isUpdatingStatus } = useMutateBuyerLead();

  const handleStatusChange = async (status: BuyerLeadsWorkflowStatus) => {
    analytics.track('Selector Changed', {
      selectorName: 'Buyer Lead Status',
      value: status,
    });

    try {
      await updateStatus({
        id: item.id,
        status,
      });
      setInvalidatingQueries(true);
      await queryClient.invalidateQueries([QueryKey.BUYER_LEADS]);
      setInvalidatingQueries(false);
    } catch (err) {
      console.error(err);

      toast.error(l['error.unknownError']);
    }
  };

  const isLoading = invalidatingQueries || isUpdatingStatus;

  return (
    <StatusChanger
      id={item.id}
      status={item.status}
      statuses={statuses}
      changeStatus={handleStatusChange}
      isLoading={isLoading}
    />
  );
};

const BuyerLeadActions = ({ lead }: { lead: BuyerLead }) => {
  const l = useLabels();
  const analytics = useAnalytics();
  const { user } = useAuth();
  const { mutateAsync: sendPhoneCallRequest, isLoading: isCalling } = useMutatePhoneCall();

  const call = async (contact: ContactDetails | null | undefined, called: string) => {
    analytics.track('Button Clicked', {
      buttonName: 'Call',
      called,
      id: lead.id,
    });

    if (!contact?.phoneNumber) {
      toast.error(l['error.missingContactPhone']);
      return;
    }

    try {
      await sendPhoneCallRequest(contact.id);
      toast.success(l['leads.call.success']);
    } catch (err) {
      console.error(err);
      toast.error(l['error.unknownError']);
    }
  };

  const spinner = (
    <>
      &nbsp;
      <Spinner size={20} />
    </>
  );

  const sellerAgentIsSelf = lead.sellerAgentContact?.id === user?.id;
  const buyerIsSelf = lead.buyerContact?.id === user?.id;
  const showButtons = (
    (!sellerAgentIsSelf || !buyerIsSelf)
      && (lead.sellerAgentContact?.phoneNumber || lead.buyerContact?.phoneNumber)
  );

  return !lead.revealed ? (
    <LeadReveal
      id={lead.id}
      revealed={!!lead.revealed}
      leadType={LeadType.BUYER}
      onReveal={() => {
        markListItemAsRevealed(rowGroupsSignal, lead);
      }}
    />
  ) : showButtons && (
    <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
      {!buyerIsSelf && lead.buyerContact?.phoneNumber && (
        <Button
          disabled={isCalling}
          color="primary"
          variant="outlined"
          size="small"
          onClick={() => call(lead.buyerContact, 'Buyer')}
          startIcon={isCalling ? null : <MdPhone />}
        >
          {isCalling ? spinner : l.owner}
        </Button>
      )}
      {!sellerAgentIsSelf && lead.sellerAgentContact?.phoneNumber && (
        <Button
          disabled={isCalling}
          variant="outlined"
          size="small"
          onClick={() => call(lead.sellerAgentContact, 'Seller Agent')}
          startIcon={isCalling ? null : <MdPhone />}
        >
          {isCalling ? spinner : l.agent}
        </Button>
      )}
    </Stack>
  );
};
