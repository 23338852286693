import { ReactNode } from 'react';

import CountUp from 'react-countup';
import {
  Card, CardContent, Grid,
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import {
  CheckCircleNoBG, DollarCircleNoBG, DollarRectNoBG, GraphCircle, PinkDotIcon, TrendingUpNoBG,
} from '../../../icons';
import { useLabels } from '../../../lib/translations';
import { BoldTypography } from '../../../typography/BoldTypography';
import { MissingDataButton } from '../MissingDataButton';
import { PortfolioPerformanceProps } from '../PortfolioPerformance';

export const PortfolioPerformancePdf = ({
  owner,
  properties,
  totalValue,
  totalAppreciation,
  totalEquity,
  totalCashflow,
}: PortfolioPerformanceProps) => {
  const l = useLabels();

  return (
    <Stack gap="1rem">
      <Stack direction="row" alignItems="center" gap={2} pb={4} justifyContent="space-between" flexWrap="wrap">
        <Stack direction="row" alignItems="center" gap="1rem" pt="0.5rem">
          <GraphCircle height="2rem" width="2rem" />
          <BoldTypography variant="h5">{l['annualReport.portfolioPerformance.title']}</BoldTypography>
        </Stack>
        <MissingDataButton owner={owner} inPreview={false} />
      </Stack>
      <Grid container spacing={1}>
        <PerformanceCard
          title={l['annualReport.portfolioPerformance.totalValue']}
          value={totalValue}
          icon={<CheckCircleNoBG className="portfolio-performance-icon" />}
        />
        <PerformanceCard
          title={l['annualReport.portfolioPerformance.totalAppreciation']}
          value={totalAppreciation}
          icon={<TrendingUpNoBG className="portfolio-performance-icon" />}
        />
        <PerformanceCard
          title={l['annualReport.portfolioPerformance.totalEquity']}
          value={totalEquity}
          icon={<DollarRectNoBG className="portfolio-performance-icon" />}
        />
        <PerformanceCard
          title={l['annualReport.portfolioPerformance.totalCashflow']}
          value={totalCashflow}
          icon={<DollarCircleNoBG className="portfolio-performance-icon" />}
        />
      </Grid>
      <Card
        elevation={0}
        sx={{
          backgroundColor: 'rgba(247, 247, 250, 1)',
        }}
      >
        <CardContent>
          <Stack gap={4}>
            <BoldTypography variant="h6">{l.properties}</BoldTypography>
            <Stack justifyContent="space-between">
              {properties.map((property) => (
                <Stack direction="row" alignItems="center" gap={3} key={property.id}>
                  <PinkDotIcon className="pink-dot-icon" />
                  <Typography variant="body2">{property.address.fullAddress}</Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};

const PerformanceCard = ({
  title,
  value,
  icon,
}: {
  title: string,
  value: number,
  icon: ReactNode,
}) => {
  const l = useLabels();

  return (
    <Grid item md={3} sm={6} xs={12}>
      <Card
        elevation={0}
        sx={{
          height: '100%',
          backgroundColor: 'rgba(247, 247, 250, 1)',
        }}
      >
        <Stack gap={4} height="100%" justifyContent="center" p={3}>
          <BoldTypography variant="h6">
            {l.dollarCurrency}
            <CountUp end={value} start={0} duration={5} />
          </BoldTypography>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2">{title}</Typography>
            {icon}
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );
};
