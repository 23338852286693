import { useState } from 'react';

import {
  AddTransactionModalProps, ITransactionForm, QueryKey, TransactionFormAction, useAnalytics, useCreateExpense, useHideChat,
} from 'lib';
import { toast } from 'react-toastify';
import {
  Dialog,
  DialogContent,
  Stack, Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';

import { TransactionForm } from './Form';
import { useLabels } from '../../lib/translations';

export const AddTransactionsModal = ({
  isOpen, toggle, initialValues,
}: AddTransactionModalProps) => {
  const l = useLabels();
  const queryClient = useQueryClient();
  const analytics = useAnalytics();
  const { mutateAsync } = useCreateExpense();
  const [falseLoading, setFalseLoading] = useState(false);
  useHideChat(isOpen);

  const onSubmit = async (data: ITransactionForm) => {
    setFalseLoading(true);

    analytics.track('Form Submitted', {
      formName: 'Add Transaction',
      type: data.type,
      propertyId: data.propertyId,
      category: data.category,
      date: data.dates.date,
      firstPaymentDate: data.dates.firstPaymentDate,
      secondPaymentDate: data.dates.secondPaymentDate,
      amount: data.amount,
    });

    try {
      await mutateAsync(data);

      if (data.type === 'recurring') {
        await new Promise((resolve) => { setTimeout(resolve, 4000); });
      }
      await queryClient.invalidateQueries([QueryKey.PROPERTIES]);
      toggle();
    } catch (e) {
      console.error(e);
      toast.error(l['error.unknownError']);
    }
    setFalseLoading(false);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={toggle}
      sx={{
        zIndex: 1600,
      }}
    >
      <DialogContent>
        <Stack gap={3}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            {l.addExpense}
          </Typography>
          <TransactionForm
            action={TransactionFormAction.ADD}
            toggle={toggle}
            onSubmit={onSubmit}
            isLoading={falseLoading}
            initialValues={initialValues}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
