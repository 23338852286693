import {
  axiosWithPayloadContext, config, createHeaders,
  ListFavoritesByOwnerQuery,
  MarketplaceProperty,
  MarketplacePropertyListingItem,
  MarketPlacePropertyStatus,
  queryGraphQL,
  SearchableMarketplacePropertyListingItemFilterInput,
  SearchListingItemsQuery,
  useAuth,
} from 'lib';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import * as Sentry from '@sentry/react';
import { useInfiniteQuery, useMutation, useQuery } from '@tanstack/react-query';

import { convertProperty } from './converters';
import {
  buildFilters, FavoriteListingItemsQuery, Filters,
  ListingItemsQuery, Recommendations, sortOptionToBuildFunction,
} from './searchable';
import { QueryKey } from '../../types/enums';
import { ListingProperty } from '../../types/property';
import {
  createListingItemsQuery,
  getProperty,
  listFavoritePropertyIDs,
  listRecommendedPropertiesQuery,
  searchListingItemsForSearchOptionsQuery,
  searchRecommendation,
} from '../graphql/queries';

class MissingListingItemPropertiesError extends Error {
  name = 'MissingListingItemPropertiesError';
}

const getListingItemFromListing = (
  marketplaceProperty: MarketplaceProperty,
  pm: string,
): MarketplacePropertyListingItem | null => {
  let listingItem = marketplaceProperty.listingItems?.items?.find((li) => li && li.pm === pm);

  // If no listing item is found for the PM, use the first one which is supposed to be an interconnected one
  if (!listingItem) {
    listingItem = marketplaceProperty.listingItems?.items?.[0];
  }

  if (!listingItem) {
    console.error('No listing item found for property', marketplaceProperty.id);
    return null;
  }

  return listingItem;
};

export const useGetProperty = (id: string | undefined) => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  return useQuery([QueryKey.MARKETPLACE_PROPERTIES, id, pm], async (): Promise<ListingProperty | null> => {
    if (!id) return null;

    const token = await getAccessTokenSilently();
    const res = await queryGraphQL({
      query: getProperty,
      variables: { id },
      authToken: token,
    }) as GraphQLResult<{ getMarketplaceProperty: MarketplaceProperty }>;

    if (!res.data?.getMarketplaceProperty) {
      return null;
    }

    const marketplaceProperty = res.data.getMarketplaceProperty;

    const listingItem = getListingItemFromListing(marketplaceProperty, pm ?? '');

    if (!listingItem) return null;

    return convertProperty(
      marketplaceProperty,
      listingItem,
      user?.id ?? '',
    );
  });
};

export const useCreateOffer = () => {
  const { getAccessTokenSilently } = useAuth();

  return useMutation(async (propertyID: string) => {
    const token = await getAccessTokenSilently();

    return axiosWithPayloadContext({
      url: `${config.apiBaseURL}/offers`,
      method: 'POST',
      headers: createHeaders(token),
      data: { marketplacePropertyId: propertyID },
    });
  });
};

export const getFavoritePropertyIDs = async (userId: string, token: string): Promise<string[]> => {
  const res = await queryGraphQL({
    query: listFavoritePropertyIDs,
    variables: {
      owner: userId,
    },
    authToken: token,
  }) as GraphQLResult<ListFavoritesByOwnerQuery>;

  if (!res.data?.listFavoritesByOwner?.items) {
    return [];
  }

  const filtered = res.data.listFavoritesByOwner.items.filter((f) => f
    && f.marketplacePropertyFavoritesId);
  return filtered.map((p) => `${p?.marketplacePropertyFavoritesId}`);
};

export const useSearchProperties = (filters: Filters, limit: number, enabled: boolean = true) => {
  const { user, getAccessTokenSilently } = useAuth();

  const query = useInfiniteQuery({
    enabled,
    queryKey: [QueryKey.MARKETPLACE_PROPERTIES, JSON.stringify(filters)],
    queryFn: async ({ pageParam: nextToken }): Promise<{
      nextToken: string | null | undefined;
      properties: ListingProperty[];
    }> => {
      const token = await getAccessTokenSilently();
      const favIDs = !nextToken ? await getFavoritePropertyIDs(user?.id ?? '', token) : [];
      const favFilters = { ...filters, ids: favIDs };
      let res: GraphQLResult<FavoriteListingItemsQuery & ListingItemsQuery>;

      try {
        res = await queryGraphQL({
          query: createListingItemsQuery(favFilters.ids.length > 0),
          variables: {
            limit,
            nextToken: nextToken || undefined,
            filter: buildFilters(filters),
            sort: sortOptionToBuildFunction[filters.sortBy](),
            favoritesFilter: favFilters.ids.length > 0 ? buildFilters(favFilters) : undefined,
          },
          authToken: token,
        }) as GraphQLResult<FavoriteListingItemsQuery & ListingItemsQuery>;
      } catch (e: any) {
        res = e; // in this case, the error type is GraphQLResult object and we want the process to continue.
      }

      if (res === undefined) {
        return { nextToken: null, properties: [] };
      }

      if (!res.data?.listingItems && !res.data?.favoriteListingItems) {
        return { nextToken: null, properties: [] };
      }

      const favoriteListingItems = favFilters.ids.length > 0 ? res.data.favoriteListingItems?.items ?? [] : [];
      const listingItems = res.data.listingItems?.items ?? [];

      const processedProperties = new Set<string>();
      const properties: ListingProperty[] = [];

      const listingItemsWithMissingPropertyIDs = new Set<string>();

      [...favoriteListingItems, ...listingItems].forEach((li) => {
        if (!li || (li.property?.id && processedProperties.has(li.property?.id))) return;

        if (!li.property) {
          listingItemsWithMissingPropertyIDs.add(li.id);
          return;
        }

        processedProperties.add(li.property.id);
        properties.push(convertProperty(li.property, li, user?.id ?? ''));
      });

      if (listingItemsWithMissingPropertyIDs.size > 0) {
        Sentry.withScope((scope) => {
          scope.setContext('errorData', {
            listingItemIDs: Array.from(listingItemsWithMissingPropertyIDs),
          });

          Sentry.captureException(new MissingListingItemPropertiesError('Missing properties in listing items'));
        });
      }

      return {
        nextToken: res.data.listingItems?.nextToken,
        properties,
      };
    },
    getNextPageParam: (lastPage) => lastPage.nextToken,
  });

  return query;
};

export const useGetAutocompleteSearchOptions = (query: string) => {
  const { getAccessTokenSilently } = useAuth();

  return useQuery([QueryKey.AUTOCOMPLETE_SEARCH_OPTIONS, query], async (): Promise<string[]> => {
    const token = await getAccessTokenSilently();
    let res: GraphQLResult<SearchListingItemsQuery>;

    try {
      res = await queryGraphQL({
        query: searchListingItemsForSearchOptionsQuery,
        variables: {
          filter: {
            status: { eq: MarketPlacePropertyStatus.active },
            partialAddresses: { matchPhrasePrefix: query },
          },
          limit: 100,
        },
        authToken: token,
      }) as GraphQLResult<SearchListingItemsQuery>;
    } catch (e: any) {
      res = e; // in this case, the error type is GraphQLResult object and we want the process to continue.
    }

    if (!res.data?.searchMarketplacePropertyListingItems) {
      return [];
    }

    const addresses = new Set<string>();

    const listingItemsWithMissingPropertyIDs = new Set<string>();

    res.data.searchMarketplacePropertyListingItems.items?.forEach((s) => {
      if (!s) return;

      if (!s.property) {
        listingItemsWithMissingPropertyIDs.add(s.id);
        return;
      }

      s.partialAddresses.forEach((address) => {
        if (address.toLowerCase().includes(query.toLowerCase())) {
          addresses.add(address);
        }
      });
    });

    if (listingItemsWithMissingPropertyIDs.size > 0) {
      Sentry.withScope((scope) => {
        scope.setContext('errorData', {
          listingItemIDs: Array.from(listingItemsWithMissingPropertyIDs),
        });

        Sentry.captureException(new MissingListingItemPropertiesError('Missing properties in listing items'));
      });
    }

    return Array.from(addresses).sort((a, b) => {
      const aCommas = a.split(',').length;
      const bCommas = b.split(',').length;

      if (aCommas === bCommas) {
        return a.localeCompare(b);
      }

      return aCommas - bCommas;
    });
  }, { enabled: query.length > 1 });
};

export const useGetRecommendedListings = () => {
  const { user, getAccessTokenSilently } = useAuth();

  const pm = user?.pm;

  const query = useQuery({
    queryKey: [QueryKey.RECOMMENDED_LISTINGS],
    queryFn: async (): Promise<ListingProperty[]> => {
      const token = await getAccessTokenSilently();

      let resRecommendationsIds: GraphQLResult<Recommendations>;

      try {
        resRecommendationsIds = await queryGraphQL({
          query: listRecommendedPropertiesQuery,
          variables: {
            owner: user?.id,
            pm: user?.pm,
          },
          authToken: token,
        }) as GraphQLResult<Recommendations>;
      } catch (e: any) {
        resRecommendationsIds = e; // in this case, the error type is GraphQLResult object and we want the process to continue.
      }

      const uniqueListings = [
        ...new Set([...resRecommendationsIds.data?.getMarketplacePropertyPmRecommendation.recommendations
          ?.map((r) => r?.propertyId ?? '') ?? [],
        ...resRecommendationsIds.data?.getOwnerMarketplacePropertyRecommendation?.recommendations
          ?.map((r) => r?.propertyId ?? '') ?? []]),
      ];

      if (uniqueListings.length === 0) {
        return [];
      }

      const filter:SearchableMarketplacePropertyListingItemFilterInput = { };

      const recommendedListings :SearchableMarketplacePropertyListingItemFilterInput[] = uniqueListings.map((l) => ({
        marketplacePropertyListingItemsId: {
          eq: l,
        },
      }));

      filter.or = recommendedListings;

      let resRecommendationListings : GraphQLResult<ListingItemsQuery>;

      try {
        resRecommendationListings = await queryGraphQL({
          query: searchRecommendation,
          variables: {
            filter,
          },
          authToken: token,
        }) as GraphQLResult<ListingItemsQuery>;
      } catch (e: any) {
        resRecommendationListings = e;
      }

      const properties: ListingProperty[] = [];

      if (resRecommendationListings.data?.listingItems?.items) {
        resRecommendationListings.data?.listingItems?.items?.forEach((r) => {
          if (!r || !r.property) return;

          const listingItem = getListingItemFromListing(r.property, pm ?? '');

          if (!listingItem) return;

          properties.push(convertProperty(r.property, listingItem, user?.id ?? ''));
        });
      }

      return properties;
    },
  });

  return query;
};
