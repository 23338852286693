import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  useAnalytics, useAuth, useHideChat, useListOwnerProperties,
} from 'lib';
import { MdMapsHomeWork } from 'react-icons/md';
import {
  Avatar, EmptyFullPageContainer, FallbackSpinner, LeftTransition, useLabels,
} from 'ui';
import {
  Box, Container, Dialog, Fade, Typography, useTheme,
} from '@mui/material';

import { OnboardingProgress } from './OnboardingProgress';
import { OnboardingProperty } from './Property';
import { PropertiesDialogProps } from './types';

export const PropertiesDialog = ({
  open,
  skipCallback,
  openPrevDialog,
  mountOnEnter,
  unmountOnExit,
  fullScreen = false,
}: PropertiesDialogProps) => {
  const theme = useTheme();
  const l = useLabels();
  const { user } = useAuth();

  const analytics = useAnalytics();
  const {
    data: ownerProperties,
    isLoading,
    isError,
  } = useListOwnerProperties(user?.id ?? '');
  const properties = ownerProperties?.properties ?? [];
  const { propertyID } = useParams();
  const [propertySlide, setPropertySlide] = useState<number>(0);

  const handleNext = (idx: number) => () => {
    if (idx === properties!.length - 1) {
      analytics.track('Milestone Reached', {
        milestone: 'Properties Onboarding Completed',
      });
      skipCallback();
    } else {
      setPropertySlide(idx + 1);
    }
  };

  const handlePrev = (idx: number) => () => {
    if (idx === 0) {
      openPrevDialog();
    } else {
      setPropertySlide(idx - 1);
    }
  };

  useHideChat(fullScreen);

  useEffect(() => {
    if (!isLoading && !isError && propertyID && properties) {
      const idx = properties.findIndex((p) => p.id === propertyID);
      if (idx !== -1) {
        setPropertySlide(idx);
      }
    }

    if (!isLoading && !isError && properties.length === 0) {
      console.error('No properties found for user');
    }
  }, [properties, propertyID, isLoading, isError]);

  if (isError) {
    return <div>{l.error}</div>;
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      TransitionComponent={LeftTransition}
      PaperProps={{
        sx: { backgroundColor: theme.palette.common.white, zIndex: 1600 },
      }}
    >
      {isLoading || !properties ? (
        <Box px={20}><FallbackSpinner /></Box>
      ) : (
        <Container
          maxWidth="sm"
          sx={{
            p: 0,
            height: fullScreen ? '100%' : '100vh',
            width: fullScreen ? undefined : '400px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {properties && properties.length > 0 ? (
            <>
              {(properties && properties.length > 1)
                ? (<OnboardingProgress amount={propertySlide + 1} total={properties.length} />)
                : null }
              <Box position="relative" flexGrow="1">
                {properties.map((property, idx) => (
                  <Fade
                    key={`${property.id}-slide`}
                    in={idx === propertySlide}
                    timeout={600}
                    mountOnEnter={mountOnEnter}
                    unmountOnExit={unmountOnExit}
                  >
                    <Box position="absolute" top="0" height="100%" width="100%">
                      <OnboardingProperty
                        property={property}
                        nextCallback={handleNext(idx)}
                        prevCallback={handlePrev(idx)}
                        skipCallback={skipCallback}
                        showNextButton={idx !== properties.length - 1}
                        isLastProperty={idx === properties.length - 1}
                        showSaveAndExitButton
                        showPrevButton={idx !== 0}
                      />
                    </Box>
                  </Fade>
                ))}
              </Box>
            </>
          ) : (
            <Box position="relative" flexGrow="1">
              <EmptyFullPageContainer>
                <Avatar
                  skin="light"
                  variant="circular"
                  color="primary"
                  sx={{
                    mr: 3, mb: 3, width: 72, height: 72,
                  }}
                >
                  <MdMapsHomeWork size={40} />
                </Avatar>
                <Typography variant="h6">
                  {l.propertiesNotFound}
                </Typography>
                <Typography variant="body2">
                  {l.tryAgainLater}
                </Typography>
              </EmptyFullPageContainer>
            </Box>
          )}
        </Container>
      )}
    </Dialog>
  );
};
