import { BillStatus } from 'lib';
import { Chip, SxProps } from '@mui/material';

const statusToColor = {
  [BillStatus.ENACTED]: 'success',
  [BillStatus.CANCELLED]: 'info',
  [BillStatus.IN_PROGRESS]: 'warning',
} as const;

const statusToDisplayText = (status: BillStatus): string => {
  switch (status) {
    case BillStatus.ENACTED:
      return 'Enacted';
    case BillStatus.CANCELLED:
      return 'Cancelled';
    case BillStatus.IN_PROGRESS:
      return 'In Progress';
    default: return 'Unknown';
  }
};

export const LegislationStatusChip = ({ status, sx = undefined }: { status: BillStatus, sx?: SxProps }) => (
  <Chip
    color={statusToColor[status]}
    label={statusToDisplayText(status)}
    size="small"
    sx={{
      maxWidth: 100,
      width: 'fit-content',
      height: 16,
      ...sx,
    }}
  />
);
