import { useEffect, useState } from 'react';

const corsProxies = [
  'https://api.cors.lol/?url=',
  'https://api.allorigins.win/raw?url=',
];

export const DataImage = ({ src, ...props }: React.ImgHTMLAttributes<HTMLImageElement>) => {
  const [logoAsData, setLogoAsData] = useState<string | null>(null);

  useEffect(() => {
    if (!src) return;

    // our assets are protected by cors and need to be bypassed
    const fetchImage = async () => {
      // eslint-disable-next-line no-restricted-syntax
      for (const [index, proxy] of corsProxies.entries()) {
        try {
          // eslint-disable-next-line no-await-in-loop
          const response = await fetch(`${proxy}${src}`);
          const reader = new FileReader();
          reader.onloadend = () => {
            const base64data = reader.result as string;
            setLogoAsData(base64data);
          };
          // eslint-disable-next-line no-await-in-loop
          reader.readAsDataURL(await response.blob());

          break;
        } catch (error) {
          if (index === corsProxies.length - 1) {
            console.error('Error converting logo to base64:', error);
            setLogoAsData(null);
          }
        }
      }
    };

    fetchImage();
  }, [src]);

  return <img src={logoAsData || src} alt="img" {...props} />;
};
