import {
  APIUser, Role, UserResponse,
} from './types';

const excludedDomains: string[] = [
  'blankethomes.com', 'blips.us', 'portico', 'email-tester.com', 'test.mailgenius.com', 'mail-tester.com', 'experte-test.com',
];

export const convertUsersResponseToUsers = (
  pm: string,
  response: UserResponse[],
) :
APIUser[] => response.map((o) => ({
  id: `${pm}::${o.email}`,
  email: o.email,
  name: `${o.firstName} ${o.lastName}`,
  isActivated: o.isActivated,
  phoneNumber: o.phoneNumber,
  companyRole: o.companyRole,
  isSelected: false,
  roles: (o.roles as Role[]) || [],
})).filter((owner) => {
  let result = true;

  for (let i = 0; i < excludedDomains.length; i += 1) {
    const domain = excludedDomains[i];
    if (owner.email.includes(domain)) {
      result = false;
    }
  }

  return result;
});
