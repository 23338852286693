import { useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import {
  AppDashboardProperty, Category, useAnalytics, useMissingData,
} from 'lib';
import {
  DonutChartWithLegend, ExpenseWithLegendProps, InfoTooltip, useLabels,
} from 'ui';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';

import { MissingExpenses } from './MissingExpenses';
import { TooltipContent } from './TooltipContent';
import { RequiresPropertiesProps } from './types';

const MonthSelector = ({ availableMonths, selectedMonth, onMonthSelected }: {
  availableMonths: string[],
  selectedMonth: string,
  onMonthSelected: (selectedMonth: string) => void
}) => (
  <Box>
    <FormControl fullWidth>
      <Select
        fullWidth
        variant="outlined"
        value={selectedMonth}
        onChange={(event) => onMonthSelected(event.target.value)}
      >
        {availableMonths.map((month) => (
          <MenuItem value={month} key={month}>
            {`${dayjs(month).format('MMMM')}, ${dayjs(month).format('YYYY')}`}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);

const categoryMapping: Map<Category, { title: string, color: string }> = new Map([
  [Category.MORTGAGE, {
    title: 'Mortgage',
    color: '#00CE9D',
  }],
  [Category.MANAGEMENT_FEE, {
    title: 'Management',
    color: '#022AFB',
  }],
  [Category.PROPERTY_INSURANCE, {
    title: 'Insurance',
    color: '#00D8F5',
  }],
  [Category.PROPERTY_TAX, {
    title: 'Property Tax',
    color: '#8D02FB',
  }],
  [Category.PROPERTY_HOA, {
    title: 'HOA',
    color: '#DA0083',
  }],
  [Category.OTHER_EXPENSES, {
    title: 'Misc',
    color: '#0094FF',
  }],
  [Category.UTILITIES, {
    title: 'Utilities',
    color: '#cc25bb',
  }],
  [Category.LEGAL_AND_PROFESSIONAL_FEES, {
    title: 'Prof. Fees',
    color: '#7732a8',
  }],
  [Category.RENTAL_REGISTRATION, {
    title: 'Rental Registration',
    color: '#d10404',
  }],
  [Category.LEASING_AND_MARKETING, {
    title: 'Leasing & Marketing',
    color: '#d1b404',
  }],
  [Category.AUTO_AND_TRAVEL, {
    title: 'Auto & Travel',
    color: '#d1d104',
  }],
  [Category.SUPPLIES, {
    title: 'Supplies',
    color: '#04bcd1',
  }],
  [Category.MAINTENANCE, {
    title: 'Maintenance',
    color: '#138207',
  }],
  [Category.CAPITAL_EXPENDITURE, {
    title: 'Capital Expenditure',
    color: '#302050',
  }],
]);

const createExpensesForLegend = (properties: AppDashboardProperty[]): { [id: string]: ExpenseWithLegendProps[] } => {
  const expenseSum: { [id: string]: { [title: string]: ExpenseWithLegendProps } } = {};
  const lastYear = dayjs().subtract(1, 'year').startOf('month');

  properties.forEach((property) => {
    property.transactions.forEach((transaction) => {
      if (transaction.type === 'expense' && dayjs(transaction.transactionDate) > lastYear) {
        let title = 'Misc';
        let color = '#0094FF';

        const catData = categoryMapping.get(transaction.category);
        if (catData) {
          title = catData.title;
          color = catData.color;
        }

        const date = dayjs(transaction.transactionDate).format('YYYY-MM');
        const monthCat = expenseSum[date];
        if (monthCat) {
          const amount = (monthCat[title]?.amount ?? 0) + transaction.amount;
          expenseSum[date][title] = {
            amount,
            title,
            color,
          };
        } else {
          expenseSum[date] = {
            [title]: {
              amount: transaction.amount,
              title,
              color,
            },
          };
        }
      }
    });
  });

  const keys = Object.keys(expenseSum);
  keys.sort((a: string, b: string) => {
    const dateA = dayjs(a);
    const dateB = dayjs(b);

    return dateA.isBefore(dateB) ? 1 : -1;
  });

  const cleanSum: { [id: string]: ExpenseWithLegendProps[] } = {};

  keys.forEach((key) => {
    const value = expenseSum[key];
    // for the expense we need to show the amount as positive.
    // if expense was positive then it needs to be negative so the chart won't show it.
    Object.keys(value).forEach((valueKey) => {
      value[valueKey].amount *= -1;
    });

    cleanSum[key] = Object.values(value).flat();
  });

  return cleanSum;
};

const getTheMostTotalExpensesMonth = (
  expensesByMonths: { [id: string]: ExpenseWithLegendProps[] },
): { date: string, amount: number } => {
  let month = '';
  let amount = 0;
  Object.entries(expensesByMonths).forEach(([key, value]) => {
    const thisMonthTotal = value.reduce((acc, cur) => acc + cur.amount, 0);
    if (thisMonthTotal > amount) {
      amount = thisMonthTotal;
      month = key;
    }
  });
  return { date: month, amount };
};

const getDefaultMonth = (availableMonths: string[]) => {
  if (availableMonths.length === 0) return '';

  if (availableMonths.length === 1) return availableMonths[0];

  const firstMonth = availableMonths[0];
  const thisMonth = dayjs().format('YYYY-MM');
  if (firstMonth === thisMonth) {
    return availableMonths[1]; // select prev month if the first month is this month
  }

  return firstMonth;
};

export const MonthlyExpensesBreakdown = ({ properties }: RequiresPropertiesProps) => {
  const expensesByMonth = useMemo(() => createExpensesForLegend(properties), [properties]);

  const availableMonths = Object.keys(expensesByMonth);

  const [selectedMonth, setSelectedMonth] = useState(getDefaultMonth(availableMonths));
  const expenses = useMemo(() => expensesByMonth[selectedMonth] || [], [expensesByMonth, selectedMonth]);
  const mostExpensiveMonth = useMemo(() => getTheMostTotalExpensesMonth(expensesByMonth), [expensesByMonth]);
  const { missingData } = useMissingData(properties);

  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();

  const onMonthSelected = (newlySelectedMonth: string) => {
    analytics.track('Selector Changed', {
      selectorName: 'Monthly Expenses Breakdown - Month Selector',
      value: newlySelectedMonth,
      previousSelection: selectedMonth,
    });

    setSelectedMonth(newlySelectedMonth);
  };

  useEffect(() => {
    // if properties changed, and we only have one, refresh the selected month
    if (properties.length === 1) {
      setSelectedMonth(getDefaultMonth(availableMonths));
    }
  }, [properties]);

  const tooltipContent = [
    {
      label: l.expenseBreakdown,
    },
  ];

  return (
    <Card
      component={Stack}
      sx={{
        justifyContent: 'space-between',
        p: 0,
      }}
    >
      <Stack>
        <CardHeader
          sx={{ p: 4, pb: 2 }}
          title="Monthly Expenses"
          titleTypographyProps={{ variant: 'h6' }}
          action={(
            <InfoTooltip
              title={<TooltipContent bullets={tooltipContent} />}
              isOutlined
              arrow
              isLight
              track={(value) => {
                analytics.track('Tooltip Toggled', {
                  value,
                  tooltipName: 'Monthly Expenses Breakdown Tooltip',
                });
              }}
            />
          )}
        />
        <CardContent>
          <Stack spacing={3}>
            <MonthSelector
              availableMonths={availableMonths}
              selectedMonth={availableMonths.includes(selectedMonth) ? selectedMonth : availableMonths[0] || ''}
              onMonthSelected={onMonthSelected}
            />
            <DonutChartWithLegend expenses={expenses} />
            {!missingData.expenses.isMissing && (
              <Stack p={3} sx={{ background: theme.palette.customColors.info, borderRadius: '4px' }}>
                <Typography variant="body1">
                  {l.highestMonth}
                  {' '}
                  <ButtonBase
                    sx={{
                      color: 'info.main',
                      fontSize: 'inherit',
                      fontFamily: 'inherit',
                      fontWeight: 'inherit',
                      lineHeight: 'inherit',
                      letterSpacing: 'inherit',
                    }}
                    onClick={() => onMonthSelected(mostExpensiveMonth.date)}
                  >
                    {dayjs(mostExpensiveMonth.date).format('MMMM, YYYY')}
                  </ButtonBase>
                </Typography>
              </Stack>
            )}
          </Stack>
        </CardContent>
      </Stack>
      <MissingExpenses
        properties={properties}
        containerSx={{ mt: 0 }}
        onAdd={() => {
          analytics.track('Button Clicked', {
            buttonName: 'Missing Expenses CTA',
            widgetName: 'Monthly Expenses Breakdown',
          });
        }}
      />
    </Card>
  );
};
