import {
  MouseEvent,
  ReactNode, useEffect, useState,
} from 'react';

import { useDebounce } from 'lib';
import {
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold, MdFormatClear, MdFormatItalic, MdFormatListBulleted,
  MdFormatListNumbered, MdFormatUnderlined, MdLink, MdRedo, MdStrikethroughS,
  MdUndo,
} from 'react-icons/md';
import { RiFontSize } from 'react-icons/ri';
import {
  Button, ButtonGroup, Menu, MenuItem,
  Stack,
  styled,
  Typography,
  useTheme,
} from '@mui/material';
import { Editor as CoreEditor } from '@tiptap/core';
import Color from '@tiptap/extension-color';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import {
  EditorOptions,
  EditorProvider, useCurrentEditor,
} from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

import { FontSize } from './fontSize';
import { hexToRGBA } from '../lib/hexToRGBA';

import './style.css';

const EditorButton = styled(Button)(({ theme }) => ({
  '&&&': {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    minWidth: 0,
  },
  '&&&.active': {
    background: hexToRGBA(theme.palette.primary.main, 0.08),
  },
  '& .MuiButton-startIcon': {
    marginRight: 0,
  },
}));

const ColorPicker = styled('input')(({ theme }) => ({
  height: 31,
  width: 30,
  borderRadius: '4px',
  padding: theme.spacing(0.5),
  borderColor: 'transparent',
  background: 'inherit',
  '&:hover': {
    cursor: 'pointer',
    background: hexToRGBA(theme.palette.primary.main, 0.08),
  },
}));

const ButtonGroupButton = styled(EditorButton)(({ theme }) => ({
  '&&&.active,&&&:hover': {
    background: hexToRGBA(theme.palette.primary.dark, 0.08),
  },
  '&&&:hover': {
    borderColor: theme.palette.grey[400],
  },
  '&&&.active': {
    '& .MuiButton-startIcon': {
      color: theme.palette.primary.dark,
    },
  },
  borderColor: theme.palette.divider,
}));

const MenuBar = ({ onContentChange }: { onContentChange: (htmlContent: string, textContent: string) => void }) => {
  const theme = useTheme();
  const { editor } = useCurrentEditor();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickOnFontSize = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFontSize = () => {
    setAnchorEl(null);
  };

  const [, debouncedContent] = useDebounce({
    html: editor?.getHTML() ?? '',
    text: editor?.getText() ?? '',
  }, 300);

  useEffect(() => {
    if (editor) {
      onContentChange(debouncedContent.html, debouncedContent.text);
    }
  }, [debouncedContent]);

  if (!editor) {
    return null;
  }

  type Btn = {
    icon: ReactNode,
    onClick: () => void,
    disabled: boolean,
    name: string,
    ignoreActive?: boolean,
  };

  const buttons: Btn[] = [
    {
      icon: <MdUndo />,
      onClick: () => editor.chain().focus().undo().run(),
      disabled: !editor.can().chain().focus().undo()
        .run(),
      name: 'undo',
    },
    {
      icon: <MdRedo />,
      onClick: () => editor.chain().focus().redo().run(),
      disabled: !editor.can().chain().focus().redo()
        .run(),
      name: 'redo',
    },
    {
      icon: <MdFormatBold />,
      onClick: () => editor.chain().focus().toggleBold().run(),
      disabled: !editor.can().chain().focus().toggleBold()
        .run(),
      name: 'bold',
    },
    {
      icon: <MdFormatItalic />,
      onClick: () => editor.chain().focus().toggleItalic().run(),
      disabled: !editor.can().chain().focus().toggleItalic()
        .run(),
      name: 'italic',
    },
    {
      icon: <MdFormatUnderlined />,
      onClick: () => editor.chain().focus().toggleUnderline().run(),
      disabled: !editor.can().chain().focus().toggleUnderline()
        .run(),
      name: 'underline',
    },
    {
      icon: <MdStrikethroughS />,
      onClick: () => editor.chain().focus().toggleStrike().run(),
      disabled: !editor.can().chain().focus().toggleStrike()
        .run(),
      name: 'strike',
    },
    {
      icon: <MdFormatListBulleted />,
      onClick: () => editor.chain().focus().toggleBulletList().run(),
      disabled: !editor.can().chain().focus().toggleBulletList()
        .run(),
      name: 'bulletList',
    },
    {
      icon: <MdFormatListNumbered />,
      onClick: () => editor.chain().focus().toggleOrderedList().run(),
      disabled: !editor.can().chain().focus().toggleOrderedList()
        .run(),
      name: 'orderedList',
    },
    {
      icon: <MdLink />,
      onClick: () => {
        if (editor.getAttributes('link').href) {
          editor.chain().focus().unsetLink().run();
        } else {
          // eslint-disable-next-line no-alert
          editor.chain().focus().toggleLink({ href: prompt('Please input link') || '' }).run();
        }
      },
      disabled: !editor.can().chain().focus().unsetLink()
        .run(),
      name: 'link',
    },
  ];

  // create an onclick wrapper to stop the propagation of the event
  const onClickWrapper = (onClick: (event: MouseEvent<HTMLElement>) => void) => (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onClick(event);
  };

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      gap={1}
      borderBottom={`1px solid ${theme.palette.divider}`}
      p={3}
      mb={3}
      alignItems="center"
      className="rich-editor-menu-bar"
    >
      {buttons.map((button) => (
        <EditorButton
          key={button.name}
          variant="text"
          size="small"
          startIcon={button.icon}
          onClick={onClickWrapper(button.onClick)}
          disabled={button.disabled}
          className={!button.ignoreActive && editor.isActive(button.name) ? 'active' : ''}
        />
      ))}
      <ColorPicker
        type="color"
        value={editor.getAttributes('textStyle').color || '#000000'}
        onChange={(e) => {
          editor.chain().focus().setColor(e.target.value).run();
        }}
      />
      <EditorButton
        variant="text"
        size="small"
        onClick={onClickWrapper(handleClickOnFontSize)}
        startIcon={(
          editor.isActive('textStyle') && editor.getAttributes('textStyle').fontSize ? (
            <Typography sx={{ fontSize: '14px !important', textTransform: 'none' }}>
              {editor.getAttributes('textStyle').fontSize}
            </Typography>
          ) : (
            <RiFontSize />
          )
        )}
      />
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseFontSize}>
        {[12, 14, 16, 18, 20, 24, 28, 32, 36, 40].map((size) => (
          <MenuItem
            key={`${size}`}
            onClick={onClickWrapper(() => {
              editor.chain().focus().setFontSize(`${size}px`).run();
              handleCloseFontSize();
            })}
          >
            <Typography sx={{ fontSize: size }}>
              {size}
              px
            </Typography>
          </MenuItem>
        ))}
      </Menu>
      <ButtonGroup variant="outlined" size="small" sx={{ ml: 2 }}>
        <ButtonGroupButton
          startIcon={<MdFormatAlignLeft />}
          onClick={onClickWrapper(() => editor.chain().focus().setTextAlign('left').run())}
          disabled={!editor.can().chain().focus().setTextAlign('left')
            .run()}
          className={editor.isActive({ textAlign: 'left' }) ? 'active' : ''}
        />
        <ButtonGroupButton
          startIcon={<MdFormatAlignCenter />}
          onClick={onClickWrapper(() => editor.chain().focus().setTextAlign('center').run())}
          disabled={!editor.can().chain().focus().setTextAlign('center')
            .run()}
          className={editor.isActive({ textAlign: 'center' }) ? 'active' : ''}
        />
        <ButtonGroupButton
          startIcon={<MdFormatAlignRight />}
          onClick={onClickWrapper(() => editor.chain().focus().setTextAlign('right').run())}
          disabled={!editor.can().chain().focus().setTextAlign('right')
            .run()}
          className={editor.isActive({ textAlign: 'right' }) ? 'active' : ''}
        />
        <ButtonGroupButton
          startIcon={<MdFormatAlignJustify />}
          onClick={onClickWrapper(() => editor.chain().focus().setTextAlign('justify').run())}
          disabled={!editor.can().chain().focus().setTextAlign('justify')
            .run()}
          className={editor.isActive({ textAlign: 'justify' }) ? 'active' : ''}
        />
      </ButtonGroup>
      <EditorButton
        onClick={onClickWrapper(() => {
          editor
            .chain()
            .focus()
            .clearNodes()
            .unsetAllMarks()
            .run();
        })}
        startIcon={<MdFormatClear />}
      />
    </Stack>
  );
};

const extensions = [
  TextStyle,
  Underline,
  Color,
  Link.configure({
    openOnClick: false,
    autolink: true,
    defaultProtocol: 'https',
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph'],
  }),
  FontSize,
  StarterKit.configure({
    bulletList: {
      keepMarks: true,
      keepAttributes: false,
    },
    orderedList: {
      keepMarks: true,
      keepAttributes: false,
    },
  }),
];

export const RichEditor = ({
  initialContent,
  onContentChange,
  onCreate = undefined,
  placeholder = '',
}: {
  initialContent: string,
  onContentChange: (htmlContent: string, textContent: string) => void,
  onCreate?: EditorOptions['onCreate'];
  placeholder?: string;
}) => {
  const theme = useTheme();
  const [focusEditor, setFocusEditor] = useState<(() => void) | null>(null);

  const placeholderContent = Placeholder.configure({
    placeholder,
  });

  const extensionsArray = [...extensions, placeholderContent];

  return (
    <Stack
      height="100%"
      border={`1px solid ${theme.palette.divider}`}
      borderRadius="6px"
      onClick={(e) => {
        // Don't focus if text is selected
        if (window.getSelection()?.toString() || (e.target as HTMLElement)?.classList?.contains('rich-editor-menu-bar')) {
          return;
        }

        focusEditor?.();
      }}
      sx={{
        '& .is-editor-empty:first-of-type::before': {
          color: theme.palette.text.disabled,
          content: 'attr(data-placeholder)',
          float: 'left',
          height: 0,
          pointerEvents: 'none',
        },
        '&:hover': {
          cursor: 'text',
        },
        '& .rich-editor-menu-bar:hover': {
          cursor: 'default',
        },
      }}
    >
      <EditorProvider
        slotBefore={<MenuBar onContentChange={onContentChange} />}
        extensions={extensionsArray}
        content={initialContent}
        immediatelyRender={false}
        onCreate={({ editor }, ...args) => {
          onCreate?.({ editor }, ...args);

          setFocusEditor(() => () => editor.commands.focus('end', { scrollIntoView: true }));
        }}
      />
    </Stack>
  );
};

export const copyHtmlToRTF = async (html: string, plain: string) => {
  if (typeof ClipboardItem !== 'undefined') {
    const richTextBlob = new Blob([html], { type: 'text/html' });
    const plainTextBlob = new Blob([plain], { type: 'text/plain' });
    const data = new ClipboardItem({ 'text/html': richTextBlob, 'text/plain': plainTextBlob });
    await navigator.clipboard.write([data]);
  } else {
    // Fallback using the deprecated `document.execCommand`.
    // https://developer.mozilla.org/en-US/docs/Web/API/Document/execCommand#browser_compatibility
    const cb = (e: any) => {
      e.clipboardData.setData('text/html', html);
      e.clipboardData.setData('text/plain', plain);
      e.preventDefault();
    };
    document.addEventListener('copy', cb);
    document.execCommand('copy');
    document.removeEventListener('copy', cb);
  }
};

export type RichEditorInstance = CoreEditor;
