import {
  Navigate, Route, Routes, useLocation,
} from 'react-router-dom';

import { useFeatures } from 'lib';

import { BlanketOverview } from './views/blanket-overview';
import { Contacts } from './views/contacts';
import { Leads } from './views/leads';
import { Network } from './views/network';
import { Partners } from './views/opportunities';
import { PmProperties } from './views/pm-properties';
import { Retain } from './views/retain';

export const App = () => {
  const { isLoading: isLoadingFeatures, ...features } = useFeatures();

  if (isLoadingFeatures) return null;

  const pmPath = features.isPmHomePageEnabled ? '/pm' : '/pm/grow/opportunities';

  return (
    <Routes>
      {features.isPmAppEnabled && (
        <>
          {features.isPmHomePageEnabled && <Route index element={<BlanketOverview />} />}
          <Route path="/contacts" element={<Navigate to="/pm/contacts/owners" />} />
          <Route path="/contacts/:contactType" element={<Contacts />} />
          {features.isRetainProductEnabled && (
            <>
              <Route path="/retain" element={<Retain />} />
              <Route path="/retain/:tab" element={<Retain />} />
            </>
          )}
          {features.isPmPropertiesDashboardEnabled && <Route path="/properties" element={<PmProperties />} />}
          {features.isGrowProductEnabled && (
            <>
              <Route path="/grow/opportunities" element={<Partners />} />
              <Route path="/grow/opportunities/:suggestedPartnerType" element={<Partners />} />
              <Route path="/grow/network" element={<Network />} />
            </>
          )}
        </>
      )}
      {features.isPmLeadsViewEnabled && <Route path="/leads" element={<Leads />} />}
      <Route
        path="*"
        element={
          // eslint-disable-next-line no-nested-ternary
          <Navigate to={features.isPmAppEnabled ? pmPath : '/'} />
        }
      />
    </Routes>
  );
};

export const RedirectsFromOldPMDashboard = () => {
  const location = useLocation();

  return (
    <Routes>
      <Route path="/owners" element={<Navigate to={`/pm/contacts/owners${location.search}`} />} />
      <Route path="/prospects" element={<Navigate to={`/pm/contacts/prospects${location.search}`} />} />
      <Route path="/deals" element={<Navigate to={`/pm/leads${location.search}`} />} />
      <Route path="/properties" element={<Navigate to={`/pm/properties${location.search}`} />} />
      <Route path="/blanket-overview" element={<Navigate to={`/pm${location.search}`} />} />
    </Routes>
  );
};
