import { useNavigate } from 'react-router-dom';

import { AppDashboardProperty, useMissingData } from 'lib';
import { MissingData, useLabels } from 'ui';
import { SxProps } from '@mui/material';

type Props = {
  properties: AppDashboardProperty[]
  onAdd: () => void;
  containerSx?: SxProps
};

export const MissingExpenses = ({ properties, onAdd, containerSx = {} }: Props) => {
  const l = useLabels();
  const navigate = useNavigate();
  const { missingData } = useMissingData(properties);

  const handleAdd = () => {
    onAdd();
    navigate('/transactions', { state: { missingExpenses: true } });
  };

  if (!missingData.expenses.isMissing) return null;

  return (
    <MissingData
      label={l.missingExpenses}
      containerSx={containerSx}
      onAdd={handleAdd}
    />
  );
};
