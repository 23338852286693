import { useEffect, useRef, useState } from 'react';

import dayjs from 'dayjs';
import { TFilterDate, useAnalytics } from 'lib';
import { MdClose, MdFilterList } from 'react-icons/md';
import { useLabels } from 'ui';
import {
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';

export const FilterModal = ({
  categories,
  properties,
  onApply,
  firstDate,
  lastDate,
  selectedCategories,
}: {
  categories: string[], properties: string[],
  onApply: ({
    newProperties, newCategories, startDate, endDate,
  }: { newProperties: string[], newCategories: string[], startDate: TFilterDate, endDate: TFilterDate }) => void,
  firstDate: string,
  lastDate: string,
  selectedCategories: string[],
}) => {
  const appliedValues = useRef<{ categories: string[], properties: string[], startDate: TFilterDate, endDate: TFilterDate }>({
    categories: [], properties: [], startDate: null, endDate: null,
  });
  const [categoriesChecked, setCategoriesChecked] = useState<string[]>(selectedCategories);
  const [propertiesChecked, setPropertiesChecked] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [startDate, setStartDate] = useState<TFilterDate>(dayjs(firstDate).toDate());
  const [endDate, setEndDate] = useState<TFilterDate>(dayjs(lastDate).toDate());
  const open = Boolean(anchorEl);

  const l = useLabels();
  const analytics = useAnalytics();
  const theme = useTheme();

  useEffect(() => {
    setCategoriesChecked(selectedCategories);
  }, [selectedCategories]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleToggle = (val: string, type: string) => () => {
    const [checked, setChecked] = type === 'category'
      ? [categoriesChecked, setCategoriesChecked]
      : [propertiesChecked, setPropertiesChecked];
    const currentIndex = checked.indexOf(val);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(val);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const handleCancel = () => {
    setCategoriesChecked(appliedValues.current?.categories || []);
    setPropertiesChecked(appliedValues.current?.properties || []);
    setStartDate(appliedValues.current?.startDate);
    setEndDate(appliedValues.current?.endDate);
    handleClose();
  };

  const handleReset = () => {
    setCategoriesChecked([]);
    setPropertiesChecked([]);
    setStartDate(dayjs(firstDate).toDate());
    setEndDate(dayjs(lastDate).toDate());

    onApply({
      newCategories: [],
      newProperties: [],
      startDate: null,
      endDate: null,
    });

    handleClose();
  };

  const handleSubmit = () => {
    const newProperties = {
      categories: categoriesChecked,
      properties: propertiesChecked,
      startDate,
      endDate,
    };

    analytics.track('Form Submitted', {
      formName: 'Transactions Filter',
      properties: newProperties.properties.join(', '),
      categories: newProperties.categories.join(', '),
      startDate: newProperties.startDate ? dayjs(newProperties.startDate).format('MMM, YYYY') : null,
      endDate: newProperties.endDate ? dayjs(newProperties.endDate).format('MMM, YYYY') : null,
    });

    appliedValues.current = newProperties;
    onApply({
      newCategories: categoriesChecked,
      newProperties: propertiesChecked,
      startDate,
      endDate,
    });
    handleClose();
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isViewportTooNarrow = useMediaQuery(theme.breakpoints.down(385));

  return (
    <div>
      <Button
        color="secondary"
        onClick={handleOpen}
        variant="outlined"
        startIcon={isViewportTooNarrow ? null : <MdFilterList />}
      >
        {l.filter}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiList-root': {
            py: 0,
          },
        }}
      >
        <Stack>
          <Stack direction="row" py={3} px={4} pb={0} justifyContent="space-between" alignItems="center">
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>Filter</Typography>
            <IconButton aria-label="delete" color="primary" onClick={handleClose}>
              <MdClose />
            </IconButton>
          </Stack>
          <Divider />
          <Stack
            p={4}
            sx={{
              overflowY: 'scroll',
              maxHeight: isMobile ? 300 : 600,
              flexGrow: 1,
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{l['transactions.filters.period']}</Typography>
            <Stack gap={4} direction="row" mt={4} mb={5}>
              <DesktopDatePicker
                views={['year', 'month']}
                inputFormat="MMM, YYYY"
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={l['transactions.filters.period']}
                    inputProps={{ placeholder: 'MMM, YYYY', ...params.inputProps }}
                    sx={{ width: '45%' }}
                  />
                )}
                onChange={(v) => {
                  setStartDate(v);
                }}
                value={startDate}
              />

              <DesktopDatePicker
                views={['year', 'month']}
                inputFormat="MMM, YYYY"
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    label={l['transactions.filters.toDate']}
                    inputProps={{ placeholder: 'MMM, YYYY', ...params.inputProps }}
                    sx={{ width: '45%' }}
                  />
                )}
                onChange={(v) => {
                  setEndDate(v);
                }}
                value={endDate}
              />
            </Stack>

            <Typography sx={{ fontWeight: 'bold' }}>{l['transactions.filters.category']}</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {categories.map((category) => {
                const labelId = `checkbox-list-label-${category}`;

                return (
                  <ListItem
                    key={category}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(category, 'category')} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={categoriesChecked.includes(category)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        id={labelId}
                        primary={category}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <Typography sx={{ fontWeight: 'bold' }}>{l['transactions.filters.property']}</Typography>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {properties.map((property) => {
                const labelId = `checkbox-list-label-${property}`;

                return (
                  <ListItem
                    key={property}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={handleToggle(property, 'property')} dense>
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={propertiesChecked.includes(property)}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={property} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            p={4}
            justifyContent="space-between"
            sx={{
              borderTop: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Button onClick={() => {
              handleCancel();
              analytics.track('Button Clicked', {
                buttonName: 'Transactions Filter Cancel',
              });
            }}
            >
              {l.cancel}
            </Button>
            <Stack gap={2} direction="row">
              <Button onClick={() => {
                handleReset();
                analytics.track('Button Clicked', {
                  buttonName: 'Transactions Filter Reset',
                });
              }}
              >
                {l.reset}
              </Button>
              <Button color="primary" variant="contained" onClick={handleSubmit}>{l.apply}</Button>
            </Stack>
          </Stack>
        </Stack>
      </Menu>
    </div>
  );
};
