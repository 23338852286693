import { ReactNode } from 'react';

import { sum, useAnalytics } from 'lib';
import {
  Card, CardContent, Stack, Typography, useTheme,
} from '@mui/material';

import { AreaChart, BarChart, getYAxisMaxAmount } from '../../../charts';
import { useLabels } from '../../../lib/translations';
import { InfoTooltip } from '../../../tooltip/InfoTooltip';
import { BoldTypography } from '../../../typography/BoldTypography';
import { useGetPropertyIdParam } from '../state';
import { Form } from '../types';
import { useProjections } from '../useProjections';

const graphYears = Array.from({ length: 25 }, (_, i) => i + 1);
const graphYearLabelYears = [1, 5, 10, 15, 20, 25];

export const useGraphYearLabel = () => {
  const l = useLabels();
  const getYearLabel = (year: number) => `${l.year} ${year}`;

  return {
    getYearLabel,
    formatXLabel: (label: string | number | undefined) => {
      if (!label) return '';

      const year = Number(label.toString().replace(l.year, '').trim());

      return graphYearLabelYears.includes(year) ? getYearLabel(year) : '';
    },
  };
};

export const EquityBuildUp = ({ form }: { form: Form }) => {
  const l = useLabels();
  const theme = useTheme();
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form.watch(), propertyID, graphYears);

  const { formatXLabel } = useGraphYearLabel();

  const loanBalance = {
    name: l.loanBalance,
    color: theme.palette.error.main,
    data: projections.years.map((_, i) => projections.loanBalance[i]),
  };
  const equity = {
    name: l.equity,
    color: theme.palette.success.main,
    data: projections.years.map((_, i) => projections.equity[i]),
  };
  const propertyValue = {
    name: l.propertyValue,
    color: theme.palette.info.main,
    data: projections.years.map((_, i) => projections.askingPrices[i]),
  };
  const series = [loanBalance, equity, propertyValue];

  const yAxisMax = getYAxisMaxAmount([...loanBalance.data, ...equity.data, ...propertyValue.data]);

  return (
    <GraphCard
      title={l['proforma.equityBuildUp']}
      tooltip={(
        <TooltipContent
          title={l['proforma.equityBuildUp.tooltip.title']}
          listItems={[...l['proforma.equityBuildUp.tooltip.listItems']]}
          footnote={l['proforma.equityBuildUp.tooltip.footnote']}
        />
      )}
      tooltipName="Equity Build Up"
    >
      <AreaChart
        series={series}
        xLabels={graphYears}
        height={211}
        width="102%"
        max={yAxisMax}
        formatXLabel={formatXLabel}
      />
    </GraphCard>
  );
};

export const InvestmentValueOverTime = ({ form }: { form: Form }) => {
  const l = useLabels();
  const theme = useTheme();
  const propertyID = useGetPropertyIdParam();
  const projections = useProjections(form.watch(), propertyID, graphYears);

  const { getYearLabel, formatXLabel } = useGraphYearLabel();

  const netCashFlow = {
    name: l.cumNetCashFlow,
    color: theme.palette.warning.main,
    data: projections.years.map((_, i) => projections.calculations.getCumulativeNetCashflow(i)),
  };
  const appreciation = {
    name: l.cumAppreciation,
    color: theme.palette.info.main,
    data: projections.years.map((_, i) => projections.calculations.getCumulativeAppreciation(i)),
  };
  const equity = {
    name: l.cumEquity,
    color: theme.palette.success.main,
    data: projections.years.map((_, i) => projections.equity[i]),
  };
  const investmentValue = {
    name: l.estInvestmentValue,
    color: theme.palette.primary.dark,
    data: projections.years.map((_, i) => sum([
      projections.calculations.getCumulativeNetCashflow(i),
      projections.calculations.getCumulativeAppreciation(i),
      projections.equity[i],
    ])),
    type: 'line',
  };
  const series = [netCashFlow, appreciation, equity, investmentValue];

  const yAxisMax = getYAxisMaxAmount(projections.years.map((_, i) => sum([
    Math.abs(netCashFlow.data[i]),
    Math.abs(appreciation.data[i]),
    Math.abs(equity.data[i]),
  ])));

  return (
    <GraphCard
      title={l['proforma.investmentValueOverTime']}
      tooltip={(
        <TooltipContent
          title={l['proforma.investmentValueOverTime.tooltip.title']}
          listItems={[...l['proforma.investmentValueOverTime.tooltip.listItems']]}
          footnote={l['proforma.investmentValueOverTime.tooltip.footnote']}
        />
      )}
      tooltipName="Investment Value Over Time"
    >
      <BarChart
        series={series}
        labels={graphYears.map(getYearLabel)}
        height={211}
        formatXLabel={formatXLabel}
        max={yAxisMax}
        stroke={{
          width: [0, 0, 0, 2],
          curve: 'smooth',
        }}
      />
    </GraphCard>
  );
};

export const GraphCard = ({
  title, tooltip, tooltipName, children,
}: {
  title: string,
  tooltip: ReactNode,
  tooltipName: string,
  children: ReactNode,
}) => {
  const theme = useTheme();
  const analytics = useAnalytics();

  return (
    <Card elevation={0} sx={{ border: `1px solid ${theme.palette.divider}` }}>
      <CardContent sx={{
        height: '100%', display: 'flex', flexDirection: 'column',
      }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <BoldTypography variant="h6">
            {title}
          </BoldTypography>
          <InfoTooltip
            arrow
            isLight
            title={tooltip}
            isOutlined
            track={(value) => {
              analytics.track('Tooltip Toggled', {
                value,
                tooltipName,
              });
            }}
          />
        </Stack>
        <Stack>
          {children}
        </Stack>
      </CardContent>
    </Card>
  );
};

export const TooltipContent = ({
  title,
  listItems,
  footnote,
}: {
  title: string,
  listItems: string[],
  footnote: string,
}) => {
  const theme = useTheme();

  return (
    <Stack direction="column" gap={1} p={2}>
      <BoldTypography variant="body2">
        {title}
      </BoldTypography>
      <ul style={{ paddingLeft: theme.spacing(2) }}>
        {listItems.map((item) => (
          <li key={item}>
            <Typography variant="body3">
              {item}
            </Typography>
          </li>
        ))}
      </ul>
      <Typography variant="body2">
        {footnote}
      </Typography>
    </Stack>
  );
};
