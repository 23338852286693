import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {
  AppDashboardProperty,
  checkIsFirstLogin, config, useAnalytics, useAuth,
  useFeatures,
  useGetUserActions,
  useListOwnerProperties,
  useMissingData,
  useMutateUserActions,
} from 'lib';
import { MdClose } from 'react-icons/md';
import {
  Avatar,
  Banner2,
  BoldTypography,
  EmptyFullPageContainer,
  FallbackSpinner,
  MissingDataIcon,
  useLabels,
} from 'ui';
import {
  Box,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';

import { DialogOwnerSurvey } from './DialogOwnerSurvey';
import { StyledPaperDesktop, StyledPaperMobile } from '../../components/first-login-tooltip/styled';
import { ActiveListingsCounter } from '../../templates/dashboard/ActiveListingsCounter';
import { CashflowSummary } from '../../templates/dashboard/CashflowSummary';
import { MissingPropertyDataModal } from '../../templates/dashboard/MissingPropertyDataModal';
import { MonthlyExpensesBreakdown } from '../../templates/dashboard/MonthlyExpensesBreakdown';
import { NOISummary } from '../../templates/dashboard/NOISummary';
import { PortfolioSummary } from '../../templates/dashboard/PortfolioSummary';
import { PortfolioTotalEquity } from '../../templates/dashboard/PortfolioTotalEquity';
import { PortfolioValue } from '../../templates/dashboard/PortfolioValue';

const ReportBanner = () => {
  const [viewed, setViewed] = useState(false);

  const l = useLabels();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  const { user } = useAuth();

  const { data: userActions, isLoading: isLoadingUserActions } = useGetUserActions(user?.id ?? '');
  const { mutateAsync: saveAnnualReportBannerViewed } = useMutateUserActions();
  const navigate = useNavigate();
  const analytics = useAnalytics();
  const features = useFeatures();
  const handleClick = () => {
    analytics.track('Button Clicked', {
      buttonName: 'View My Report',
    });

    navigate('/annual-report');
  };

  const handleCloseBanner = async () => {
    setViewed(true);
    try {
      await saveAnnualReportBannerViewed({
        viewedAnnualReportBanner: true,
      });
    } catch {
      console.error('Failed to save banner viewed');
    }
  };

  if (isLoadingUserActions) return null;

  return !viewed && !userActions?.viewedAnnualReportBanner && features.isAnnualReportEnabled && (
    <Banner2
      title={isTablet ? l['annualReport.owner.tablet'] : l['annualReport.owner.title']}
      subtitle={l['annualReport.owner.subtitle']}
      buttonText={l['annualReport.owner.button']}
      onClick={handleClick}
      onClose={handleCloseBanner}
    />
  );
};

type Props = {
  answerParam: string | null,
  ownerSurveyOpen: boolean,
  setOwnerSurveyOpen: (open: boolean) => void,
  satisfiedAnswer: string,
  properties: AppDashboardProperty[],
  isFirstLogin: boolean,
  isFirstVisitTooltipShown: boolean,
  closeFirstVisitTooltip: () => void,
};

const MobileView = ({
  answerParam,
  ownerSurveyOpen,
  setOwnerSurveyOpen,
  satisfiedAnswer,
  properties,
  isFirstLogin,
  isFirstVisitTooltipShown,
  closeFirstVisitTooltip,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const { user } = useAuth();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box sx={{ marginBottom: '70px' }}>
      {(answerParam && (
        <DialogOwnerSurvey
          satisfiedAnswer={satisfiedAnswer}
          formId={config.jotFormOwnerSurveyFormId}
          ownerEmail={user !== undefined ? user.email : ''}
          open={ownerSurveyOpen}
          closeDialog={() => setOwnerSurveyOpen(false)}
        />
      ))}
      <MissingPropertyDataModal properties={properties} />
      <Box>
        <Grid
          container
          spacing={3}
          sx={{
            paddingRight: '12px',
            width: isMobile ? '100%' : '900px',
            margin: 'auto',
          }}
        >
          {isFirstLogin && isFirstVisitTooltipShown && (
            <StyledPaperMobile>
              <Stack
                direction="row"
                alignItems="center"
                height="100%"
              >
                <Stack justifyContent="space-around" height="100%" px={4}>
                  <BoldTypography sx={{
                    marginBottom: theme.spacing(4),
                  }}
                  >
                    {l['dashboard.welcomeTooltip.title']}
                  </BoldTypography>
                  <Typography variant="body2">
                    {l['dashboard.welcomeTooltip.text']}
                  </Typography>
                </Stack>
                <Box pr={7}>
                  <Box
                    component="img"
                    src="https://assets.blankethomes.com/gi/money-plant-mobile.svg"
                    sx={{
                      width: '60px',
                      height: '150px',
                    }}
                  />
                </Box>
              </Stack>
              <IconButton
                aria-label="Close"
                onClick={closeFirstVisitTooltip}
                size="medium"
                color="secondary"
                sx={{
                  position: 'absolute',
                  top: theme.spacing(1),
                  right: theme.spacing(1),
                }}
              >
                <MdClose />
              </IconButton>
            </StyledPaperMobile>
          )}
          <Grid item xs={12}>
            <ReportBanner />
          </Grid>
          <Grid item xs={12} md={12} display="flex" flexDirection="column">
            {properties.length === 1 && (
              <Box sx={{
                backgroundColor: theme.palette.primary.main,
                mb: '-1px',
                p: 3,
                borderTopLeftRadius: '6px',
                borderTopRightRadius: '6px',
              }}
              >
                <Typography sx={{
                  p: 4,
                  pl: 3.5,
                  backgroundColor: theme.palette.primary.dark,
                  color: theme.palette.primary.contrastText,
                  borderRadius: '6px',
                  '& .MuiSelect-iconOutlined': { color: theme.palette.primary.contrastText },
                }}
                >
                  {properties[0].address.street1}
                </Typography>
              </Box>
            )}
            <PortfolioSummary properties={properties} />
            <ActiveListingsCounter />
          </Grid>
          <Grid item xs={12} md={12}>
            <PortfolioValue properties={properties} />
          </Grid>
          <Grid item xs={12} md={12}>
            <NOISummary properties={properties} />
          </Grid>
          <Grid item xs={12} md={12}>
            <CashflowSummary properties={properties} />
          </Grid>
          <Grid item xs={12} md={12}>
            <MonthlyExpensesBreakdown properties={properties} />
          </Grid>
          <Grid item xs={12} md={12}>
            <PortfolioTotalEquity properties={properties} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const DesktopView = ({
  answerParam,
  ownerSurveyOpen,
  setOwnerSurveyOpen,
  satisfiedAnswer,
  properties,
  isFirstLogin,
  isFirstVisitTooltipShown,
  closeFirstVisitTooltip,
}: Props) => {
  const l = useLabels();
  const theme = useTheme();
  const { user } = useAuth();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      {(answerParam && (
        <DialogOwnerSurvey
          ownerEmail={user !== undefined ? user.email : ''}
          satisfiedAnswer={satisfiedAnswer}
          formId={config.jotFormOwnerSurveyFormId}
          open={ownerSurveyOpen}
          closeDialog={() => setOwnerSurveyOpen(false)}
        />
      ))}
      {isFirstLogin && isFirstVisitTooltipShown && (
        <StyledPaperDesktop>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Stack justifyContent="space-between" height="100%" px={4}>
              <BoldTypography sx={{ mb: theme.spacing(4) }}>
                {l['dashboard.welcomeTooltip.title']}
              </BoldTypography>
              <Typography variant="body2">
                {l['dashboard.welcomeTooltip.text']}
              </Typography>
            </Stack>
            <Stack
              justifyContent="flex-end"
              position="relative"
              sx={{ maxWidth: '240px !important' }}
            >
              <IconButton
                aria-label="Close"
                onClick={closeFirstVisitTooltip}
                size="medium"
                color="secondary"
                sx={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                }}
              >
                <MdClose />
              </IconButton>
              <Box
                component="img"
                src="https://assets.blankethomes.com/gi/money-plant-desktop.svg"
                sx={{
                  width: '100%',
                  height: '100%',
                  marginTop: theme.spacing(2),
                }}
              />
            </Stack>
          </Stack>
        </StyledPaperDesktop>
      )}
      <MissingPropertyDataModal properties={properties} />
      <Grid container spacing={2}>
        <Grid sx={{ display: 'flex', flexDirection: 'column' }} item xs={12} md={3.5} mx={isLowerThanLg ? 3 : 0}>
          {properties.length === 1 && (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.primary.main,
              p: 3,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
            }}
            >
              <Typography sx={{
                p: 4,
                pl: 3.5,
                borderRadius: '6px',
                backgroundColor: theme.palette.primary.dark,
                color: '#fff',
                '& .MuiSelect-iconOutlined': { color: '#fff' },
              }}
              >
                {properties[0].address.street1}
              </Typography>
            </Box>
          )}
          <PortfolioSummary properties={properties} />
          <ActiveListingsCounter />
        </Grid>
        <Grid item xs={12} md={3.5} mx={isLowerThanLg ? 3 : 0}>
          <PortfolioValue properties={properties} />
        </Grid>
        <Grid item xs={12} md={5} mx={isLowerThanLg ? 3 : 0}>
          <PortfolioTotalEquity properties={properties} />
        </Grid>
        <Grid item xs={12}>
          <ReportBanner />
        </Grid>
        <Grid item xs={12} md={7} mx={isLowerThanLg ? 3 : 0}>
          <CashflowSummary properties={properties} />
        </Grid>
        <Grid item xs={12} md={5} mx={isLowerThanLg ? 3 : 0}>
          <Box sx={{ paddingBottom: '10px' }}>
            <NOISummary properties={properties} />
          </Box>
          <MonthlyExpensesBreakdown properties={properties} />
        </Grid>
      </Grid>
    </>
  );
};

export const Home = () => {
  const { user } = useAuth();

  const {
    data: apiProperties,
    isLoading,
    isError,
  } = useListOwnerProperties(user?.id ?? '');
  const theme = useTheme();
  const isLowerThanLg = useMediaQuery(theme.breakpoints.down('lg'));

  const properties = apiProperties?.properties ?? [];

  const { isMissingData, missingAttributes } = useMissingData(properties ?? []);
  const [isFirstVisitTooltipShown, setIsFirstVisitTooltipShown] = useState(true);
  const [hadPreviousError, setHadPreviousError] = useState(false);
  const [ownerSurveyOpen, setOwnerSurveyOpen] = useState(false);
  const [satisfiedAnswer, setSatisfiedAnswer] = useState('');
  const [searchParams] = useSearchParams();
  const answerParam = searchParams.get('answer');
  const features = useFeatures();

  useEffect(() => {
    if (answerParam !== null) {
      setSatisfiedAnswer(answerParam);
      setOwnerSurveyOpen(true);
    }
  }, [answerParam]);

  const analytics = useAnalytics();
  const l = useLabels();
  const haveProperties = !!properties?.length;
  const isFirstLogin = checkIsFirstLogin(user?.pm ?? '');

  useEffect(() => {
    if (user && user.id === user.realID) {
      analytics.identify(user.realID, {
        Activated: !isMissingData,
        MortgageActivated: !missingAttributes.mortgage,
        PurchasePriceActivated: !missingAttributes.purchasePrice,
        ExpensesActivated: !missingAttributes.expenses,
      }, features);
    }
  }, [isMissingData, user, features]);

  useEffect(() => {
    // if there was an error, we don't want to show a spinner every time the data refreshes
    // rather, we want to show the error message and for the data to be refreshed silently
    if (isError) {
      setHadPreviousError(true);
    }
  }, [isError]);

  useEffect(() => {
    // if we had an error and now we have properties, we want to reset the error state
    // if we don't, from the point we get properties, we will show the error message every time the data refreshes
    if (haveProperties && hadPreviousError) {
      setHadPreviousError(false);
    }
  }, [hadPreviousError, haveProperties]);

  if (isError || (hadPreviousError && !properties)) {
    return (
      <EmptyFullPageContainer>
        <Avatar
          skin="light"
          variant="circular"
          color="primary"
          sx={{
            mr: 3, mb: 3, width: 72, height: 72,
          }}
        >
          <MissingDataIcon iconProps={{ size: 40, color: 'text.primary' }} />
        </Avatar>
        <Typography variant="h6">
          {l.errorGettingProperties}
        </Typography>
        <Typography variant="body2">
          {l.errorGettingPropertiesDescription}
        </Typography>
      </EmptyFullPageContainer>
    );
  }

  if (isLoading || !properties) {
    return <FallbackSpinner />;
  }

  const props: Props = {
    answerParam,
    ownerSurveyOpen,
    setOwnerSurveyOpen,
    satisfiedAnswer,
    properties,
    isFirstVisitTooltipShown,
    isFirstLogin,
    closeFirstVisitTooltip: () => {
      setIsFirstVisitTooltipShown(false);
    },
  };

  return isLowerThanLg ? <MobileView {...props} /> : <DesktopView {...props} />;
};
