import { ReactNode, useMemo } from 'react';

import { MdClose } from 'react-icons/md';
import {
  Grid,
  IconButton,
  Paper, Skeleton, Stack, SxProps, Theme, Typography, useMediaQuery, useTheme,
} from '@mui/material';

import {
  bubble1ImageURL,
  bubble2ImageURL,
  bubble3ImageURL,
  bubble4ImageURL,
  connectedBubblesImageURL,
  connectedBubblesImageURL2,
  personAvatar1URL, personAvatar2URL, personAvatar3URL, personAvatar4URL,
  personAvatar5URL, personAvatarBox1URL, personAvatarBox2URL, portfolioDataImageURL,
  recommendationBackgroundURL, womanIpad2ImageURL, yourGrowthImageURL,
} from '../assets';
import { InvertedButton } from '../button/styled';
import { Checkmark, CircledIcon } from '../icons';
import { LazyImage } from '../image';
import { PortfolioLottie } from '../lottie/Portfolio';
import { BoldTypography } from '../typography/BoldTypography';

const UserCard = ({ avatar, skew = false }: { avatar: string, skew?: boolean }) => {
  const iconSize = 16;
  const avatarSize = 43;
  const theme = useTheme();

  return (
    <Paper sx={{
      position: 'relative',
      p: 2,
      height: 'fit-content',
      top: skew ? 13 : 0,
    }}
    >
      <CircledIcon
        icon={<Checkmark color={theme.palette.primary.contrastText} />}
        color={theme.palette.success.main}
        size={iconSize}
        sx={{
          position: 'absolute',
          top: -iconSize / 2,
          right: -iconSize / 2,
        }}
      />
      <Stack gap={2}>
        <LazyImage img={avatar} style={{ height: avatarSize, width: avatarSize }} />
        <Stack gap={1}>
          <Skeleton variant="text" width={avatarSize * 0.75} height={10} />
          <Skeleton variant="text" width={avatarSize * 0.4} height={10} />
        </Stack>
      </Stack>
    </Paper>
  );
};

type BannerProps = {
  title: string,
  subtitle?: string,
  buttonText?: string,
  onClick?: () => void,
  onClose?: () => void,
  buttonComponent?: ReactNode,
};

type BannerWithContentProps = BannerProps & {
  leftContent: ReactNode,
  rightContent: ReactNode,
  bannerSx?: SxProps<Theme>,
  textContainerSx?: SxProps<Theme>,
  stacked?: boolean,
};

const BannerWithContent = ({
  title,
  leftContent,
  rightContent,
  subtitle = undefined,
  buttonText = undefined,
  onClick = undefined,
  buttonComponent = undefined,
  bannerSx = undefined,
  textContainerSx = undefined,
  stacked = false,
  onClose = undefined,
}: BannerWithContentProps) => {
  if ((!buttonComponent && !onClick) || (onClick && !buttonText)) {
    throw new Error('Banner must have either a button or an onClick handler and a buttonText');
  }

  const columnWidth = useMemo(() => {
    if (stacked) return 12;

    if (leftContent && rightContent) return 4;

    if (leftContent || rightContent) return 6;

    return 12;
  }, [leftContent, rightContent, stacked]);

  return (
    <Stack
      direction="row"
      justifyContent="space-evenly"
      alignItems="center"
      width="100%"
      px={5}
      gap={3}
      sx={{
        overflow: 'hidden',
        position: 'relative',
        borderRadius: '6px',
        ...bannerSx,
      }}
    >
      <Grid container spacing={2}>
        {leftContent && (
          <Grid item xs={columnWidth}>
            <Stack alignItems="center" justifyContent="center" height="100%" width="100%">
              {leftContent}
            </Stack>
          </Grid>
        )}
        <Grid item xs={columnWidth}>
          <Stack
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            gap={3}
            py={5}
            sx={textContainerSx}
          >
            <BoldTypography variant="body1" sx={{ textAlign: 'center' }}>{title}</BoldTypography>
            {subtitle && <Typography variant="body2" sx={{ textAlign: 'center' }}>{subtitle}</Typography>}
            {buttonComponent || <InvertedButton onClick={onClick}>{buttonText}</InvertedButton>}
          </Stack>
        </Grid>
        {rightContent && (
          <Grid item xs={columnWidth}>
            <Stack alignItems="center" justifyContent="center" height="100%" width="100%">
              {rightContent}
            </Stack>
          </Grid>
        )}
      </Grid>
      {onClose && (
        <Stack position="absolute" top={0} right={0} pt={2} pr={2}>
          <IconButton onClick={() => onClose()}>
            <MdClose />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export const Banner1 = ({
  buttonText = undefined,
  onClick = undefined,
  buttonComponent = undefined,
  ...props
}: BannerProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const hideAvatar4Breakpoint = useMediaQuery(theme.breakpoints.down(810));
  const hideAvatar3Breakpoint = useMediaQuery(theme.breakpoints.down(700));
  const hideSpritesBreakpoint = useMediaQuery(theme.breakpoints.down('sm'));

  if ((!buttonComponent && !onClick) || (onClick && !buttonText)) {
    throw new Error('Banner must have either a button or an onClick handler and a buttonText');
  }

  return (
    <BannerWithContent
      {...props}
      buttonText={buttonText}
      onClick={onClick}
      buttonComponent={buttonComponent}
      bannerSx={{
        background: `url(${recommendationBackgroundURL}) no-repeat center center`,
        minHeight: 114,
        mb: 3,
      }}
      leftContent={!hideSpritesBreakpoint && (
        <Stack direction="row" gap={7} justifyContent="space-between" overflow="visible">
          <UserCard avatar={personAvatar1URL} />
          <UserCard skew avatar={personAvatar2URL} />
          {!hideAvatar3Breakpoint && <UserCard avatar={personAvatar3URL} />}
        </Stack>
      )}
      rightContent={!hideSpritesBreakpoint && (
        <>
          <Stack direction="row" height="100%" gap={7}>
            <LazyImage img={yourGrowthImageURL} style={{ minHeight: 114, width: 200 }} />
            {!hideAvatar4Breakpoint && (
              <Stack pt={13}>
                <LazyImage img={personAvatar4URL} style={{ height: 40, width: 40 }} />
              </Stack>
            )}
          </Stack>
          {!isMobile && (
            <Stack position="absolute" p={3} top={0} right={0}>
              <LazyImage img={personAvatar5URL} style={{ height: 20, width: 20 }} />
            </Stack>
          )}
        </>
      )}
    />
  );
};

export const Banner2 = ({
  buttonText = undefined,
  onClick = undefined,
  buttonComponent = undefined,
  subtitle = undefined,
  ...props
}: BannerProps) => {
  const theme = useTheme();
  const hideSingleBubble1Breakpoint = useMediaQuery(theme.breakpoints.down(1280));
  const hideSingleBubble2Breakpoint = useMediaQuery(theme.breakpoints.down(1280));
  const hideSingleBubbleBreakpoint = hideSingleBubble1Breakpoint || hideSingleBubble2Breakpoint;
  const hideBubblesBreakpoint = useMediaQuery(theme.breakpoints.down(840));
  const hideSpritesBreakpoint = useMediaQuery(theme.breakpoints.down(835));

  if ((!buttonComponent && !onClick) || (onClick && !buttonText)) {
    throw new Error('Banner must have either a button or an onClick handler and a buttonText');
  }

  return (
    <BannerWithContent
      {...props}
      stacked={hideSpritesBreakpoint}
      buttonText={buttonText}
      onClick={onClick}
      buttonComponent={buttonComponent}
      subtitle={subtitle}
      bannerSx={{
        backgroundColor: '#EBEDF5 !important',
        my: 2,
        ...(hideSpritesBreakpoint && {
          flexDirection: 'column',
        }),
      }}
      textContainerSx={{
        ...(hideSpritesBreakpoint && {
          py: 0,
          pt: 5,
          zIndex: 1,
        }),
      }}
      leftContent={!hideSpritesBreakpoint && (
        <Stack direction="row" gap={7} justifyContent="space-between" overflow="visible">
          {!hideBubblesBreakpoint && (
            <Stack overflow="visible" position="relative" pr={6}>
              <Stack position="absolute" top={0} left={0} gap={10} width="100%" height="100%" justifyContent="space-between">
                <LazyImage img={bubble1ImageURL} style={{ height: 60, width: 60 }} />
                <Stack>
                  {!hideSingleBubbleBreakpoint && (
                    <LazyImage
                      img={bubble2ImageURL}
                      style={{
                        height: 48,
                        width: 48,
                        position: 'relative',
                        left: '-50px !important',
                      }}
                    />
                  )}
                </Stack>
              </Stack>
            </Stack>
          )}
          <LazyImage img={womanIpad2ImageURL} style={{ height: 162, width: 116 }} />
          {!hideBubblesBreakpoint && (
            <Stack direction="row" gap={7} overflow="visible">
              <Stack overflow="visible" position="relative">
                <Stack position="absolute" top={0} right={0} gap={10} pt={4} height="100%" justifyContent="space-between">
                  <Stack>
                    {!hideSingleBubbleBreakpoint && (
                      <LazyImage img={bubble3ImageURL} style={{ height: 40, width: 40 }} />
                    )}
                  </Stack>
                  <LazyImage
                    img={bubble4ImageURL}
                    style={{
                      height: 44, width: 44, position: 'relative', right: -50,
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
        </Stack>
      )}
      rightContent={(
        <Stack
          position="relative"
          overflow="hidden"
          maxHeight={160}
          sx={{
            ...(hideSpritesBreakpoint && {
              maxHeight: 75,
            }),
          }}
        >
          {hideSpritesBreakpoint ? (
            <LazyImage
              img={portfolioDataImageURL}
              style={{
                height: 131, width: 280, backgroundPosition: 'top', marginTop: theme.spacing(-4),
              }}
            />
          ) : (
            <LazyImage img={portfolioDataImageURL} style={{ height: 160, width: 300 }} />
          )}
        </Stack>
      )}
    />
  );
};

export const Banner3 = ({
  buttonText = undefined,
  onClick = undefined,
  buttonComponent = undefined,
  subtitle = undefined,
  ...props
}: BannerProps) => {
  const theme = useTheme();
  const hideSpritesBreakpoint = useMediaQuery(theme.breakpoints.down(961));
  const compactContentBreakpoint = useMediaQuery(theme.breakpoints.down(1360));

  if ((!buttonComponent && !onClick) || (onClick && !buttonText)) {
    throw new Error('Banner must have either a button or an onClick handler and a buttonText');
  }

  return (
    <BannerWithContent
      {...props}
      buttonText={buttonText}
      onClick={onClick}
      buttonComponent={buttonComponent}
      subtitle={subtitle}
      bannerSx={{
        background: `url(${recommendationBackgroundURL}) no-repeat`,
        backgroundSize: 'cover',
        my: 2,
      }}
      leftContent={!hideSpritesBreakpoint && (
        compactContentBreakpoint ? (
          <LazyImage img={connectedBubblesImageURL2} style={{ height: 162, width: 300 }} />
        ) : (
          <LazyImage img={connectedBubblesImageURL} style={{ height: 162, width: 380 }} />
        )
      )}
      rightContent={!hideSpritesBreakpoint && (
        <Stack direction="row" gap={2} height="100%">
          {!compactContentBreakpoint && (
            <Stack height="100%" pt={3}>
              <LazyImage img={personAvatarBox1URL} style={{ height: 70, width: 70 }} />
            </Stack>
          )}
          <PortfolioLottie height={140} width={211} />
          <Stack height="100%" justifyContent="flex-end">
            <LazyImage img={personAvatarBox2URL} style={{ height: 70, width: 70 }} />
          </Stack>
        </Stack>
      )}
    />
  );
};
