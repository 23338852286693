import { useCallback, useEffect, useState } from 'react';

import { useAppSettings, useAuth } from 'lib';
import {
  Box, Stack, styled, Typography, useTheme,
} from '@mui/material';

import { annualReportVideoURL, loginVideoPosterURL, recommendationBackgroundURL } from '../../assets';
import { useLabels } from '../../lib/translations';
import { BoldTypography } from '../../typography/BoldTypography';

export const AnnualReportHeaderBanner = () => {
  const l = useLabels();
  const theme = useTheme();
  const { data: appSettings } = useAppSettings();
  const { user } = useAuth();
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const containerRef = useCallback((node: HTMLDivElement | null) => {
    setContainer(node);
  }, []);
  const [isLowerThanMd, setIsLowerThanMd] = useState(false);

  useEffect(() => {
    if (!container) return () => { };

    const resizeObserver = new ResizeObserver(() => {
      setIsLowerThanMd(container.offsetWidth < theme.breakpoints.values.md);
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, [container]);

  return (

    <Stack
      p={6}
      gap={2}
      sx={{
        backgroundImage: `url(${recommendationBackgroundURL})`,
        borderRadius: '12px',
        backgroundPosition: isLowerThanMd ? 'right' : 'unset',
        position: 'relative',
        height: '164px',
        backgroundRepeat: !isLowerThanMd ? 'no-repeat' : 'unset',
        backgroundSize: !isLowerThanMd ? '80%' : 'unset',
      }}
      ref={containerRef}
    >
      <LogoContainer style={{ maxHeight: '40px', backgroundImage: `url(${appSettings?.logo})`, paddingBottom: '12px' }} />
      <Typography variant={isLowerThanMd ? 'h6' : 'h4'}>
        {l['annualReport.header.title']}
      </Typography>
      <BoldTypography variant="h6">
        {user && `${user.givenName} ${user.familyName}`}
      </BoldTypography>
      {!isLowerThanMd && (
        <Stack
          position="absolute"
          top={-1}
          right={-1}
          height="calc(100% + 1px)"
          pl={20}
          sx={{
            backgroundColor: '#EFF0F7',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)',
            borderTopRightRadius: '12px',
            borderBottomRightRadius: '12px',
            overflow: 'hidden',
          }}
        >
          <video
            width="287px"
            height="165px"
            autoPlay
            loop
            playsInline
            muted
            poster={loginVideoPosterURL}
          >
            <source src={annualReportVideoURL} type="video/mp4" />
          </video>
        </Stack>
      )}
    </Stack>
  );
};

export const LogoContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  height: '100%',
  width: '100%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
});
