import { AppUserActions as APIUserActions, AppUserActions } from '../../types/appUserActions';

export const convertUserActions = (apiUserActions: APIUserActions | null | undefined): AppUserActions => ({
  owner: apiUserActions?.owner ?? '',
  pm: apiUserActions?.pm ?? '',
  viewedCashOutTooltip: apiUserActions?.viewedCashOutTooltip ?? false,
  viewedWelcomeToMarketplaceScreen: apiUserActions?.viewedWelcomeToMarketplaceScreen ?? false,
  dashboardProformaOverride: apiUserActions?.dashboardProformaOverride ?? null,
  viewedAnnualReportBanner: apiUserActions?.viewedAnnualReportBanner ?? false,
});
