import dayjs, { Dayjs } from 'dayjs';

import { sum } from './math';
import { AppDashboardProperty } from '../../api/dashboard-properties/types/property';
import { Valuation } from '../../types/property';
import { sortByAsc } from '../sort';

export const calculateTotalPurchase = (properties: Pick<AppDashboardProperty, 'purchaseHistory'>[]) => sum(
  properties.map(({ purchaseHistory }) => (purchaseHistory?.amount ?? 0)
      + (purchaseHistory?.closingCost ?? 0)
      + (purchaseHistory?.homeImprovementCost ?? 0)),
);

export const calculateTotalValuation = (properties: Pick<AppDashboardProperty, 'latestValuation'>[]) => sum(
  properties.map(({ latestValuation }) => latestValuation?.priceMean ?? 0),
);

export const getLastValuationDate = (properties: Pick<AppDashboardProperty, 'latestValuation'>[]): Dayjs | null => {
  const valuations = properties
    .map(({ latestValuation }) => latestValuation)
    .filter(Boolean) as Valuation[];

  if (valuations.length === 0) {
    return null;
  }

  const lastValuationDate = sortByAsc<Valuation>(valuations, 'date')[0]!.date;

  return dayjs(lastValuationDate);
};
